import { DatePickerInput } from "@mantine/dates";
import { IconChevronDown } from "@tabler/icons-react";
import React from "react";

const DataPicker = ({
  mode = "single",
  label,
  value,
  onChange,
  className,
  placeholder,
}) => {
  return (
    <DatePickerInput
      type={mode === "range" ? "range" : "default"}
      label={label}
      placeholder={placeholder}
      value={value || (mode === "range" ? [null, null] : null)}
      onChange={onChange}
      valueFormat="YYYY-MM-DD"
      className={className}
      rightSection={<IconChevronDown size={16} />}
      styles={{
        input: {
          fontSize: "14px",
          fontWeight: "400",
          color: "black",
          whiteSpace: "nowrap",
          width: "100%",
          "&:focus-within": {
            borderColor: "rgb(33, 160, 56)",
            borderWidth: "1px",
          },
        },
        placeholder: {
          color: "#aaaaaa",
        },
      }}
    />
  );
};
export default DataPicker;
