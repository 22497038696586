import clsx from "clsx";
import TeamManagementTable from "../../modules/Tables/TeamManagementTable";
import styles from "./styles.module.scss";

const TeamManagement = () => {
  return (
    <div className={styles.mainWrapper}>
      <p className={clsx("H1", styles.titleTable)}>Team management</p>
      <div className={styles.containerTable}>
        <TeamManagementTable />
      </div>
    </div>
  );
};

export default TeamManagement;
