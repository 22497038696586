export const ButtonMode = {
  GREEN_GRADIENT: "green-gradient",
};
export const LOGIN_REG_EXP = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const SECONDS_TO_TESEND_2FA_CODE = 120;

export const HIGHLIGH_POSITION_LAST = "last";

export const NOTIFICATION_INFO = {
  LOGIN: "Welcome to [FBM] PAY",
  PROFILE_DATA_CHANGED: "Profile data changed",
  PASSOWRD_CHANGED: "Password changed",
  ACCOUNT_REMOVED: "Account successfully removed",
  ACCOUNT_BLOCKED_ACTION: "Account successfully blocked",
  ACCOUNT_UNBLOCKED: "Account successfully unblocked",
  ACCOUNT_GRANTED: "Account successfully granted",
  ACCOUNT_CREATED: "Account successfully created",
  ACCOUNT_UPDATED: "Account successfully updated",
  ACCOUNT_BLOCKED_INFORM: "Your account has been banned",
  ACCOUNT_TRANSFER: "Money transfer completed successfully",
  CARD_CLOSED: "Your card has been permanently closed",
  CHANGED_THREE_DS_STATUS: "Your 3ds status has been successfully changed",
  CHANGED_CARD_NAME: "Сard name successfully changed",
  CHANGE_CARD_LIMITS: "Сard limits have been successfully changed",
  CHANGE_CARD_STATUS: "Сard status have been successfully changed",
  CHANGE_CARD_STATUS_ERROR: "Failed to update card status",
  CHANGE_CARD_LIMITS_ERROR:
    "You can't change the card limits because the card is inactive.",

  CARDS_ISSUED: "The cards have been successfully issued",
  CARDS_ISSUED_ERROR: "Unexpected error during card issuance",

  TOPUP_SUCCESS: "Order has been successfully submitted",
  ISSUE_ACCOUNT: "Account has been successfully issued",
  USER_REMOVE: "User has been successfully removed",
  USER_ADD: "User has been successfully added",
};

export const REG_EXP_ONLY_NUMBERS = /^[0-9]+$/;

export const optionsTable = {
  striped: true,
  highlightOnHover: true,
  withBorder: false,
  sx: {
    "thead th": {
      color: "#aaaaaa !important",
      fontWeight: "600 !important",
    },
    "th:nth-of-type(6)": {
      paddingLeft: "11px !important",
    },
    "th:nth-of-type(7)": {
      paddingLeft: "16px !important",
    },
    td: {
      background: "white !important",
    },
  },
};

export const pageSize = 15;

export const displayLimits = [
  { label: "1 day", name: "LMTTZ54" },
  { label: "7 days", name: "LMTTZ56" },
  { label: "30 days", name: "LMTTZ55" },
];

// Card Issuance

export const ALL_MERCHANTS =
  "https://docs.google.com/spreadsheets/d/1FnHQFVaNZFQ9vuvCPCJ6BIs7xOZ86BFRnjROf0I2EZM/edit?gid=2107443851#gid=2107443851";

export const cardsData = [
  { bin: "53738301", system: "mc", currensy: "EUR" },
  { bin: "44625300", system: "visa", currensy: "EUR" },
  { bin: "62330559", system: "up", currensy: "EUR" },
];

export const systemOptions = [
  { label: "All", value: "all" },
  { label: "Mastercard", value: "mastercard" },
  { label: "Visa", value: "visa" },
  { label: "UnionPay", value: "up" },
];

export const currencyOptions = [
  { label: "All", value: "all" },
  { label: "EUR", value: "EUR" },
];

export const cardsIssuanceData = [
  { title: "Currency", text: "EURO" },
  { title: "All EURO payments", text: "No comission" },
  { title: "Non-EURO payments", text: "No comission" },
  { title: "Declined payments", text: "€0" },
];

export const limitOptions = [50, 100, 200, 500, 1000];

export const widthStatusTitle = "150px";
