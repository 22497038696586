import React, { useState } from "react";
import styles from "./styles.module.scss";
import RadioOnIcon from "../../icons/RadioOnIcon";
import SearchIcon from "../../icons/SearchIcon";
import CardIssuanceItem from "../../components/CardIssuanceItem/CardIssuanceItem";
import {
  cardsData,
  currencyOptions,
  systemOptions,
} from "../../constants/constants";
import FilterButton from "../../components/FilterButton/FilterButton";
import { getFilteredAndSortedCards } from "../../utils/getFilteredCards";

const CardIssuance = () => {
  const [binFilter, setBinFilter] = useState("");
  const [systemFilter, setSystemFilter] = useState("all");
  const [currencyFilter, setCurrencyFilter] = useState("all");

  const sortedCards = getFilteredAndSortedCards(
    cardsData,
    binFilter,
    systemFilter,
    currencyFilter
  );

  return (
    <>
      <div className={styles.cardIssuanceContainer}>
        <div className={styles.cardIssuanceFilters}>
          <div className={styles.cardIssuanceFilter}>
            <label>Search</label>
            <input
              placeholder="Search by BIN"
              type="text"
              value={binFilter}
              onChange={(e) => setBinFilter(e.target.value)}
            />
            <span className={styles.filterSearchIcon}>
              <SearchIcon />
            </span>
          </div>

          <div className={styles.cardIssuanceFilter}>
            <label>Payment system</label>
            <div className={styles.cardIssuanceButtonContainer}>
              {systemOptions.map(({ label, value }) => (
                <FilterButton
                  key={value}
                  label={label}
                  styles={styles.activeButton}
                  isActive={systemFilter === value}
                  onClick={() => setSystemFilter(value)}
                />
              ))}
            </div>
          </div>

          <div className={styles.cardIssuanceFilter}>
            <label>Currency</label>
            <div className={styles.cardIssuanceButtonContainer}>
              {currencyOptions.map(({ label, value }) => (
                <FilterButton
                  key={value}
                  label={label}
                  styles={styles.activeButton}
                  isActive={currencyFilter === value}
                  onClick={() => setCurrencyFilter(value)}
                />
              ))}
            </div>
          </div>
          <div className={styles.cardIssuanceFilter}>
            <label>Types of use</label>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <RadioOnIcon /> For advertising and services
            </div>
          </div>
        </div>

        <div className={styles.cardIssuanceItems}>
          <CardIssuanceItem data={sortedCards} />
        </div>
      </div>
    </>
  );
};

export default CardIssuance;
