import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./TopUpModal.module.scss";
import CloseIcon from "../../../icons/CloseIcon";
import { maxHeightActions } from "../../../styles/constants";

export const AccountForm = ({
  accounts,
  setAccountsArray,
  index,
  removeAccount,
  user,
  setSelectedIbans,
  selectedIbans,
}) => {
  const [accountIban, setAccountIban] = useState("");
  const [accountId, setAccountId] = useState();
  const [accountValue, setAccountValue] = useState();
  const [error, setError] = useState(false);
  const [selectError, setSelectError] = useState(false);

  useEffect(() => {
    if (index === 0 && user) {
      handleAccountChange(user?.iban || user);
    }
  }, [index, user]);

  const handleAccountChange = (event) => {
    const selectedIban = event?.target?.value || event;

    setSelectedIbans((prevIbans) => {
      const newIbans = [...prevIbans];
      const previousIban = accountIban;
      if (previousIban) {
        const index = newIbans.indexOf(previousIban);
        if (index > -1) newIbans.splice(index, 1);
      }

      newIbans.push(selectedIban);
      return newIbans;
    });

    if (selectedIban) {
      setSelectError(false);
      setAccountIban(selectedIban);
      const selectedAccount = accounts.find(
        (account) => account.iban === selectedIban
      );
      if (selectedAccount) {
        setAccountId(selectedAccount.ID);
      }
    } else {
      setSelectError(true);
    }
  };

  const handleAmountChange = (event) => {
    const input = event.target.value;

    if (/^\d*$/.test(input) || input < 500) {
      setAccountValue(input);
      setError(false);
    } else {
      setAccountValue("");
      setError(true);
    }
  };
  console.log("accountIban", accountIban);
  useEffect(() => {
    if (!error && !selectError) {
      setAccountsArray((prevArray) => {
        const updatedArray = [...prevArray];

        updatedArray[index] = {
          id: accountId,
          amount: accountValue,
          iban: accountIban,
        };
        return updatedArray;
      });
    }
  }, [accountIban, accountValue, error, selectError, accountId]);

  return (
    <>
      <div className={styles.accountFormContainer}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            maxHeight: "40px",
            width: "100%",
            "& .MuiInputBase-root": {
              height: "40px",
            },
          }}
          error={selectError}
        >
          <InputLabel
            id="network-select-label"
            shrink
            sx={{
              backgroundColor: "white",
              padding: "0px",
              fontWeight: "700",
              color: "#AAAAAA",
            }}
          >
            Account #{index + 1}
          </InputLabel>
          <Select
            labelId="network-select-label"
            id="network-select"
            value={accountIban}
            label="Select the account"
            onChange={handleAccountChange}
            displayEmpty
            sx={{
              fontSize: "14px",
              color: "#464646",
              fontFamily: "Noto Sans, sans-serif",
              "& .MuiMenuItem-root": {
                fontFamily: "Noto Sans, sans-serif",
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: maxHeightActions,
                  overflow: "auto",
                },
              },
            }}
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <span style={{ color: "#AAAAAA" }}>Select the account</span>
                );
              }

              const selectedAccount = accounts.find(
                (acc) => acc.iban === selected
              );

              if (!selectedAccount) {
                return (
                  <span style={{ color: "#AAAAAA" }}>Select the account</span>
                );
              }
              const slicedName =
                selectedAccount.name.length > 25
                  ? `${selectedAccount.name.slice(0, 25)}...`
                  : selectedAccount.name;
              return `${slicedName}, ${selectedAccount.iban}, ${selectedAccount.balance} EUR`;
            }}
          >
            {accounts
              .filter(
                (account) =>
                  !selectedIbans.includes(account.iban) ||
                  account.iban === accountIban
              )
              .map((account, idx) => {
                const truncatedName =
                  account.name.length > 25
                    ? `${account.name.slice(0, 25)}...`
                    : account.name;
                return (
                  <MenuItem key={idx} value={account.iban}>
                    {truncatedName}, {` `}
                    {account.iban}, {account?.balance} EUR
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <TextField
          id={"amounts"}
          label="Top up amount"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
            style: {
              fontSize: "16px",
              fontWeight: 700,
              color: "#AAAAAA",
              backgroundColor: "white",
              paddingRight: "7px",
            },
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            style: {
              fontSize: "14px",
              fontWeight: 500,
              color: "#464646",
            },
          }}
          sx={{
            fontSize: "20px",
            color: "#464646",
          }}
          value={accountValue}
          onChange={handleAmountChange}
          error={error}
          helperText={
            error
              ? "Please enter a valid amount (numbers only) and total sum of accounts min. 500"
              : ""
          }
          style={{
            maxHeight: "40px",
            marginBottom: error ? "10px" : "",
          }}
        />

        {index === 0 ? (
          ""
        ) : (
          <Button
            onClick={() => removeAccount(index)}
            className={styles.deleteButton}
          >
            <CloseIcon color="#AAAAAA" />
          </Button>
        )}
      </div>
    </>
  );
};
