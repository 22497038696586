import React from "react";

const Teather = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2974_18521)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.84478 0.0884055L0.015742 8.01336C0.00117144 8.04283 -0.00344588 8.07613 0.00256529 8.10838C0.00857646 8.14062 0.0249008 8.17013 0.049151 8.19258L10.3905 17.9567C10.4198 17.9845 10.459 18 10.4997 18C10.5404 18 10.5795 17.9845 10.6089 17.9567L20.9502 8.19319C20.9744 8.17074 20.9907 8.14123 20.9967 8.10899C21.0028 8.07674 20.9981 8.04344 20.9836 8.01397L17.1545 0.0890151C17.1422 0.0623997 17.1223 0.0398532 17.0973 0.02407C17.0723 0.00828685 17.0432 -6.52887e-05 17.0135 1.17974e-05H3.98708C3.95723 -0.000354068 3.92791 0.00779688 3.90267 0.0234814C3.87742 0.0391659 3.85731 0.0617139 3.84478 0.0884055Z"
          fill="#50AF95"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.831 8.82722C11.7567 8.83271 11.3732 8.85526 10.5175 8.85526C9.83697 8.85526 9.35378 8.83515 9.18426 8.82722C6.55423 8.71323 4.59115 8.26211 4.59115 7.722C4.59115 7.18188 6.55423 6.73138 9.18426 6.61555V8.37794C9.35625 8.39013 9.84872 8.41878 10.5293 8.41878C11.3459 8.41878 11.7549 8.38525 11.8285 8.37855V6.61677C14.453 6.73199 16.4117 7.1831 16.4117 7.722C16.4117 8.26089 14.4536 8.71201 11.8285 8.82661L11.831 8.82722ZM11.831 6.4345V4.85743H15.4936V2.45251H5.52165V4.85743H9.18364V6.43389C6.20715 6.56861 3.96875 7.14957 3.96875 7.84575C3.96875 8.54192 6.20715 9.12227 9.18364 9.25761V14.3113H11.8304V9.25578C14.8001 9.12106 17.0347 8.54071 17.0347 7.84514C17.0347 7.14957 14.8019 6.56922 11.8304 6.43389L11.831 6.4345Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2974_18521">
          <rect width="21" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Teather;
