const FilterActive = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.20938 4.63494L1.20937 4.63493C1.07799 4.54109 1 4.38955 1 4.22807V1.5C1 1.22385 1.22385 1 1.5 1H18.5C18.7761 1 19 1.22387 19 1.5V4.22807C19 4.38962 18.922 4.54112 18.7907 4.63493L18.7907 4.63494L12.8376 8.88715C12.8376 8.88715 12.8376 8.88716 12.8376 8.88717C12.312 9.26255 12 9.8687 12 10.5146V15.9256L12 15.9256L8.00003 18.2589L8.5039 19.1227L8.00003 18.2589L8 18.259V10.5146C8 9.86876 7.68807 9.26258 7.16248 8.88717C7.16247 8.88716 7.16247 8.88716 7.16246 8.88716L1.20938 4.63494Z"
        fill="url(#paint0_linear_3967_12663)"
        stroke="url(#paint1_linear_3967_12663)"
        stroke-width="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3967_12663"
          x1="0"
          y1="0"
          x2="19.4911"
          y2="-0.501886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#83DA4E" />
          <stop offset="0.511453" stop-color="#21A038" />
          <stop offset="0.98" stop-color="#54ACB8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3967_12663"
          x1="0"
          y1="0"
          x2="19.4911"
          y2="-0.501886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#83DA4E" />
          <stop offset="0.511453" stop-color="#21A038" />
          <stop offset="0.98" stop-color="#54ACB8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FilterActive;
