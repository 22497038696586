export const PAGE_ROUTES = {
  LOGIN: "/login",
  TWO_FA_AFTER_LOGIN: "/2fa",
  RECOVER_PASSWORD: "/recover-password",
  RESET_PASSWORD: "/recover-password/:token",
  NOT_FOUND: "/not-found",
  NEWS: "/news",
  NEWS_ARTICLE: "/news-article/:id",

  //footer
  YOUR_MANAGER: "https://t.me/fbm_ivan",
  TG_CHANNEL: "https://t.me/fbm_pay",

  //menu
  DASHBOARD: "/dashboard",

  ACCOUNTS: "/accounts",
  TEAM_MANAGEMENT: "/team-management",
  CARDS: "/cards",
  PAYMENTS: "/payments",
  METAPAY_AND_3DS: "/metapay-and-3ds",
  FBM_SERVICES: "/fbm-services",

  //profile
  PROFILE: "/profile",
  PROFILE_CHANGE: "/profile/change",
  PROFILE_PASSWORD_CHANGE: "/profile/change-password",

  //cards
  CARD_DETAILES: "/cards/card-details/:id",
};

export const cardIssueLink =
  "https://docs.google.com/spreadsheets/d/1FnHQFVaNZFQ9vuvCPCJ6BIs7xOZ86BFRnjROf0I2EZM/edit?gid=2107443851#gid=2107443851";
