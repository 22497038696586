import clsx from "clsx";
import styles from "./styles.module.scss";

import { ButtonMode } from "../../constants/constants";
import LoaderSpinnerIcon from "../../icons/LoaderSpinnerIcon";

export const Button = ({
  children,
  mode = ButtonMode.GREEN_GRADIENT,
  className,
  disabled,
  isLoading,
  ...props
}) => {
  const classNames = clsx(
    className,
    mode === ButtonMode.GREEN_GRADIENT && styles.buttonGradient,
    disabled && styles.buttonDisabled
  );

  return (
    <button
      type="button"
      className={`${styles.button} ${classNames}`}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading ? <LoaderSpinnerIcon /> : children}
    </button>
  );
};
