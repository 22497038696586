export const getCardStatus = (cardData) => {
  let statusCard;
  let colorContainer;
  let colorText;

  switch (cardData) {
    case "VALID CARD":
      statusCard = "Active";
      colorContainer = "#D9FFE0";
      colorText = "#21A038";
      break;
    case "AWAITING ACTIVATION":
      statusCard = "PENDING";
      colorContainer = "#FFE390";
      colorText = "#F79E1B";
      break;
    case "50":
      statusCard = "PENDING";
      colorContainer = "#FFE390";
      colorText = "#F79E1B";
      break;
    case "DO NOT HONOR":
      statusCard = "STOPPED";
      colorContainer = "#D5E8FF";
      colorText = "#227ECF";

      break;
    case "PERMANENT BLOCKED BY CLIENT":
      statusCard = "CLOSED";
      colorContainer = "#FFE2E2";
      colorText = "#FF4D4D";

      break;

    case "EXPIRY OF THE CARD":
      statusCard = "CLOSED";
      colorContainer = "#FFE2E2";
      colorText = "#FF4D4D";

      break;

    case "In progress":
      statusCard = "In progress";
      colorContainer = "#FFE390";
      colorText = "#F79E1B";

      break;
    case "Reversed":
      statusCard = "Reversed";
      colorContainer = "#FFE2E2";
      colorText = "#FF4D4D";

      break;
    case "Partial reversed":
      statusCard = "Part reversed";
      colorContainer = "#FFE2E2";
      colorText = "#FF4D4D";

      break;

    case "Unhold":
      statusCard = "Unhold";
      colorContainer = "#D5E8FF";
      colorText = "#227ECF";

      break;
    case "Executed":
      statusCard = "Completed";
      colorContainer = "#D9FFE0";
      colorText = "#21A038";

      break;

    case "Partial Refund":
      statusCard = "Part refund";
      colorContainer = "#FFD8F9";
      colorText = "#C970BC";
      break;

    case "Refund":
      statusCard = "Refund";
      colorContainer = "#FFD8F9";
      colorText = "#C970BC";
      break;
    default:
      statusCard = "No status";
      colorContainer = "#D9FFE0";
      colorText = "#21A038";
  }
  return { statusCard, colorContainer, colorText };
};
