import { changeCard, changeCardStatus } from "../../api/cardsApi";
import { removeUserAccount } from "../../api/usersApi";
import { NOTIFICATION_INFO } from "../../constants/constants";
import { showToast } from "../../modules/Notification/ActionNotification";
import { useClientInvalidateData } from "../useClientInvalidateData";

const useCardsHandles = ({
  setActiveModalInveractive,
  setSelectedRows,
  setModalDataRow,
  selectedRows,
}) => {
  const { handleInvalidateData } = useClientInvalidateData({
    queryKey: "cardsTableData",
  });

  const handleSubmit = async (modalType, data) => {
    if (!data || data.length === 0) {
      console.warn("No selected rows to process.");
      return;
    }

    try {
      if (modalType === "removeCardUser") {
        await handleRemoveUserAccount(data.id, data.userId);
        handleInvalidateData();
      } else {
        for (const row of data) {
          try {
            if (modalType === "holdCard") {
              if (row.statusCode === "03") {
                showToast("error", `Card ${row.name} already stopped.`);
                continue;
              }
              const payload = { status: "03" };

              await changeCardStatus(row.cardId, payload);
              showToast("success", `${row.name} was successfully held.`);
            } else if (modalType === "unholdCard") {
              if (row.statusCode === "00") {
                showToast("error", `Card ${row.name} is already active`);
                continue;
              }
              const payload = { status: "00" };

              await changeCardStatus(row.cardId, payload);
              showToast("success", `${row.name} was successfully unhold.`);
            } else if (modalType === "closeCard") {
              const payload = { status: "21" }; //fix in prod to 21 code status

              await changeCardStatus(row.cardId, payload);
              showToast("success", `${row.name} was successfully closed.`);
            } else if (modalType === "activateThreeDS") {
              if (row.enablePushNotification) {
                showToast(
                  "error",
                  `Card ${row.name} already has 3DS activated.`
                );
                continue;
              }
              const payload = { enablePushNotification: true };
              await changeCard(row.cardId, payload);

              showToast("success", `3DS activated for ${row.name}.`);
            } else if (modalType === "deactivateThreeDS") {
              if (!row.enablePushNotification) {
                showToast(
                  "error",
                  `Card ${row.name} already has 3DS deactivated.`
                );
                continue;
              }

              const payload = { enablePushNotification: false };
              await changeCard(row.cardId, payload);
              showToast("success", `3DS deactivated for ${row.name}.`);
            }
            handleInvalidateData();
          } catch (error) {
            showToast("error", `Failed to process card ${row.name}`);
            console.error("Error processing card:", error);
          }
        }
      }
      setSelectedRows([]);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      setActiveModalInveractive(null);
    }
  };

  const handleOpenModalsInteractive = (modalType) => {
    setActiveModalInveractive(modalType);
  };

  const handleOpenModalRemoveUser = (modalName, rowData) => {
    console.log("rowData", rowData);

    setActiveModalInveractive(modalName);
    setModalDataRow(rowData);
  };

  const handleRemoveUserAccount = async (id, userId) => {
    try {
      await removeUserAccount(id, userId);
      showToast("success", "The user has been successfully deleted");
    } catch (error) {
      console.error(error);
      showToast("error", "Unexpected error");
    }
  };

  return {
    handleSubmit,
    handleOpenModalRemoveUser,
    handleOpenModalsInteractive,
  };
};

export default useCardsHandles;
