import React from "react";
import DownloadIcon from "../../icons/DownloadIcon";
import styles from "./styles.module.scss";

const ExcelButton = ({ onSubmit, isDisable = false }) => {
  const handleSubmit = async () => {
    try {
      await onSubmit();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <button
      onClick={handleSubmit}
      className={styles.excelButton}
      disabled={isDisable}
    >
      <span>Excel </span>
      <DownloadIcon />
    </button>
  );
};

export default ExcelButton;
