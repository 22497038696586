import React from "react";

const CodesMobileIcon = ({ fill }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8501 5.25C5.8501 4.83579 5.51431 4.5 5.1001 4.5C4.68588 4.5 4.3501 4.83579 4.3501 5.25V18.75C4.3501 19.1642 4.68588 19.5 5.1001 19.5C5.51431 19.5 5.8501 19.1642 5.8501 18.75V5.25Z"
        fill={fill}
      />
      <path
        d="M8.1001 4.5C8.51431 4.5 8.8501 4.83579 8.8501 5.25V16.4357C8.8501 16.8499 8.51431 17.1857 8.1001 17.1857C7.68588 17.1857 7.3501 16.8499 7.3501 16.4357V5.25C7.3501 4.83579 7.68588 4.5 8.1001 4.5Z"
        fill={fill}
      />
      <path
        d="M11.8501 5.25C11.8501 4.83579 11.5143 4.5 11.1001 4.5C10.6859 4.5 10.3501 4.83579 10.3501 5.25V16.4357C10.3501 16.8499 10.6859 17.1857 11.1001 17.1857C11.5143 17.1857 11.8501 16.8499 11.8501 16.4357V5.25Z"
        fill={fill}
      />
      <path
        d="M14.1001 4.5C14.5143 4.5 14.8501 4.83579 14.8501 5.25V18.75C14.8501 19.1642 14.5143 19.5 14.1001 19.5C13.6859 19.5 13.3501 19.1642 13.3501 18.75V5.25C13.3501 4.83579 13.6859 4.5 14.1001 4.5Z"
        fill={fill}
      />
      <path
        d="M17.8501 5.25C17.8501 4.83579 17.5143 4.5 17.1001 4.5C16.6859 4.5 16.3501 4.83579 16.3501 5.25V16.4357C16.3501 16.8499 16.6859 17.1857 17.1001 17.1857C17.5143 17.1857 17.8501 16.8499 17.8501 16.4357V5.25Z"
        fill={fill}
      />
      <path
        d="M20.8501 5.25C20.8501 4.83579 20.5143 4.5 20.1001 4.5C19.6859 4.5 19.3501 4.83579 19.3501 5.25V18.75C19.3501 19.1642 19.6859 19.5 20.1001 19.5C20.5143 19.5 20.8501 19.1642 20.8501 18.75V5.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default CodesMobileIcon;
