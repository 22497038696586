import { useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import styles from "./styles.module.scss";
import Metapay from "../../modules/MetapayAnd3DSCodes/Metapay";
import ThreeDS from "../../modules/MetapayAnd3DSCodes/ThreeDS";

const MetapayAndThreeDs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [{ title: "METAPAY" }, { title: "3DS" }];
  const componentsToRender = [<Metapay />, <ThreeDS />];

  return (
    <div className={styles.mainContainer}>
      <h1 className="H1">Codes</h1>
      <Pagination tabs={tabs} setActive={setActiveTab} active={activeTab} />

      <div className={styles.containerCards}>
        {componentsToRender[activeTab]}
      </div>
    </div>
  );
};

export default MetapayAndThreeDs;
