const AccountIcon = ({ onClick }) => {
  return (
    <>
      <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style={{ cursor: "pointer" }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.25 8.25C7.83578 8.25 7.5 8.58578 7.5 9C7.5 9.41415 7.83578 9.75 8.25 9.75H15.75C16.1642 9.75 16.5 9.41415 16.5 9C16.5 8.58578 16.1642 8.25 15.75 8.25H8.25Z"
          fill="white"
        />
        <path
          d="M7.5 12C7.5 11.5858 7.83578 11.25 8.25 11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4141 16.1642 12.75 15.75 12.75H8.25C7.83578 12.75 7.5 12.4141 7.5 12Z"
          fill="white"
        />
        <path
          d="M12.375 14.25C11.9608 14.25 11.625 14.5858 11.625 15C11.625 15.4141 11.9608 15.75 12.375 15.75H15.75C16.1642 15.75 16.5 15.4141 16.5 15C16.5 14.5858 16.1642 14.25 15.75 14.25H12.375Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.7657 21.7089C12.2852 21.9599 11.7121 21.9587 11.2325 21.706L8.70382 20.373C8.6592 20.3495 8.6058 20.35 8.56162 20.3742L6.19457 21.6749C5.09494 22.2791 3.75 21.4835 3.75 20.2288V3.76629C3.75 2.51384 5.09059 1.71802 6.1901 2.31776L8.56238 3.61174C8.60633 3.63569 8.65928 3.63617 8.70368 3.61301L11.2362 2.29045C11.7148 2.04055 12.2852 2.04055 12.7638 2.29045L15.2963 3.61301C15.3407 3.63617 15.3937 3.63569 15.4376 3.61174L17.8099 2.31776C18.9094 1.71802 20.25 2.51384 20.25 3.76629V20.2341C20.25 21.4865 18.9094 22.2824 17.8099 21.6827L15.4376 20.3887C15.3937 20.3647 15.3407 20.3642 15.2963 20.3874L12.7657 21.7089ZM11.932 20.379C11.9756 20.402 12.0277 20.4021 12.0714 20.3793L14.602 19.0578C15.0898 18.803 15.6727 18.8083 16.1559 19.0718L18.5282 20.3658C18.6281 20.4203 18.75 20.348 18.75 20.2341V3.76629C18.75 3.65242 18.6281 3.58008 18.5282 3.6346L16.1559 4.92858C15.6727 5.19214 15.0898 5.19741 14.602 4.94263L12.0695 3.62007C12.0259 3.59735 11.9741 3.59735 11.9305 3.62007L9.39802 4.94263C8.91015 5.19741 8.32733 5.19214 7.8441 4.92858L5.47183 3.6346C5.37188 3.58008 5.25 3.65242 5.25 3.76629V20.2288C5.25 20.3429 5.37226 20.4152 5.47223 20.3603L7.8393 19.0596C8.32523 18.7926 8.91277 18.7875 9.40327 19.046L11.932 20.379Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default AccountIcon;
