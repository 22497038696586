import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useGetUserRole = () => {
  const { userData } = useContext(AuthContext);

  return { userRole: userData?.role };
};

export default useGetUserRole;
