import React from "react";

const ErcIcon = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2984 0L10.1777 0.410072V12.3094L10.2984 12.4298L15.8219 9.16489L10.2984 0Z"
        fill="#343434"
      />
      <path
        d="M10.2989 0L4.77539 9.16489L10.2989 12.4299V6.6543V0Z"
        fill="#8C8C8C"
      />
      <path
        d="M10.2985 13.4757L10.2305 13.5586V17.7974L10.2985 17.996L15.8253 10.2124L10.2985 13.4757Z"
        fill="#3C3C3B"
      />
      <path
        d="M10.2989 17.996V13.4757L4.77539 10.2124L10.2989 17.996Z"
        fill="#8C8C8C"
      />
      <path
        d="M10.3008 12.4299L15.8242 9.16501L10.3008 6.65442V12.4299Z"
        fill="#141414"
      />
      <path
        d="M4.77539 9.16501L10.2988 12.4299V6.65442L4.77539 9.16501Z"
        fill="#393939"
      />
    </svg>
  );
};

export default ErcIcon;
