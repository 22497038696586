import { Group } from "@mantine/core";
import React from "react";
import { Button } from "../../../components/Button/Button";
import styles from "./styles.module.scss";

const HeaderTable = ({
  handleOpenModalsInteractive,
  selectedRows,
  handleOpenCardIssue,
  setIsSetLimitsOpen,
}) => {
  const isDisabled = selectedRows.length === 0;

  return (
    <Group
      position="apart"
      p="md"
      style={{ padding: "16px 0px", marginBottom: "20px" }}
    >
      <Button onClick={handleOpenCardIssue} className={styles.issueCard}>
        Issue card
      </Button>

      <Group className={styles.containerBtnsInteractive}>
        <Button
          onClick={() => setIsSetLimitsOpen(true)}
          className={styles.cardsBtnsFunctional}
          disabled={isDisabled}
        >
          Set limits
        </Button>
        <Group>
          <Button
            onClick={() => handleOpenModalsInteractive("holdCard")}
            className={styles.cardsBtnsFunctional}
            disabled={isDisabled}
          >
            Hold card
          </Button>
          <Button
            onClick={() => handleOpenModalsInteractive("unholdCard")}
            className={styles.cardsBtnsFunctional}
            disabled={isDisabled}
          >
            Unhold card
          </Button>
        </Group>

        <Group>
          <Button
            onClick={() => handleOpenModalsInteractive("activateThreeDS")}
            className={styles.cardsBtnsFunctional}
            disabled={isDisabled}
          >
            Activate 3DS
          </Button>

          <Button
            onClick={() => handleOpenModalsInteractive("deactivateThreeDS")}
            className={styles.cardsBtnsFunctional}
            disabled={isDisabled}
          >
            Deactivate 3DS
          </Button>
        </Group>
        <Button
          onClick={() => handleOpenModalsInteractive("closeCard")}
          className={styles.closeCard}
          disabled={isDisabled}
        >
          Close card
        </Button>
      </Group>

      {/* MOBILE VERSION */}

      <Group className={styles.containerBtnsInteractiveMobile}>
        <Button
          onClick={() => setIsSetLimitsOpen(true)}
          className={styles.cardsBtnsFunctional}
          disabled={isDisabled}
        >
          Set limits
        </Button>
        <Button
          onClick={() => handleOpenModalsInteractive("holdCard")}
          className={styles.cardsBtnsFunctional}
          disabled={isDisabled}
        >
          Hold card
        </Button>
        <Button
          onClick={() => handleOpenModalsInteractive("unholdCard")}
          className={styles.cardsBtnsFunctional}
          disabled={isDisabled}
        >
          Unhold card
        </Button>
        <Button
          onClick={() => handleOpenModalsInteractive("activateThreeDS")}
          className={styles.cardsBtnsFunctional}
          disabled={isDisabled}
        >
          Activate 3DS
        </Button>

        <Button
          onClick={() => handleOpenModalsInteractive("deactivateThreeDS")}
          className={styles.cardsBtnsFunctional}
          disabled={isDisabled}
        >
          Deactivate 3DS
        </Button>
        <Button
          onClick={() => handleOpenModalsInteractive("closeCard")}
          className={styles.closeCard}
          disabled={isDisabled}
        >
          Close card
        </Button>
      </Group>
    </Group>
  );
};

export default HeaderTable;
