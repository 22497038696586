import React from "react";

const MenuActiveCross = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.094 1.10572C14.3869 1.3986 14.3869 1.87349 14.094 2.16638L8.26037 8.00001L14.094 13.8336C14.3869 14.1265 14.3869 14.6014 14.094 14.8943C13.8011 15.1872 13.3262 15.1872 13.0333 14.8943L7.19971 9.06067L1.36608 14.8943C1.07319 15.1872 0.5983 15.1872 0.305416 14.8943C0.0125325 14.6014 0.0125325 14.1265 0.305416 13.8336L6.13905 8.00001L0.305416 2.16638C0.0125253 1.87349 0.0125325 1.3986 0.305416 1.10572C0.5983 0.812833 1.07319 0.812826 1.36608 1.10572L7.19971 6.93935L13.0333 1.10572C13.3262 0.812826 13.8011 0.812833 14.094 1.10572Z"
        fill="#21A038"
      />
    </svg>
  );
};

export default MenuActiveCross;
