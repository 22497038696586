import React from "react";

const MasterCardIcon = () => {
  return (
    <svg
      width="52"
      height="32"
      viewBox="0 0 52 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.849 3.42285H18.8477V28.5775H32.849V3.42285Z"
        fill="#FF5A00"
      />
      <path
        d="M19.7799 16C19.7799 10.8893 22.1825 6.3534 25.8693 3.42265C23.156 1.28932 19.7333 0 16 0C7.15599 0 0 7.15599 0 16C0 24.844 7.15599 32 16 32C19.7333 32 23.156 30.7107 25.8693 28.5773C22.1773 25.688 19.7799 21.1107 19.7799 16Z"
        fill="#EB001B"
      />
      <path
        d="M51.7384 16C51.7384 24.844 44.5824 32 35.7384 32C32.0051 32 28.5824 30.7107 25.8691 28.5773C29.6025 25.6414 31.9585 21.1107 31.9585 16C31.9585 10.8893 29.5559 6.3534 25.8691 3.42265C28.5772 1.28932 31.9999 0 35.7332 0C44.5824 0 51.7384 7.20259 51.7384 16Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default MasterCardIcon;
