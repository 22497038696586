import React from "react";

const AlarmIcon = () => {
  return (
    <>
      <svg
        width="82"
        height="82"
        viewBox="0 0 82 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.8438 54.4214C44.8438 56.5445 43.1231 58.2655 41 58.2655C38.8772 58.2655 37.1562 56.5445 37.1562 54.4214C37.1562 52.2986 38.8772 50.5776 41 50.5776C43.1231 50.5776 44.8438 52.2986 44.8438 54.4214Z"
          fill="#FF4D4D"
        />
        <path
          d="M43.5625 26.8054C43.5625 25.3902 42.4155 24.2429 41 24.2429C39.5848 24.2429 38.4375 25.3902 38.4375 26.8054V41.861C38.4375 43.2762 39.5848 44.4235 41 44.4235C42.4155 44.4235 43.5625 43.2762 43.5625 41.861V26.8054Z"
          fill="#FF4D4D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.8444 10.7583C37.1045 5.39276 44.8918 5.39276 48.152 10.7583L75.4344 55.6592C78.8237 61.2379 74.8081 68.3767 68.2806 68.3767H13.7159C7.1885 68.3767 3.17262 61.2379 6.56212 55.6592L33.8444 10.7583ZM43.7721 13.4195C42.508 11.339 39.4883 11.339 38.2242 13.4195L10.942 58.3204C9.62773 60.4835 11.1849 63.2517 13.7159 63.2517H68.2806C70.8117 63.2517 72.3686 60.4835 71.0546 58.3204L43.7721 13.4195Z"
          fill="#FF4D4D"
        />
      </svg>
    </>
  );
};

export default AlarmIcon;
