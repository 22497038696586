import React from "react";

const MenuNotActiveThreeLines = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.94971 4.25C3.5355 4.25 3.19971 4.58579 3.19971 5C3.19971 5.41421 3.5355 5.75 3.94971 5.75H20.4497C20.8639 5.75 21.1997 5.41421 21.1997 5C21.1997 4.58579 20.8639 4.25 20.4497 4.25H3.94971Z"
        fill="#222222"
      />
      <path
        d="M3.19971 12C3.19971 11.5858 3.5355 11.25 3.94971 11.25H20.4497C20.8639 11.25 21.1997 11.5858 21.1997 12C21.1997 12.4142 20.8639 12.75 20.4497 12.75H3.94971C3.5355 12.75 3.19971 12.4142 3.19971 12Z"
        fill="#222222"
      />
      <path
        d="M3.19971 19C3.19971 18.5858 3.5355 18.25 3.94971 18.25H20.4497C20.8639 18.25 21.1997 18.5858 21.1997 19C21.1997 19.4142 20.8639 19.75 20.4497 19.75H3.94971C3.5355 19.75 3.19971 19.4142 3.19971 19Z"
        fill="#222222"
      />
    </svg>
  );
};

export default MenuNotActiveThreeLines;
