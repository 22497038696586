import React from "react";

const ArrowBottom = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6161 7.88141C20.9578 8.22311 20.9578 8.77713 20.6161 9.11884L14.2093 15.5256C12.989 16.7459 11.0103 16.7459 9.78995 15.5256L3.38324 9.11884C3.04153 8.77713 3.04153 8.22311 3.38324 7.8814C3.72495 7.53969 4.27897 7.53969 4.62068 7.8814L11.0274 14.2881C11.5643 14.8251 12.4349 14.8251 12.9719 14.2881L19.3786 7.88141C19.7203 7.5397 20.2743 7.5397 20.6161 7.88141Z"
        fill="#21A038"
      />
    </svg>
  );
};

export default ArrowBottom;
