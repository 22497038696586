import React from "react";
import ForAdvertising from "../../images/ForAdvertising.png";
import styles from "./styles.module.scss";
import { getBackgroundForSystem } from "../../utils/getBackgroundForSystem";

const CardItem = ({ card }) => {
  return (
    <div
      className={styles.cardItemBackground}
      style={{
        backgroundImage: getBackgroundForSystem(card?.system),
        backgroundPosition: "center",
        zIndex: "5",
        maxHeight: "220px",
        height: "220px",
        minWidth: "348px",
        maxWidth: "348px",
        width: "384x",
        position: "relative",
        borderRadius: "16px",
      }}
    >
      {card ? (
        <>
          <p className={styles.cardItemP}>
            {card?.bin.slice(0, 4)} {card?.bin.slice(4, 8)}{" "}
            <span
              className={styles.cardItemSpan}
              style={{ letterSpacing: "0.54rem" }}
            >
              .... ....
            </span>
          </p>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CardItem;
