import { useCallback } from "react";
import styles from "./styles.module.scss";
import { Button } from "../Button/Button";

const Pagination = ({ tabs, active, setActive }) => {
  const handleTabClick = useCallback(
    (index) => {
      if (typeof setActive === "function") {
        setActive(index);
      } else {
        return;
      }
    },
    [setActive]
  );

  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => (
        <Button
          key={index}
          className={`${styles.tab} ${active === index ? styles.active : ""} ${
            tabs.length === 1 ? styles.singleTab : ""
          }`}
          onClick={() => handleTabClick(index)}
        >
          <div className={styles.containerContent}>
            {tab.icon && tab.icon}
            {tab.title}
          </div>
        </Button>
      ))}
    </div>
  );
};

export default Pagination;
