import React from "react";
import { Button } from "../Button/Button";
import FilterActive from "../../icons/FilterActive";
import FilterUnactive from "../../icons/FilterUnactive";
import styles from "./style.module.scss";

const FiltersButton = ({ isActive, onClick }) => {
  return (
    <Button
      className={styles.buttonFilter}
      style={{
        border: isActive ? "2px solid #21a038" : "none",
      }}
      onClick={onClick}
    >
      <span className={styles.text}>Filters</span>{" "}
      {isActive ? (
        <FilterActive className={styles.icon} />
      ) : (
        <FilterUnactive className={styles.icon} />
      )}
    </Button>
  );
};

export default FiltersButton;
