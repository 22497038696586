import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./styles.module.scss";
import ArrowLeft from "../../../icons/ArrowLeft";
import { Button } from "../../../components/Button/Button";

import CardDeatailsHeader from "../../../modules/CardDetails/CardDeatailsHeader";
import ReloadIcon from "../../../icons/ReloadIcon";
import TableCardPayments from "../../../modules/Tables/CardHistoryTable/TableCardPayments";
import clsx from "clsx";
import { useClientInvalidateData } from "../../../hooks/useClientInvalidateData";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import TableFilters from "../../../modules/TableFilters/CardHistory";
import ExcelButton from "../../../components/ExcelButton/ExcelButton";
import { getCard } from "../../../api/cardsApi";
import { useQuery } from "@tanstack/react-query";
import { handleExcelDownload } from "../../../utils/excelDownload";
import {
  getExportCardHistory,
  getExportPayments,
} from "../../../api/paymentsApi";

const CardDetail = () => {
  const navigate = useNavigate();
  const [isFiltersShow, setIsFiltersShow] = useState(false);
  const { isFetching, handleInvalidateData } = useClientInvalidateData({
    queryKey: "cardHistory",
  });
  const [filters, setFilters] = useState({
    dateFrom: null,
    dateTo: null,
    type: null,
  });

  const { id } = useParams();

  const {
    data: cardDataInfo,
    isLoading: isCardDataLoading,
    isFetching: isCardDataFetching,
  } = useQuery({
    queryKey: ["cardDataInformation", id],
    queryFn: () => getCard(id),
    staleTime: 2 * 60 * 1000,
    cacheTime: 2 * 60 * 1000,
  });

  console.log("Filters:", filters);
  console.log("Card ID:", cardDataInfo?.data?.cardId);

  return (
    <div className={styles.wrapperCardDetails}>
      <div className={styles.containerHeader}>
        <h1>Card details</h1>
        <Button onClick={() => navigate(-1)}>
          <ArrowLeft />
          Go back
        </Button>
      </div>

      <div className={styles.containerCardDetails}>
        <CardDeatailsHeader
          cardDataInfo={cardDataInfo}
          isCardDataLoading={isCardDataLoading}
          isCardDataFetching={isCardDataFetching}
        />

        <div>
          <div className={styles.containerTitle}>
            <div className={styles.rightHeaderItems}>
              <h2 className={clsx("H1", styles.titleTable)}>Card history</h2>
              <FiltersButton
                isActive={isFiltersShow}
                onClick={() => setIsFiltersShow((prev) => !prev)}
              />
              <ReloadIcon
                className={styles.reloadIcon}
                onClick={handleInvalidateData}
                style={{
                  animation: isFetching ? "spin 1s linear infinite" : "none",
                }}
              />
            </div>

            <ExcelButton
              onSubmit={() =>
                handleExcelDownload({
                  fn: () =>
                    getExportPayments({
                      params: {
                        ...filters,
                        cardId: cardDataInfo?.data?.cardId,
                      },
                    }),
                  fileName: "payments",
                })
              }
              isDisable={!cardDataInfo?.data?.cardId}
            />
          </div>

          <div className={styles.containerExcelMobile}>
            <div className={styles.containerTitleCardMobile}>
              <h2 className={clsx("H1", styles.titleTable)}>Card history</h2>

              <ReloadIcon
                className={styles.reloadIcon}
                onClick={handleInvalidateData}
                style={{
                  animation: isFetching ? "spin 1s linear infinite" : "none",
                }}
              />
            </div>
            <div className={styles.containerFiltersMobile}>
              <FiltersButton
                isActive={isFiltersShow}
                onClick={() => setIsFiltersShow((prev) => !prev)}
              />
              <ExcelButton
                onSubmit={() =>
                  handleExcelDownload({
                    fn: () =>
                      getExportPayments({
                        params: {
                          ...filters,
                          cardId: cardDataInfo?.data?.cardId,
                        },
                      }),
                    fileName: "payments",
                  })
                }
                isDisable={!!cardDataInfo?.data?.cardId}
              />
            </div>
          </div>

          {isFiltersShow && (
            <TableFilters setFilters={setFilters} filters={filters} />
          )}

          <TableCardPayments
            cardId={cardDataInfo?.data?.cardId}
            filters={filters}
          />
        </div>
      </div>
    </div>
  );
};

export default CardDetail;
