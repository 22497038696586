import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../pages/constants";
import { showToast } from "../modules/Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../constants/constants";
import { useQueryClient } from "@tanstack/react-query";
import eventEmitter from "../utils/emitter/EventEmitter";

const useLogoutUser = ({ setToken, token }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setToken("");
    queryClient.clear();
    navigate(PAGE_ROUTES.LOGIN);
    showToast("blocked", NOTIFICATION_INFO.ACCOUNT_BLOCKED_INFORM);
  }, []);

  useEffect(() => {
    eventEmitter.on("logout", logout);

    return () => {
      eventEmitter.off("logout", logout);
    };
  }, [logout]);
};

export default useLogoutUser;
