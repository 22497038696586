import { useForm } from "react-hook-form";
import Modal from "../Modal";
import { useContext, useEffect, useState } from "react";
import { AccountSelect } from "../../../components/AccountSelect/AccountSelect";
import { updateUser, userCreate } from "../../../api/userApi";

import { Button } from "@mantine/core";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { StyledTextField } from "../../../components/Inputs/Input";
import { useQueryClient } from "@tanstack/react-query";
import { showToast } from "../../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";

const ModalAddMember = ({ setModals, modals, isEdit, userData }) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      nickName: "",
      role: "",
      login2Fa: "One time password received via E-mail",
      loginApprove2Fa: "One time password received via E-mail",
    },
  });
  const { email, name, nickName, role } = watch();

  const { userData: profileData } = useContext(AuthContext);
  const myRole = profileData?.role;

  useEffect(() => {
    if (isEdit && userData) {
      setValue("name", userData.name || "");
      setValue("email", userData.email || "");
      setValue("nickName", userData.telegram || "");
      setValue("role", userData.role);
    }
  }, [isEdit, userData, setValue]);

  const roleOptions = () => {
    switch (myRole) {
      case "Owner":
        return [
          { value: "Member", label: "Member" },
          { value: "Team Lead", label: "Team Lead" },
        ];
      case "Team Lead":
        return [{ value: "Member", label: "Member" }];
      case "Member":
      default:
        return [];
    }
  };

  const options = roleOptions();

  const addNewMember = async (data) => {
    try {
      const accounts = [...selectedAccounts.map((el) => `${el.ID}`)];
      const body = {
        name: data.name,
        email: data.email,
        telegram: data.nickName,
        "2fa_login_type": "email",
        "2fa_approve_type": "email",
        accounts: accounts,
      };

      if (isEdit) {
        delete body.email;

        await updateUser(userData.id, body);
        showToast("accUpdate", NOTIFICATION_INFO.ACCOUNT_UPDATED);
      } else {
        await userCreate(body);
        showToast("accCreate", NOTIFICATION_INFO.ACCOUNT_CREATED);
      }
      queryClient.invalidateQueries(["tableData"]);

      setModals((prev) => ({
        ...prev,
        modalAddMember: false,
      }));
      reset();
      setSelectedAccounts([]);
    } catch (error) {
      if (error.response.data.data.message) {
        setError("serverError", { message: error.response.data.data.message });
      }
      console.error(error.response.data.data);
    }
  };

  const handleCloseModal = () => {
    setModals((prev) => ({
      ...prev,
      modalAddMember: false,
    }));
    reset();
    setSelectedAccounts([]);
  };

  useEffect(() => {
    if (errors.serverError) {
      clearErrors("serverError");
    }
  }, [email, name, nickName]);

  return (
    <>
      {modals.modalAddMember && (
        <div className={styles.modalBackground}>
          <div className={styles.containerModal}>
            <p className="H1">
              {isEdit ? `Redact ${name}` : `Add member ${name}`}
            </p>
            <span className={clsx("body1", styles.textModalDescription)}>
              {isEdit
                ? 'Make changes to users details and click on "Save" to save them.'
                : " Fill in the details of the new team member and click on 'Invite member' button to send him an invitation."}
            </span>

            <form onSubmit={handleSubmit(addNewMember)}>
              <div className={styles.containerInputs}>
                {errors.serverError && (
                  <p style={{ color: "red" }}>{errors.serverError.message}</p>
                )}
                <StyledTextField
                  id="outlined-basic"
                  label="Member's name"
                  variant="outlined"
                  {...register("name", { required: "Name is required" })}
                  error={!!errors.name}
                  placeholder="Enter a name"
                  helperText={errors.name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    marginTop: "20px",
                    fontFamily: "Noto Sans",
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      backgroundColor: "white",
                      padding: "0 3px",
                    },
                  }}
                />

                <StyledTextField
                  id="outlined-basic"
                  label="Authorization E-mail"
                  variant="outlined"
                  {...register("email", { required: "Email is required" })}
                  error={!!errors.email}
                  placeholder="Enter an E-mail"
                  helperText={errors.email?.message}
                  style={{
                    color: isEdit ? "#aaaaaa" : "black",
                    fontFamily: "Noto Sans",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: isEdit,
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      backgroundColor: "white",
                      padding: "0 3px",
                    },
                  }}
                  defaultValue={isEdit ? userData.email : ""}
                />
                <StyledTextField
                  id="outlined-basic"
                  label="Telegram nickname"
                  variant="outlined"
                  {...register("nickName")}
                  // error={!!errors.name}
                  placeholder="Enter a nickname"
                  onChange={(event) => setValue("nickName", event.target.value)}
                  value={watch("nickName")}
                  // helperText={errors.name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    fontFamily: "Noto Sans",
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      backgroundColor: "white",
                      padding: "0 3px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          marginLeft: "12px !important",
                          fontWeight: "700",
                          color: "#AAAAAA",
                        }}
                      >
                        @
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl
                  fullWidth
                  sx={{
                    maxHeight: "40px",
                    fontFamily: "Noto Sans",
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "40px",
                      fontWeight: "500",
                      fontSize: "14px",
                    },
                  }}
                  error={!!errors.login2Fa}
                >
                  <InputLabel
                    id="2FA-select-label"
                    shrink
                    sx={{
                      top: "-2px",
                      backgroundColor: "white",
                      padding: "3px",
                      fontFamily: "Noto Sans",
                    }}
                  >
                    2FA for login*
                  </InputLabel>
                  <Select
                    labelId="2FA-select-label"
                    id="2FA-select"
                    value={watch("login2Fa") || ""}
                    onChange={(event) =>
                      setValue("login2Fa", event.target.value)
                    }
                    displayEmpty
                    sx={{
                      fontFamily: "Noto Sans",
                      fontWeight: "400 !important",
                    }}
                  >
                    {[
                      {
                        value: "One time password received via E-mail",
                        label: "One time password received via E-mail",
                      },
                    ].map((option, idx) => (
                      <MenuItem
                        key={idx}
                        value={option.value}
                        sx={{
                          "&:hover": {
                            background:
                              "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                            margin: "0px 3px",
                            borderRadius: "4px",
                          },
                          "&.Mui-selected": {
                            background:
                              "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                            color: "#fff",
                            margin: "0px 3px",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    maxHeight: "40px",
                    fontFamily: "Noto Sans",
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "40px",
                      fontWeight: "500",
                      fontSize: "14px",
                    },
                  }}
                  error={!!errors.loginApprove2Fa}
                >
                  <InputLabel
                    id="2FA-confirm-select-label"
                    shrink
                    sx={{
                      top: "-2px",
                      backgroundColor: "white",
                      padding: "3px",
                      fontFamily: "Noto Sans",
                    }}
                  >
                    2FA for approve*
                  </InputLabel>
                  <Select
                    labelId="2FA-confirm-select-label"
                    id="2FA-confirm-select"
                    value={watch("loginApprove2Fa") || ""}
                    onChange={(event) =>
                      setValue("loginApprove2Fa", event.target.value)
                    }
                    displayEmpty
                    sx={{
                      fontFamily: "Noto Sans",
                      fontWeight: "400 !important",
                    }}
                  >
                    {[
                      {
                        value: "One time password received via E-mail",
                        label: "One time password received via E-mail",
                      },
                    ].map((option, idx) => (
                      <MenuItem
                        key={idx}
                        value={option.value}
                        sx={{
                          "&:hover": {
                            background:
                              "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                            margin: "0px 3px",
                            color: "#fff",

                            borderRadius: "4px",
                          },
                          "&.Mui-selected": {
                            background:
                              "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                            color: "#fff",
                            margin: "0px 3px",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.loginApprove2Fa && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "4px",
                      }}
                    >
                      {errors.loginApprove2Fa.message}
                    </p>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    maxHeight: "40px",
                    fontFamily: "Noto Sans",
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "40px",
                      fontWeight: "500",
                      fontSize: "14px",
                    },
                  }}
                  error={!!errors.role}
                >
                  <InputLabel
                    id="role-select-label"
                    shrink
                    sx={{
                      top: "-2px",
                      backgroundColor: "white",
                      padding: "3px",
                      fontFamily: "Noto Sans",
                    }}
                  >
                    Role*
                  </InputLabel>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    value={role || ""}
                    onChange={(event) => setValue("role", event.target.value)}
                    displayEmpty
                    {...register("role", { required: "Role is required" })}
                    sx={{
                      fontFamily: "Noto Sans",
                      fontWeight: "400 !important",
                    }}
                  >
                    <MenuItem value="" sx={{ display: "none" }} disabled>
                      Assign a role
                    </MenuItem>
                    {options.map((option, idx) => (
                      <MenuItem
                        key={idx}
                        value={option.value}
                        sx={{
                          "&:hover": {
                            background:
                              "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                            margin: "0px 3px",
                            color: "#fff",

                            borderRadius: "4px",
                          },
                          "&.Mui-selected": {
                            background:
                              "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                            color: "#fff",
                            margin: "0px 3px",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.role && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "4px",
                      }}
                    >
                      {errors.role.message}
                    </p>
                  )}
                </FormControl>
                {/* <FormControl>
                  <InputLabel
                    id="2FA-confirm-select"
                    shrink
                    sx={{
                      top: "-2px",
                      backgroundColor: "white",
                      padding: "3px",
                    }}
                  >
                    2FA for approve
                  </InputLabel>
                  <Select
                    key="2FA-confirm-select"
                    id="2FA-confirm-select"
                    placeholder="One time password received via E-mail"
                    data={[
                      {
                        value: "One time password received via E-mail",
                        label: "One time password received via E-mail",
                      },
                    ]}
                    value={watch("login2Fa")}
                    onChange={(value) => setValue("loginApprove2Fa", value)}
                    error={
                      errors.loginApprove2Fa && errors.loginApprove2Fa.message
                    }
                    required
                  />
                </FormControl> */}
                {/* <FormControl>
                  <InputLabel
                    id="role-select"
                    shrink
                    sx={{
                      top: "-2px",
                      backgroundColor: "white",
                      padding: "3px",
                    }}
                  >
                    Role*
                  </InputLabel>
                  <Select
                    key="role-select"
                    id="role-select"
                    placeholder={role || "Assign a role"}
                    value={role}
                    data={options}
                    onChange={(value) => setValue("role", value)}
                    error={errors.role && errors.role.message}
                    required
                  />{" "}
                </FormControl> */}
              </div>
              {!isEdit && (
                <AccountSelect
                  selectedAccounts={selectedAccounts}
                  setSelectedAccounts={setSelectedAccounts}
                />
              )}

              <div className={styles.containerButtons}>
                <div className={styles.containerInviteAndText}>
                  <span className={clsx("body1", styles.textBottomInform)}>
                    {isEdit ? (
                      <>
                        We draw your attention, that{" "}
                        <strong>you can`t edit email after invitation</strong>,
                        if you want to use a new email for a user, you need to
                        create a new user account.
                      </>
                    ) : (
                      <>
                        <strong>
                          You can not edit email for an already added user
                        </strong>
                        , if you want to use a new email for a user, you need to
                        create a new user account.
                      </>
                    )}
                  </span>
                </div>
                <div className={styles.btnCloseModal}>
                  <Button type="submit" className={styles.submitBtn}>
                    {isEdit ? "Save" : " Invite member"}
                  </Button>
                  <button onClick={handleCloseModal} type="button">
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalAddMember;

// <TextInput
// key="email-input"
// label="Authorization E-mail*"
// placeholder="Enter an E-mail"
// {...register("email", { required: "Name is required" })}
// error={errors.email && errors.email.message}
// required
// /> <TextInput
//                 key="nickname-input"
//                 label="Telegram nickname"
//                 placeholder="@ Enter a nickname"
//                 {...register("nickName", { required: "Name is required" })}
//                 error={errors.nickName && errors.nickName.message}
//                 required
//               />
//               <Select
//                 key="2FA-select"
//                 label="2FA for login"
//                 placeholder="One time password received via E-mail"
//                 data={[
//                   {
//                     value: "One time password received via E-mail",
//                     label: "One time password received via E-mail",
//                   },
//                 ]}
//                 value={watch("login2Fa")}
//                 onChange={(value) => setValue("login2Fa", value)}
//                 error={errors.login2Fa && errors.login2Fa.message}
//                 required
//               />
//               <Select
//                 key="2FA-confirm-select"
//                 label="2FA for approve"
//                 placeholder="One time password received via E-mail"
//                 data={[
//                   {
//                     value: "One time password received via E-mail",
//                     label: "One time password received via E-mail",
//                   },
//                 ]}
//                 value={watch("login2Fa")}
//                 onChange={(value) => setValue("loginApprove2Fa", value)}
//                 error={errors.loginApprove2Fa && errors.loginApprove2Fa.message}
//                 required
//               />
//               <Select
//                 key="role-select"
//                 label="Role"
//                 placeholder={role || "Assign a role"}
//                 value={role}
//                 data={[
//                   {
//                     value: "member",
//                     label: "Member",
//                   },
//                   {
//                     value: "teamLead",
//                     label: "Team Lead",
//                   },
//                 ]}
//                 onChange={(value) => setValue("role", value)}
//                 error={errors.role && errors.role.message}
//                 required
//               />
//               <AccountSelect
//                 selectedAccounts={selectedAccounts}
//                 setSelectedAccounts={setSelectedAccounts}
//               />
//               <Button type="submit">
//                 {isEdit ? "Save" : " Invite member"}
//               </Button>
//               <span>
//                 {isEdit
//                   ? "We draw your attention, that you can`t edit email after invitation, if you want to use a new email for a user, you need to create a new user account."
//                   : "You can not edit email for an already added user, if you want to use a new email for a user, you need to create a new user account."}
//               </span>
//               <button
//                 style={{ background: "red" }}
//                 onClick={handleCloseModal}
//                 type="button"
//               >
//                 Close
//               </button>
