import React from "react";
import QuestionIcons from "../../icons/QuestionIcons";
import { PAGE_ROUTES } from "../../pages/constants";
import masterCard from "../../images/mastercard.png";
import unionCard from "../../images/unionPay.png";
import visa from "../../images/visa.png";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { toast } from "react-toastify";
import CopyIcon from "../../icons/CopyIcon";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { getCorrectCardType } from "../../utils/getCorrectCardType";
import CardNumber from "../../components/CardNumber/CardNumber";

const ColumnsTreeDsCodes = () => {
  const columns = [
    {
      accessorKey: "cardMask",
      header: "DATE",
      enableSorting: false,
    },
    {
      accessorKey: "cardNumber",
      header: "CARD NUMBER",
      enableSorting: false,
      accessorFn: (row) => (row.cardMask !== null ? row.cardMask : "No info"),

      Cell: ({ cell, row }) => {
        const cardNum = cell.getValue() !== null ? cell.getValue() : "No info";
        const paymentSystem = row.original.cardType;
        const cardId = row.original.cardId;
        const navigate = useNavigate();

        const handleNavigateToCard = () => {
          navigate(`${PAGE_ROUTES.CARD_DETAILES.replace(":id", cardId)}`);
        };

        return (
          <div className={styles.containerCardName}>
            {getCorrectCardType(paymentSystem)}
            <CardNumber cardNum={cardNum} onClick={handleNavigateToCard} />

            <CustomTooltip
              placement="bottom-start"
              title="Click to see card details "
              arrow
            >
              <span className={styles.containerQuestion}>
                <QuestionIcons />
              </span>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      accessorKey: "cardName",
      header: "CARD NAME",
      enableSorting: false,

      Cell: ({ cell, row }) => {
        const cardName = cell.getValue() !== null ? cell.getValue() : "No name";
        const cardId = row.original.cardOriginalId;

        const navigate = useNavigate();

        const handleNavigateToCard = () => {
          navigate(`${PAGE_ROUTES.CARD_DETAILES.replace(":id", cardId)}`);
        };

        return (
          <span
            className={`${styles.containerUserName} ${styles.cursorPointer}`}
            onClick={handleNavigateToCard}
          >
            {cardName}
          </span>
        );
      },
    },

    {
      accessorKey: "description",
      header: "MERCHANT",
      enableSorting: false,
    },

    {
      accessorKey: "code",
      enableSorting: false,

      header: "3DS OTP CODE",
      Cell: ({ cell }) => {
        const code = cell.getValue();
        const handleCopyToClipboard = async () => {
          try {
            await navigator.clipboard.writeText(code);
            toast.success("Copied to clipboard!");
          } catch (err) {
            toast.error("Failed to copy!");
            console.error("Failed to copy text: ", err);
          }
        };

        return (
          <div className={styles.containerCodes}>
            <span>{code}</span>
            <CopyIcon
              className={styles.copyIcon}
              onClick={handleCopyToClipboard}
            />
          </div>
        );
      },
    },
  ];

  return columns;
};

export default ColumnsTreeDsCodes;
