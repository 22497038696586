import { useIsFetching, useQueryClient } from "@tanstack/react-query";

export const useClientInvalidateData = ({ queryKey }) => {
  const queryClient = useQueryClient();

  const handleInvalidateData = () => {
    queryClient.invalidateQueries([queryKey]);
  };

  const isFetching = useIsFetching([queryKey]);

  return { handleInvalidateData, isFetching, queryClient };
};
