import React from "react";

const QuestionIcons = ({ fill = "#232323", style }) => {
  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7725 16.875C13.7725 17.3928 13.3528 17.8125 12.835 17.8125C12.3172 17.8125 11.8975 17.3928 11.8975 16.875C11.8975 16.3572 12.3172 15.9375 12.835 15.9375C13.3528 15.9375 13.7725 16.3572 13.7725 16.875Z"
          fill={fill}
        />
        <path
          d="M10.585 10.125C10.585 9.18045 11.4952 8.25 12.8353 8.25C14.1754 8.25 15.0856 9.18045 15.0856 10.125C15.0856 10.5785 14.9562 10.8512 14.788 11.0552C14.5982 11.2854 14.3263 11.4697 13.9555 11.6876C13.9089 11.715 13.8592 11.7436 13.8075 11.7736C13.4948 11.9539 13.1011 12.1812 12.7939 12.4664C12.3896 12.8417 12.0853 13.3528 12.0853 14.0625C12.0853 14.4767 12.4211 14.8125 12.8353 14.8125C13.2495 14.8125 13.5853 14.4767 13.5853 14.0625C13.5853 13.8453 13.656 13.7128 13.8144 13.5658C13.9875 13.405 14.2111 13.2749 14.5322 13.0879C14.59 13.0542 14.651 13.0187 14.7154 12.9809C15.0946 12.758 15.5728 12.4614 15.9456 12.0091C16.34 11.5306 16.5856 10.919 16.5856 10.125C16.5856 8.17005 14.8093 6.75 12.8353 6.75C10.8613 6.75 9.08496 8.17005 9.08496 10.125C9.08496 10.5392 9.42074 10.875 9.83496 10.875C10.2492 10.875 10.585 10.5392 10.585 10.125Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.08496 12C3.08496 6.61522 7.45019 2.25 12.835 2.25C18.2197 2.25 22.585 6.61522 22.585 12C22.585 17.3848 18.2197 21.75 12.835 21.75C7.45019 21.75 3.08496 17.3848 3.08496 12ZM12.835 3.75C8.27861 3.75 4.58496 7.44365 4.58496 12C4.58496 16.5563 8.27861 20.25 12.835 20.25C17.3913 20.25 21.085 16.5563 21.085 12C21.085 7.44365 17.3913 3.75 12.835 3.75Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default QuestionIcons;
