import React from "react";

const StartIcon = ({ className, onClick }) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        onClick={onClick}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="3.2" fill="#D9FFE0" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6006 10.2382C10.6006 9.14051 11.8063 8.4698 12.7389 9.04867L22.0219 14.8106C22.9042 15.3582 22.9042 16.6419 22.0219 17.1895L12.7389 22.9514C11.8063 23.5302 10.6006 22.8596 10.6006 21.7619V10.2382ZM12.1061 10.0682C11.9728 9.98555 11.8006 10.0814 11.8006 10.2382V21.7619C11.8006 21.9187 11.9728 22.0146 12.1061 21.9318L21.3891 16.17C21.5151 16.0918 21.5151 15.9083 21.3891 15.8301L12.1061 10.0682Z"
          fill="#21A038"
        />
      </svg>
    </>
  );
};

export default StartIcon;
