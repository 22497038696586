import React from "react";
import styles from "./AddUserModal.module.scss";
import { Button } from "../../../components/Button/Button";
import { grantAccessToAcc } from "../../../api/getAccounts";
import { showToast } from "../../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";

const AddUserModal = ({ setAddMemberModal, addUserData, reload }) => {
  const handleAddUser = async (addUserData) => {
    try {
      const id = addUserData.iban;
      const body = {
        userIds: [addUserData.id],
      };

      await grantAccessToAcc(id, body);
      setAddMemberModal(false);
      showToast("success", NOTIFICATION_INFO.USER_ADD);
      reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.addUserModal}>
      <div className={styles.addUserModalContainer}>
        <h1>Add user</h1>
        <p className={styles.addUserModalContainerText}>
          User {addUserData?.name || "No name"}{" "}
          {addUserData?.email || "No email"} will have access{" "}
          <span>
            to this account “{addUserData?.accountName}” and all its cards also.
          </span>
          <p>Are you sure you want to add this user?</p>
        </p>
        <div className={styles.addUserModalContainerButtons}>
          <Button
            className={styles.addUserModalContainerButton}
            onClick={() => handleAddUser(addUserData)}
          >
            Add
          </Button>
          <Button
            className={styles.addUserModalContainerButton}
            onClick={() => setAddMemberModal(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
