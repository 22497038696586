import React from "react";
import styles from "./styles.module.scss";
import { Button } from "../../../components/Button/Button";
import DataPicker from "../../../components/DatePicker/DataPicker";
import { useQueryClient } from "@tanstack/react-query";
import { Select } from "@mantine/core";

const TableFilters = ({ filters, setFilters }) => {
  const queryClient = useQueryClient();

  const handleDateChange = (value) => {
    const formatToISODate = (date) =>
      date
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
            .toISOString()
            .split("T")[0]
        : null;

    if (Array.isArray(value) && value.length === 2) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateFrom: formatToISODate(value[0]),
        dateTo: formatToISODate(value[1]),
      }));
    } else if (value) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateFrom: formatToISODate(value),
        dateTo: null,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateFrom: null,
        dateTo: null,
      }));
    }
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const clearFilters = () => {
    setFilters({
      dateFrom: null,
      dateTo: null,
      status: null,
      iban: null,
      bin: null,
    });
    setTimeout(() => handleUpdateCardsData(), 0);
  };
  const handleUpdateCardsData = () => {
    queryClient.invalidateQueries(["tableData"]);
  };

  return (
    <div className={styles.containerFilters}>
      <div className={styles.containerWithItemsFiltering}>
        <DataPicker
          mode="range"
          className={styles.item}
          label="Creation date"
          placeholder={"For the whole period"}
          value={
            filters.dateFrom && filters.dateTo
              ? [new Date(filters.dateFrom), new Date(filters.dateTo)]
              : filters.dateFrom
              ? [new Date(filters.dateFrom), null]
              : null
          }
          onChange={handleDateChange}
        />

        <Select
          label="Transaction type:"
          value={filters.type}
          onChange={(value) => handleFilterChange("type", value)}
          placeholder="Debit and Credit"
          styles={{
            input: {
              "&:focus-within": {
                borderColor: "rgb(33, 160, 56)",
                borderWidth: "1px",
              },
            },
            item: {
              "&[data-hovered]": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                color: "#fff",
                borderRadius: "4px",
              },
              "&[data-selected]": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                color: "#fff",
              },
            },
          }}
          data={[
            { value: "debit", label: "Debit" },
            { value: "credit", label: "Credit" },
          ]}
          className={styles.item}
        />
      </div>

      <div className={styles.containerFilterButtons}>
        <Button className={styles.applyFilter} onClick={handleUpdateCardsData}>
          Apply filters
        </Button>
        <Button className={styles.clearFilters} onClick={clearFilters}>
          Clear filters
        </Button>
      </div>
    </div>
  );
};

export default TableFilters;
