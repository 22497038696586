import React from "react";
import styles from "./styles.module.scss";
import ArrowRightTop from "../../icons/ArrowRightTop";

export const CardNoInfoText = "No card info";

const CardNumber = ({
  cardStateCode = null,
  cardStatusName = null,
  onClick,
  cardNum,
}) => {
  return (
    <div
      style={{
        cursor: cardStateCode || cardStatusName ? "not-allowed" : "pointer",
      }}
      onClick={onClick}
      className={styles.cardNumber}
    >
      {cardNum}
      {cardNum !== CardNoInfoText && <ArrowRightTop />}
    </div>
  );
};

export default CardNumber;
