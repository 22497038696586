import PaymentIcon from "../../icons/menuItems/PaymentIcon";
import MetaPayIcon from "../../icons/menuItems/MetaPayIcon";
import FbmIcon from "../../icons/menuItems/FbmIcon";
import ProfileIcon from "../../icons/menuItems/ProfileIcon";
import CardsIcon from "../../icons/menuItems/CardsIcon";
import TeamManagmentIcon from "../../icons/menuItems/TeamManagmentIcon";
import AccountIcon from "../../icons/menuItems/AccountIcon";
import DashboardIcon from "../../icons/menuItems/DashboardIcon";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import Skeleton from "../Skeleton/Skeleton";

import style from "./styles.module.scss";
import { AuthContext } from "../../context/AuthContext";
import { MenuItem } from "./menuItem/MenuItem";

const MenuBar = () => {
  const params = useLocation();

  const {
    userData,
    userDataIsLoading,
    totalPricesAccount,
    infoPricesTotalLoading,
  } = useContext(AuthContext);

  // if (userDataIsLoading || infoPricesTotalLoading) {
  //   return <Skeleton className={style.menuBarSkeleton} isSpinner={true} />;
  // }

  const items = [
    {
      title: "Dashboard",
      subTitle: "",
      icon: <DashboardIcon />,
      link: PAGE_ROUTES.DASHBOARD,
    },
    {
      title: "Accounts",
      subTitle: "Top-up and history",
      icon: <AccountIcon />,
      price: totalPricesAccount?.totalDepositSum?.value
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      link: PAGE_ROUTES.ACCOUNTS,
    },
    ...(userData?.role !== "Member"
      ? [
          {
            title: "Team management",
            subTitle: "",
            icon: <TeamManagmentIcon />,
            link: PAGE_ROUTES.TEAM_MANAGEMENT,
          },
        ]
      : []),
    {
      title: "Cards",
      subTitle: "",
      icon: <CardsIcon />,
      link: PAGE_ROUTES.CARDS,
    },
    {
      title: "Payments",
      subTitle: "",
      icon: <PaymentIcon />,
      link: PAGE_ROUTES.PAYMENTS,
    },
    {
      title: "METAPAY & 3DS",
      subTitle: "Codes",
      icon: <MetaPayIcon />,
      link: PAGE_ROUTES.METAPAY_AND_3DS,
    },
    {
      title: "[FBM] Services",
      subTitle: "Creo, landings, etc.",
      icon: <FbmIcon />,
      link: PAGE_ROUTES.FBM_SERVICES,
    },
    {
      title: "Profile",
      subTitle: userData?.email,
      icon: <ProfileIcon />,
      link: PAGE_ROUTES.PROFILE,
    },
  ];

  return (
    <>
      {items.map((el, idx) => {
        const isActive = params.pathname.startsWith(el.link);
        if (userDataIsLoading) {
          return (
            <Skeleton className={style.menuBarSkeleton} isSpinner={false} />
          );
        }
        return (
          <MenuItem
            userDataIsLoading={userDataIsLoading}
            key={idx}
            icon={el.icon}
            link={el.link}
            isActive={isActive}
            title={el.title}
            subTitle={el.subTitle}
            price={el.price}
          />
        );
      })}
    </>
  );
};

export default React.memo(MenuBar);
