import React from "react";

const EqualsIcon = () => {
  return (
    <svg
      style={{ minWidth: "16px" }}
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.53 4.7V2.36C1.49 1.31 2.69 0.77 4.13 0.77C5.27 0.77 6.26 0.949999 8.09 1.73C9.53 2.36 10.37 2.54 11.21 2.54C12.38 2.54 13.7 1.76 14.66 0.77V3.08C13.7 4.13 12.5 4.7 11.06 4.7C9.92 4.7 8.9 4.52 7.1 3.74C5.66 3.11 4.82 2.93 3.95 2.93C2.84 2.93 1.49 3.71 0.53 4.7ZM0.53 10.61V8.27C1.49 7.22 2.69 6.68 4.13 6.68C5.27 6.68 6.26 6.86 8.09 7.64C9.53 8.27 10.37 8.45 11.21 8.45C12.38 8.45 13.7 7.67 14.66 6.68V8.99C13.7 10.04 12.5 10.61 11.06 10.61C9.92 10.61 8.9 10.43 7.1 9.65C5.66 9.02 4.82 8.84 3.95 8.84C2.84 8.84 1.49 9.62 0.53 10.61Z"
        fill="#232323"
      />
    </svg>
  );
};

export default EqualsIcon;
