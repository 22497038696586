import React from "react";
import { useForm } from "react-hook-form";
import styles from "./styles.module.scss";
import { issueAccounts } from "../../../api/IssueAccount";
import { showToast } from "../../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";
import { StyledTextField } from "../../../components/Inputs/Input";

const ModalIssueAccount = ({ setIssueModal, reload }) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleCloseModal = () => {
    setIssueModal(false);
  };

  const onSubmit = async (data) => {
    if (data.accountName.trim().length < 3) {
      setError("accountName", {
        type: "manual",
        message: "Name must be at least 3 characters long!",
      });
      return;
    }

    try {
      const response = await issueAccounts({ name: data.accountName });

      if (response?.status === 200) {
        setIssueModal(false);
        showToast("success", NOTIFICATION_INFO.ISSUE_ACCOUNT);
        reload();
      } else {
        setError("accountName", {
          type: "manual",
          message: "Something went wrong. Please try again.",
        });
      }
    } catch (err) {
      setError("accountName", {
        type: "manual",
        message: err.message,
      });
    }
  };

  return (
    <div className={styles.modalIssue}>
      <div className={styles.modalIssueContainer}>
        <h1>Issue an account</h1>
        <p className="primaryText" style={{ marginBottom: "10px" }}>
          To release an account, you need to come up with a name for it.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ position: "relative" }}>
            <StyledTextField
              type="text"
              label="Account’s name*"
              placeholder="Enter a name"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 40 }}
              {...register("accountName", {
                required: "The field cannot be empty",
                onChange: () => clearErrors("accountName"),
              })}
              sx={{
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                  padding: "0 3px",
                },
              }}
              error={!!errors.accountName}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: "rgba(0, 0, 0, 0.6)",
                },
              }}
            />
            {errors.accountName && (
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  position: "absolute",
                  bottom: "-20px",
                  left: "0",
                }}
              >
                {errors.accountName.message}
              </span>
            )}
          </div>

          <div className={styles.modalIssueContainerButton}>
            <button
              className={styles.modalIssueContainerIssueButton}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Issuing..." : "Issue"}
            </button>
            <span>€0, It’s free</span>
          </div>
        </form>

        <button
          className={styles.modalIssueContainerExitButton}
          onClick={handleCloseModal}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ModalIssueAccount;
