import { Button, Group, Menu, Popover, Text } from "@mantine/core";
import {
  IconChevronDown,
  IconDotsVertical,
  IconPlus,
} from "@tabler/icons-react";
import React, { useState } from "react";
import styles from "../../modules/Tables/styles.module.scss";
import {
  maxHeightActions,
  maxWidthActions,
  maxWidthColumns,
  widthStatusTitle,
} from "../../styles/constants";
import {
  shouldEnableAddAccountButton,
  shouldShowActionButton,
  shouldShowRemoveButton,
} from "../../utils/shouldShowActionButton";

const ColumnsTeamManagement = ({
  handleEditUser,
  handleOpenModal,
  userData,
}) => {
  const columns = [
    {
      accessorKey: "name",
      header: "USERNAME",
      enableSorting: false,

      Cell: ({ cell }) => (
        <div
          style={{
            maxWidth: maxWidthColumns,
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {cell.getValue()}
        </div>
      ),
    },
    { accessorKey: "role", header: "ROLE", enableSorting: false },
    {
      accessorKey: "email",
      header: "LOGIN",
      enableSorting: false,
      Cell: ({ cell }) => (
        <div
          style={{
            maxWidth: maxWidthColumns,
            paddingBottom: "30x",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: "email",
      header: "Authorization Email",
      enableSorting: false,
      Cell: ({ cell }) => (
        <div
          style={{
            maxWidth: maxWidthColumns,
            paddingBottom: "30x",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: "telegram",
      header: "Telegram ID",
      enableSorting: false,
      Cell: ({ row }) => (
        <span>{row.original.telegram || "No telegram ID"}</span>
      ),
    },
    {
      accessorKey: "availableAccounts",
      header: "Available Accounts",
      enableSorting: false,

      Cell: ({ row }) => {
        const [opened, setOpened] = useState(false);
        const [isAccountGrantsOpen, setIsAccountGrantsOpen] = useState(false);

        const accountsCount = row.original.accountsCount;
        const accounts = row.original.accounts;
        const accountsToGrant = row.original.accountsToGrants;

        return (
          <Group spacing="sm" style={{ minWidth: "220px" }}>
            <Group
              spacing={0}
              style={{
                gap: "0px",
                alignItems: "center",
              }}
            >
              <Text
                size="sm"
                color="green"
                style={{ cursor: "pointer", width: "20px" }}
                onClick={() => {
                  return accountsCount > 0
                    ? setOpened((prev) => !prev)
                    : undefined;
                }}
              >
                {accountsCount}
              </Text>

              {/* Popover для просмотра текущих счетов */}
              <Popover
                opened={opened}
                onClose={() => setOpened(false)}
                position="bottom-start"
                withArrow
                withinPortal
                styles={{
                  dropdown: {
                    maxWidth: "none",
                    position: "absolute",
                    zIndex: 1000000,
                  },
                }}
              >
                <Popover.Target>
                  <Button
                    size="xs"
                    variant="subtle"
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => {
                      return accountsCount > 0
                        ? setOpened((prev) => !prev)
                        : undefined;
                    }}
                  >
                    <IconChevronDown size={16} color="green" />
                  </Button>
                </Popover.Target>
                <Popover.Dropdown
                  style={{ maxHeight: maxHeightActions, overflow: "auto" }}
                >
                  {accounts.map((account, idx) => (
                    <div
                      key={account.ID}
                      className={styles.popupTitleBlock}
                      style={{
                        marginBottom:
                          idx === accounts.length - 1 ? "0px" : "16px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        size="sm"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "2px",
                          maxWidth: maxWidthActions,
                          overflow: "auto",
                        }}
                      >
                        <p className="body1">{account.name || "Unnamed"} </p>
                        <span className="body2" style={{ color: "#AAAAAA" }}>
                          {account.iban}
                        </span>
                      </Text>
                      {shouldShowRemoveButton(userData, row.original) && (
                        <Button
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          onClick={() => {
                            return handleOpenModal("removeAccount", {
                              accountId: account.ID,
                              userId: row.original.id,
                              userName: row.original.name || "Unnamed",
                              userEmail: row.original.email || "Unnamed",
                              accountName: account.name || "Unnamed",
                            });
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  ))}
                </Popover.Dropdown>
              </Popover>

              {/* Popover для добавления новых счетов */}
              <Popover
                opened={isAccountGrantsOpen}
                onClose={() => setIsAccountGrantsOpen(false)}
                position="bottom-start"
                withArrow
                withinPortal
                styles={{
                  dropdown: {
                    maxWidth: "none",
                    position: "absolute",
                    zIndex: 1000000,
                  },
                }}
              >
                <Popover.Target>
                  <Button
                    size="xs"
                    sx={{
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",
                      border: "none",
                      "&:disabled": {
                        color: "#fff",
                        cursor: "not-allowed",
                        opacity: 0.7,
                      },
                    }}
                    rightIcon={
                      <IconPlus size={12} style={{ height: "20px" }} />
                    }
                    onClick={() =>
                      accountsToGrant.length > 0
                        ? setIsAccountGrantsOpen((prev) => !prev)
                        : undefined
                    }
                    disabled={
                      !shouldEnableAddAccountButton(userData, row.original)
                    }
                  >
                    Add account
                  </Button>
                </Popover.Target>

                <Popover.Dropdown
                  style={{ maxHeight: maxHeightActions, overflow: "auto" }}
                  position="bottom"
                >
                  {accountsToGrant.map((account, idx) => {
                    return (
                      <div
                        key={account.ID}
                        className={styles.popupTitleBlock}
                        style={{
                          marginBottom:
                            idx === accountsToGrant.length - 1 ? "0px" : "16px",
                        }}
                      >
                        <Text
                          size="sm"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            maxWidth: maxWidthActions,
                            overflow: "auto",
                            gap: "2px",
                          }}
                        >
                          <p className="body1">{account.name || "Unnamed"} </p>
                          <span className="body2" style={{ color: "#AAAAAA" }}>
                            {account?.iban || "No IBAN"}
                          </span>
                        </Text>

                        <Button
                          size="xs"
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          onClick={() => {
                            const { name, email, id } = row.original;

                            return handleOpenModal("grantAccount", {
                              accountId: account.ID,
                              userId: id,
                              userGranted: account.name,
                              nameAccount: name,
                              email: email,
                            });
                          }}
                        >
                          Grant Account
                        </Button>
                      </div>
                    );
                  })}
                </Popover.Dropdown>
              </Popover>
            </Group>
          </Group>
        );
      },
    },
    {
      accessorKey: "blocked",
      header: "USER STATUS",
      sortType: "basic",
      enableSorting: false,

      Cell: ({ cell, row }) => {
        const isBlocked = cell.getValue() === true;
        const isVerified = row.original.verified === true;
        console.log("rowwwwwww", row.original);
        let backgroundColor;
        let textColor;
        let statusText;

        if (isBlocked) {
          backgroundColor = "#FFE2E2";
          textColor = "#FF4D4D";
          statusText = "Blocked";
        } else if (!isVerified) {
          backgroundColor = "#FFE390";
          textColor = "#E2A900";
          statusText = "Invited";
        } else {
          backgroundColor = "#D9FFE0";
          textColor = "#21A038";
          statusText = "Active";
        }

        return (
          <Group
            spacing="sm"
            style={{
              display: "flex",
              alignItems: "center",

              minWidth: "250px !important",
              whiteSpace: "nowrap",
              flexDirection: "row",
            }}
          >
            <span
              className={styles.statusCard}
              style={{
                background: backgroundColor,
                color: textColor,
                width: widthStatusTitle,
              }}
            >
              {statusText}
            </span>

            <Menu>
              <Menu.Target sx={{ padding: 0 }}>
                <Button
                  variant="subtle"
                  sx={{
                    padding: 0,
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                  }}
                >
                  <IconDotsVertical
                    color="black"
                    stroke="2"
                    size={16}
                    sx={{ padding: 0, margin: 0 }}
                  />
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item onClick={() => handleEditUser(row.original)}>
                  Edit
                </Menu.Item>
                {shouldShowActionButton(userData, row.original) && (
                  <Menu.Item
                    onClick={() => {
                      console.log("rowq", row.original);
                      return handleOpenModal(
                        isBlocked ? "unblockUser" : "blockUser",
                        {
                          userId: row.original.id,
                          userName: row.original.name,
                          userEmail: row.original.email,
                        }
                      );
                    }}
                  >
                    {isBlocked ? "Unblock" : "Block"}
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          </Group>
        );
      },
    },
  ];

  return columns;
};

export default ColumnsTeamManagement;
