export function getUserRolePriority(role1, role2, add) {
  if (role1.email === role2.email && add) {
    return true;
  }

  const rolePriority = {
    Owner: 1,
    "Team Lead": 2,
    Member: 3,
  };

  return rolePriority[role1.role] > rolePriority[role2.role];
}
