import clsx from "clsx";
import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import style from "../styles.module.scss";

export const MenuItem = memo(
  ({ icon, link, isActive, title, subTitle, price, userDataIsLoading }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <Link
        className={clsx(style.containerMenuItem, {
          [style.activeMenuItem]: isActive,
          [style.activeColor]: isActive,
        })}
        to={link}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={style.iconsMenu}>
          {React.cloneElement(icon, {
            fill: isActive || isHovered ? "white" : "#232323",
          })}
        </div>
        <div className={style.containerTitles}>
          <div className={style.containerTitle}>
            <p className={clsx(style.title, "semiHedline")}>
              {title}{" "}
              {price !== undefined && price !== null && (
                <span
                  className={clsx(style.priceAcc, {
                    [style.priceActive]: isActive,
                  })}
                >
                  - €{price}
                </span>
              )}
            </p>
            {subTitle && (
              <p
                className={clsx("primaryText", style.subTitle, {
                  [style.activeColor]: isActive,
                })}
              >
                {subTitle}
              </p>
            )}
          </div>
        </div>
      </Link>
    );
  }
);
//     return (
//       <Link
//         className={clsx(style.containerMenuItem, {
//           [style.activeMenuItem]: isActive,
//           [style.activeColor]: isActive,
//         })}
//         to={link}
//       >
//         <div className={style.iconsMenu}>
//           {React.cloneElement(icon, {
//             fill: isActive ? "white" : "#232323",
//           })}
//         </div>
//         <div className={style.containerTitles}>
//           <div className={style.containerTitle}>
//             <p className={clsx(style.title, "semiHedline")}>
//               {title}{" "}
//               {price !== undefined && price !== null && (
//                 <span
//                   className={clsx(style.priceAcc, {
//                     [style.priceActive]: isActive,
//                   })}
//                 >
//                   - €{price}
//                 </span>
//               )}
//             </p>
//             {subTitle && (
//               <p
//                 className={clsx("primaryText", style.subTitle, {
//                   [style.activeColor]: isActive,
//                 })}
//               >
//                 {subTitle}
//               </p>
//             )}
//           </div>
//         </div>
//       </Link>
//     );
//   }
// );
