import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getNotifications = async () => {
  const res = await axiosAuth.get(API_PATHS.NOTIFICATIONS);
  return res.data;
};

export const markAsReadNotifications = async (id) => {
  return axiosAuth.post(`${API_PATHS.NOTIFICATIONS}/${id}/read`);
};
