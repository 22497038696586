import styles from "./styles.module.scss";
import ReloadIcon from "../../icons/ReloadIcon";
import { Table, Pagination, Select, Button, Group } from "@mantine/core";
import Paginations from "../../components/pagination/Pagination";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import { getExportPayments, getPayments } from "../../api/paymentsApi";
import Skeleton from "../../components/Skeleton/Skeleton";
import { useQueryClient } from "@tanstack/react-query";
import StatusBadge from "./components/StatusBadge";
import ArrowLink from "../../icons/ArrowLink";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import QestionIcon from "../../icons/QestionIcon";
import ExcelButton from "../../components/ExcelButton/ExcelButton";
import { getPayemntsData } from "../../api/getExcel";
import { IconChevronLeftPipe, IconChevronRightPipe } from "@tabler/icons-react";
import { getCorrectCardType } from "../../utils/getCorrectCardType";
import DatePicker from "../../components/DatePicker/DatePickerInput";
import FiltersButton from "../../components/FiltersButton/FiltersButton";
import { getExchangeRate } from "../../api/getAccounts";
import { getCardsOptions } from "../../api/cardsApi";
import dayjs from "dayjs";
import { handleExcelDownload } from "../../utils/excelDownload";
import { ButtonBase } from "@mui/material";
import CardNumber from "../../components/CardNumber/CardNumber";
import { PAGE_ROUTES } from "../constants";

const Payments = () => {
  const [pagination, setPagination] = useState({ pageIndex: 0 });
  const pageSize = 15;
  const location = useLocation();
  const [isActiveFilters, setIsActiveFilters] = useState(false);
  const [accountName, setAccountName] = useState();
  const [cardName, setCardName] = useState();
  const [dateValue, setDateValue] = useState([null, null]);
  const [accountsFiltersData, setAccountsFilterData] = useState();
  const [cardsFiltersData, setCardsFiltersData] = useState();
  const [filters, setFilters] = useState({
    cardId: null,
    dateFrom: null,
    dateTo: null,
    type: null,
    iban: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const today = dayjs().startOf("day");

    if (searchParams.get("spend-for-day")) {
      setFilters((prev) => ({
        ...prev,
        dateFrom: today.toDate(),
        dateTo: today.toDate(),
      }));
      setDateValue([today.toDate(), today.toDate()]);
    } else if (searchParams.get("spend-for-month")) {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");

      setFilters((prev) => ({
        ...prev,
        dateFrom: startOfMonth.toDate(),
        dateTo: endOfMonth.toDate(),
      }));

      setDateValue([startOfMonth.toDate(), endOfMonth.toDate()]);
    }

    const iban = searchParams.get("iban");

    if (iban) {
      setFilters((prev) => ({
        ...prev,
        iban: iban,
      }));
    }
    setTimeout(() => {
      handleUpdateAData();
    }, 1000);
  }, [location]);

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    setPagination({ pageIndex: 0 });
  };

  const handleClearFilters = () => {
    setAccountName(null);
    setCardName(null);
    setDateValue([null, null]);

    setFilters({
      iban: null,
      dateFrom: null,
      dateTo: null,
      type: null,
      refillMethod: null,
      sourceId: null,
      cardName: null,
    });

    setTimeout(() => {
      handleUpdateAData();
    }, 0);
  };

  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["paymentsData", pagination.pageIndex, pageSize],
    queryFn: () =>
      getPayments({
        params: filters,
        page: pagination.pageIndex + 1,
        perPage: pageSize,
      }),
  });

  const handleUpdateAData = () => {
    queryClient.invalidateQueries(["paymentsData"]);
  };

  useEffect(() => {
    try {
      const fetchAccountsFiltersData = async () => {
        const response = await getExchangeRate();
        setAccountsFilterData(response?.data.data.accounts);
      };
      const fetchCardsFiltersData = async () => {
        const response = await getCardsOptions();
        setCardsFiltersData(response.cards);
      };
      fetchCardsFiltersData();
      fetchAccountsFiltersData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handlePageChange = useCallback((page) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page - 1,
    }));
  }, []);

  const tabs = [{ title: "All payments" }];
  return (
    <div className={styles.paymentsContainer}>
      <h1>Payments</h1>

      <Paginations tabs={tabs} active={0} setActive={0} />

      <div className={styles.paymentsTableContainer}>
        <div className={styles.paymentsTableHeader}>
          <div className={styles.paymentsTableHeaderLeft}>
            <h3>ALL PAYMENTS</h3>
            <FiltersButton
              isActive={isActiveFilters}
              onClick={() =>
                isLoading || isFetching
                  ? ""
                  : setIsActiveFilters((prev) => !prev)
              }
            />
            <ReloadIcon
              onClick={handleUpdateAData}
              style={{
                animation: isFetching ? "spin 1s linear infinite" : "none",
              }}
            />
          </div>
          <ExcelButton
            onSubmit={() =>
              handleExcelDownload({
                fn: () => getExportPayments({ params: filters }),
                fileName: "cards",
              })
            }
          />
        </div>

        {isActiveFilters && (
          <div className={styles.filters}>
            <Group
              spacing="sm"
              mb="md"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                minWidth: "100%",
              }}
            >
              <div
                className={styles.datePickerInput}
                style={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <DatePicker
                  setFilters={setFilters}
                  setDateValue={setDateValue}
                  dateValue={dateValue}
                />
              </div>

              <Select
                label="Payments of accounts:"
                placeholder="Search by account"
                value={accountName}
                onChange={(value) => {
                  setAccountName(value);
                  handleFilterChange("iban", value);
                }}
                data={accountsFiltersData?.map((item) => ({
                  value: item.iban,
                  label: item.name,
                }))}
                styles={{
                  label: {
                    marginBottom: 8,
                  },
                  input: {
                    "&:focus-within": {
                      borderColor: "rgb(33, 160, 56)",
                      borderWidth: "1px",
                    },
                  },
                  item: {
                    "&[data-hovered]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                      borderRadius: "4px",
                    },
                    "&[data-selected]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                    },
                  },
                }}
                className={styles.inputWidths}
              />

              <Select
                label="Payments of card:"
                value={cardName}
                onChange={(value) => {
                  setCardName(value);

                  handleFilterChange("cardId", value);
                }}
                placeholder="Search by card"
                data={cardsFiltersData?.map((item) => ({
                  value: item.cardId,
                  label: item.name,
                }))}
                styles={{
                  label: {
                    marginBottom: 8,
                  },
                  input: {
                    "&:focus-within": {
                      borderColor: "rgb(33, 160, 56)",
                      borderWidth: "1px",
                    },
                  },
                  item: {
                    "&[data-hovered]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                      borderRadius: "4px",
                    },
                    "&[data-selected]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                    },
                  },
                }}
                className={styles.inputWidths}
              />
              <Select
                label="Transaction Type:"
                value={filters?.type}
                onChange={(value) => handleFilterChange("type", value)}
                placeholder="Select refill method"
                data={[
                  { value: "Debit", label: "Debit" },
                  { value: "Credit", label: "Credit" },
                ]}
                className={styles.inputWidths}
                styles={{
                  label: {
                    marginBottom: 8,
                  },
                  input: {
                    "&:focus-within": {
                      borderColor: "rgb(33, 160, 56)",
                      borderWidth: "1px",
                    },
                  },
                  item: {
                    "&[data-hovered]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                      borderRadius: "4px",
                    },
                    "&[data-selected]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                    },
                  },
                }}
              />
            </Group>
            <div className={styles.filterButtons}>
              <button
                className={styles.applyFiltersButton}
                onClick={handleUpdateAData}
              >
                Apply filters
              </button>
              <button
                className={styles.clearFiltersButton}
                onClick={handleClearFilters}
              >
                Clear filters
              </button>
            </div>
          </div>
        )}

        <div className={styles.paymentsTable}>
          {isLoading ? (
            <Skeleton className={styles.skeletonTable} isSpinner={true} />
          ) : data?.data.length ? (
            <Table
              striped
              highlightOnHover
              sx={{
                minWidth: "800px",
                tableLayout: "auto",
                overflow: "auto",
              }}
              onPaginationChange={(updater) => {
                setPagination((prev) => {
                  const newState =
                    typeof updater === "function" ? updater(prev) : updater;
                  return newState;
                });
              }}
              state={{
                pagination: {
                  pageIndex: pagination.pageIndex,
                  pageSize: pageSize,
                },
              }}
              pageCount={
                data?.meta?.last_page ??
                Math.ceil((data?.meta?.total ?? 0) / pageSize)
              }
              enablePagination={false}
            >
              <thead className={styles.paymentsHead}>
                <tr>
                  <th style={{ color: "#AAAAAA" }}>DOCUMENT</th>
                  <th style={{ color: "#AAAAAA" }}>DATE</th>
                  <th style={{ color: "#AAAAAA" }}>CARD NUMBER</th>
                  <th style={{ color: "#AAAAAA" }}>CARD NAME</th>
                  <th style={{ color: "#AAAAAA" }}>STATUS</th>
                  <th style={{ color: "#AAAAAA" }}>TRANSACTION AMOUNT</th>
                  <th style={{ color: "#AAAAAA" }}>ACCOUNT AMOUNT</th>
                  <th style={{ color: "#AAAAAA" }}>PAYMENT DETAILS</th>
                  <th style={{ color: "#AAAAAA" }}>BALANCE</th>
                  <th style={{ color: "#AAAAAA" }}>ACCOUNT #</th>
                </tr>
              </thead>
              <tbody className={styles.paymentsBody}>
                {data?.data?.map((item, index) => {
                  const cardStateCode = item.stateCode === "50";
                  const cardStatusName =
                    item.statusName === "AWAITING ACTIVATION";

                  const handleNavigateToCard = () => {
                    if (cardStateCode || cardStatusName) return null;
                    navigate(
                      `${PAGE_ROUTES.CARD_DETAILES.replace(":id", item.cardId)}`
                    );
                  };

                  console.log("item", item);
                  return (
                    <tr key={index}>
                      <td>{item.ID}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {new Date(item.dateTime).toLocaleDateString()}{" "}
                        {new Date(item.dateTime).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>

                      <td className={styles.paymentsBodyCardNumber}>
                        <span className={styles.paymentsBodyCardNumberIcon}>
                          {getCorrectCardType(item.cardType)}
                        </span>

                        <Link style={{ display: "flex" }}>
                          <span className={styles.paymentsBodyCardNumberSpan}>
                            <CardNumber
                              cardNum={item.cardNumber}
                              cardStateCode={cardStateCode}
                              cardStatusName={cardStatusName}
                              onClick={handleNavigateToCard}
                            />
                          </span>{" "}
                          <CustomTooltip
                            placement="bottom-start"
                            title="Click to see card details"
                            arrow
                          >
                            <span className={styles.paymentsBodyQuestionSpan}>
                              <QestionIcon />
                            </span>
                          </CustomTooltip>
                        </Link>
                      </td>
                      <td>
                        <span className={styles.paymentsBodyCardNameSpan}>
                          {item.cardName}
                        </span>
                      </td>
                      <td>
                        <StatusBadge status={item.status} />
                      </td>
                      <td
                        style={{
                          color: item.type === "Credit" ? "#21A038" : "black",
                        }}
                      >
                        {item.type === "Credit" ? "+" : "-"}{" "}
                        {item.merchantAmount} {item.accountCurrency}
                      </td>
                      <td
                        style={{
                          color: item.type === "Credit" ? "#21A038" : "black",
                        }}
                      >
                        {item.type === "Credit" ? "+" : "-"}
                        {item.accountAmount} {item.merchantCurrency}
                      </td>
                      <td>{item.details}</td>
                      <td>€{item.balance}</td>
                      <td
                        className="secondaryText"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <span className={styles.paymentsBodyAccountSpan}>
                          #{item.accountIban.slice(-6)}
                        </span>{" "}
                        {item.accountName}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            "No data"
          )}
        </div>
        <Pagination
          value={pagination.pageIndex + 1}
          onChange={handlePageChange}
          total={
            data?.meta?.last_page ??
            Math.ceil((data?.meta?.total ?? 0) / pageSize)
          }
          position="right"
          withEdges
          icons={{
            first: <IconChevronLeftPipe size={16} />,
            last: <IconChevronRightPipe size={16} />,
          }}
        />
      </div>
    </div>
  );
};

export default Payments;
