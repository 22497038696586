import React from "react";

const CardsMobile = ({ fill = "#2F3035" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.02467 16.5C5.61045 16.5 5.27477 16.1642 5.2749 15.75C5.27503 15.3358 5.61092 15 6.02513 15H7.52513C7.93935 15 8.27505 15.3358 8.2749 15.75C8.27475 16.1642 7.9389 16.5 7.52467 16.5H6.02467Z"
        fill={fill}
      />
      <path
        d="M9.0249 15.75C9.02475 16.1642 9.36045 16.5 9.77468 16.5H11.2747C11.6889 16.5 12.0248 16.1642 12.0249 15.75C12.0251 15.3358 11.6894 15 11.2751 15H9.77513C9.3609 15 9.02505 15.3358 9.0249 15.75Z"
        fill={fill}
      />
      <path
        d="M13.5247 16.5C13.1105 16.5 12.7748 16.1642 12.7749 15.75C12.7751 15.3358 13.1109 15 13.5251 15H15.0251C15.4394 15 15.7751 15.3358 15.7749 15.75C15.7748 16.1642 15.4389 16.5 15.0247 16.5H13.5247Z"
        fill={fill}
      />
      <path
        d="M16.5249 15.75C16.5248 16.1642 16.8605 16.5 17.2747 16.5H18.7747C19.1889 16.5 19.5248 16.1642 19.5249 15.75C19.5251 15.3358 19.1894 15 18.7751 15H17.2751C16.8609 15 16.5251 15.3358 16.5249 15.75Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7749 6.75C14.7394 6.75 13.8999 7.58948 13.8999 8.625V9.375C13.8999 10.4105 14.7394 11.25 15.7749 11.25H18.0249C19.0604 11.25 19.8999 10.4105 19.8999 9.375V8.625C19.8999 7.58948 19.0604 6.75 18.0249 6.75H15.7749ZM15.3999 8.625C15.3999 8.41793 15.5678 8.25 15.7749 8.25H18.0249C18.232 8.25 18.3999 8.41793 18.3999 8.625V9.375C18.3999 9.58208 18.232 9.75 18.0249 9.75H15.7749C15.5678 9.75 15.3999 9.58208 15.3999 9.375V8.625Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.0249 3.75C3.74672 3.75 1.8999 5.59682 1.8999 7.875V16.125C1.8999 18.4032 3.74672 20.25 6.0249 20.25H18.7749C21.0531 20.25 22.8999 18.4032 22.8999 16.125V7.875C22.8999 5.59682 21.0531 3.75 18.7749 3.75H6.0249ZM3.3999 7.875C3.3999 6.42525 4.57515 5.25 6.0249 5.25H18.7749C20.2247 5.25 21.3999 6.42525 21.3999 7.875V16.125C21.3999 17.5747 20.2247 18.75 18.7749 18.75H6.0249C4.57515 18.75 3.3999 17.5747 3.3999 16.125V7.875Z"
        fill={fill}
      />
    </svg>
  );
};

export default CardsMobile;
