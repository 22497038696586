import { Link, Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "./MainLayout.module.scss";
import FooterMainLayout from "../../components/footer/FooterMainLayout/FooterMainLayout";
import { ActionNotification } from "../Notification/ActionNotification";
import { Notifications } from "../Notifications/Notifications";
import logo from "../../icons/Logo.svg";
import profileIcon from "../../icons/mobile/Profile.svg";
import { PAGE_ROUTES } from "../../pages/constants";

const MainLayout = () => {
  return (
    <div className={styles.mainLayout}>
      <div className={styles.wrapperMainLayout}>
        <Sidebar />

        <div className={styles.childrenLayout}>
          <div className={styles.headerMobile}>
            <div className={styles.containerHeaderMobile}>
              <Link to={PAGE_ROUTES.DASHBOARD}>
                <img src={logo} alt="logo" style={{ width: "fitContent" }} />
              </Link>

              <Link to={PAGE_ROUTES.PROFILE}>
                <img src={profileIcon} alt="" />
              </Link>
            </div>
          </div>

          <div className={styles.containerLayout}>
            <ActionNotification position="top-right" autoClose={2000} />
            <Outlet />
            <Notifications />
            <FooterMainLayout />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
