import React, { useState } from "react";
import { Input } from "../../../components/Inputs/Input";
import {
  displayLimits,
  NOTIFICATION_INFO,
  REG_EXP_ONLY_NUMBERS,
} from "../../../constants/constants";
import styles from "./styles.module.scss";
import { useForm } from "react-hook-form";
import { changeCard } from "../../../api/cardsApi";
import { showToast } from "../../Notification/ActionNotification";
import Modal from "../Modal";
import { Button } from "../../../components/Button/Button";
import { useClientInvalidateData } from "../../../hooks/useClientInvalidateData";

const SetLimitsCard = ({
  data,
  type = "cardDetailsChange", //card details
  setActiveModal,
  cardId,
  selectedRows,
  keyToInvalidate = "",
}) => {
  const { control, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const { handleInvalidateData } = useClientInvalidateData({
    queryKey: keyToInvalidate,
  });
  const limitsMap =
    type === "cardDetailsChange" &&
    data.limits.reduce((acc, limit) => {
      acc[limit.name] = limit;
      return acc;
    }, {});

  const limits =
    type === "cardDetailsChange" &&
    displayLimits.map(({ label, name }) => {
      const limit = limitsMap[name];
      return limit.value;
    });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (type === "cardDetailsChange") {
        await onSubmitCardLimits(data);
      } else {
        await onSubmitCardLimitsMoreThanOne(data);
      }
      handleInvalidateData();
      setIsLoading(false);
      setActiveModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmitCardLimitsMoreThanOne = async (formData) => {
    if (!selectedRows || selectedRows.length === 0) {
      showToast("error", "No rows selected to update limits.");
      return;
    }

    try {
      for (const row of selectedRows) {
        const updatedLimits = [
          {
            name: "LMTTZ54", // Limit for 1 day
            value: parseFloat(formData.limitOneDay),
          },
          {
            name: "LMTTZ56", // Limit for 7 days
            value: parseFloat(formData.limitSevenDays),
          },
          {
            name: "LMTTZ55", // Limit for 30 days
            value: parseFloat(formData.limit30Days),
          },
        ];

        const payload = {
          limits: updatedLimits,
        };

        await changeCard(row.cardId, payload);
        setActiveModal(false);

        showToast("success", `Limits updated successfully for ${row.name}`);
      }
    } catch (error) {
      console.error("Error updating limits:", error);
      showToast(
        "error",
        error.response.data.data.message ||
          "Failed to update limits for selected rows."
      );
    }
  };

  const onSubmitCardLimits = async (data) => {
    console.log("da123ta", data);
    try {
      const updatedLimits = [
        {
          name: "LMTTZ54",
          // description: "Limit 7 days",
          value: parseFloat(data.limitOneDay),
        },
        {
          name: "LMTTZ56",
          // description: "Limit 7 days",
          value: parseFloat(data.limitSevenDays),
        },
        {
          name: "LMTTZ55",
          // description: "Limit 7 days",
          value: parseFloat(data.limit30Days),
        },
      ];
      const payload = {
        limits: updatedLimits,
      };

      await changeCard(cardId, payload);
      showToast("success", NOTIFICATION_INFO.CHANGE_CARD_LIMITS);
      setActiveModal(null);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal>
      <div className={styles.cardLimitTitle}>
        <p className="H1">Set Card Limits</p>
        <span className="b1" style={{ fontSize: "14px" }}>
          Card will not be able to spend more than the limit you set.
        </span>
        <form
          id="modal-form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", gap: "18px", flexDirection: "column" }}
        >
          <div className={styles.containerInputsLimitsModal}>
            <Input
              maxWidth="436px"
              name="limitOneDay"
              startAdornment="€"
              placeholder={
                type === "cardDetailsChange" ? limits[0] : "Write your limit"
              }
              control={control}
              label="Limit for 1 day"
              rules={{
                pattern: {
                  value: REG_EXP_ONLY_NUMBERS,
                  message: "Only numbers are allowed",
                },
                required: "This field is required",
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                  padding: "0 3px",
                },
              }}
            />
            <Input
              maxWidth="436px"
              name="limitSevenDays"
              startAdornment="€"
              placeholder={
                type === "cardDetailsChange" ? limits[1] : "Write your limit"
              }
              control={control}
              label="Limit for 7 days"
              rules={{
                pattern: {
                  value: REG_EXP_ONLY_NUMBERS,
                  message: "Only numbers are allowed",
                },
                required: "This field is required",
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                  padding: "0 3px",
                },
              }}
            />
            <Input
              maxWidth="436px"
              name="limit30Days"
              startAdornment="€"
              control={control}
              placeholder={
                type === "cardDetailsChange" ? limits[2] : "Write your limit"
              }
              label="Limit for 30 days"
              rules={{
                pattern: {
                  value: REG_EXP_ONLY_NUMBERS,
                  message: "Only numbers are allowed",
                },
                required: "This field is required",
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                  padding: "0 3px",
                },
              }}
            />
          </div>

          <div className={styles.containerBtns}>
            <Button
              type="submit"
              className={styles.applyBtn}
              isLoading={isLoading}
            >
              Apply
            </Button>
            <Button
              type="button"
              className={styles.closeBtn}
              onClick={() => setActiveModal(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SetLimitsCard;
