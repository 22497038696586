import React from "react";

const ArrowLink = ({ fill = "", arrow, style }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#21A038" />
      <path
        d="M8.88015 7.33103C8.51327 7.33103 8.21585 7.03361 8.21585 6.66674C8.21584 6.29986 8.51325 6.00245 8.88013 6.00246L15.6271 6.00246C16.9374 6.00247 17.9995 7.06465 17.9995 8.37495L17.9995 15.1219C17.9995 15.4888 17.7021 15.7862 17.3353 15.7862C16.9684 15.7862 16.671 15.4888 16.671 15.1219L16.671 8.37495C16.6709 8.34129 16.6694 8.30806 16.6663 8.27521L7.63871 17.3028C7.37929 17.5622 6.95865 17.5622 6.69925 17.3028C6.43985 17.0434 6.43983 16.6227 6.69925 16.3633L15.7268 7.33575C15.694 7.33263 15.6607 7.33108 15.6271 7.33102L8.88015 7.33103Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowLink;
