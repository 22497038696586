import React, { useState } from "react";
import styles from "./styles.module.scss";
import ReloadIcon from "../../icons/ReloadIcon";
import ThreeDSTable from "../Tables/TableMetapayAnd3Ds/ThreeDSTable";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import FiltersButton from "../../components/FiltersButton/FiltersButton";

const ThreeDS = () => {
  const query = useQueryClient();
  const isFetching = useIsFetching(["threeDSCodes"]);
  // const [isFiltersShow, setIsFiltersShow] = useState(false);

  const handleRefetch = () => {
    query.invalidateQueries(["threeDSCodes"], { exact: true });
  };

  return (
    <div>
      <div className={styles.containerTitle}>
        <div className={styles.containerSpinner}>
          <h2 className="H3">3DS OTP</h2>
          <ReloadIcon
            className={styles.reloadIcon}
            onClick={handleRefetch}
            style={{
              animation: isFetching ? "spin 1s linear infinite" : "none",
            }}
          />
        </div>

        {/* <FiltersButton
          isActive={isFiltersShow}
          onClick={() => setIsFiltersShow((prev) => !prev)}
        /> */}
      </div>

      <ThreeDSTable />
    </div>
  );
};

export default ThreeDS;
