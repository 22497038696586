export const shouldShowActionButton = (currentUser, targetUser) => {
  const isSelf = currentUser.id === targetUser.id;
  const isOwner = currentUser.role === "Owner";
  const isTeamLead = currentUser.role === "Team Lead";

  if (isSelf) return false;
  if (isOwner) return true;
  if (isTeamLead) return targetUser.role === "Member";
  return false;
};

export const shouldEnableAddAccountButton = (currentUser, targetUser) => {
  const isSelf = currentUser.id === targetUser.id;
  const isOwner = currentUser.role === "Owner";
  const isTeamLead = currentUser.role === "Team Lead";
  const isTargetOwner = targetUser.role === "Owner";
  const isTargetMember = targetUser.role === "Member";

  if (isSelf) return false;

  if (isOwner) {
    return !isTargetOwner;
  }

  if (isTeamLead) {
    return isTargetMember;
  }

  return true;
};

export const shouldShowRemoveButton = (currentUser, targetUser) => {
  const isSelf = currentUser.id === targetUser.id;
  const isOwner = currentUser.role === "Owner";
  const isTeamLead = currentUser.role === "Team Lead";

  if (isSelf) return false;
  if (isOwner) return true;
  if (isTeamLead) return targetUser.role === "Member";
  return false;
};
