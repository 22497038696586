import React from "react";

const ArrowRightTop = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.834961" width="16" height="16" rx="8" fill="white" />
      <path
        d="M6.75539 4.88703C6.5108 4.88703 6.31252 4.68875 6.31252 4.44416C6.31252 4.19958 6.51079 4.00131 6.75537 4.00131L11.2533 4.00132C12.1269 4.00132 12.835 4.70944 12.835 5.58298V10.0809C12.835 10.3255 12.6367 10.5238 12.3921 10.5238C12.1476 10.5238 11.9493 10.3255 11.9493 10.0809V5.58298C11.9492 5.56054 11.9482 5.53838 11.9461 5.51648L5.92776 11.5349C5.75481 11.7078 5.47439 11.7078 5.30145 11.5349C5.12852 11.3619 5.1285 11.0815 5.30145 10.9085L11.3198 4.89017C11.2979 4.8881 11.2758 4.88706 11.2533 4.88702L6.75539 4.88703Z"
        fill="#21A038"
      />
    </svg>
  );
};

export default ArrowRightTop;
