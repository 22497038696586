import React from "react";

const Erc = () => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.29838 0L6.17773 0.410072V12.3094L6.29838 12.4298L11.8219 9.16489L6.29838 0Z"
        fill="#343434"
      />
      <path
        d="M6.29893 0L0.775391 9.16489L6.29893 12.4299V6.6543V0Z"
        fill="#8C8C8C"
      />
      <path
        d="M6.29845 13.4757L6.23047 13.5586V17.7974L6.29845 17.996L11.8253 10.2124L6.29845 13.4757Z"
        fill="#3C3C3B"
      />
      <path
        d="M6.29893 17.996V13.4757L0.775391 10.2124L6.29893 17.996Z"
        fill="#8C8C8C"
      />
      <path
        d="M6.30078 12.4299L11.8242 9.16501L6.30078 6.65442V12.4299Z"
        fill="#141414"
      />
      <path
        d="M0.775391 9.16501L6.29884 12.4299V6.65442L0.775391 9.16501Z"
        fill="#393939"
      />
    </svg>
  );
};

export default Erc;
