import { styled, Tooltip, tooltipClasses } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    style={{ cursor: "pointer" }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2F3035",
    color: "#FFFFFF",
    fontSize: "12px",
    padding: "16px",
    arrow: true,
    marginLeft: "-30px",
    borderRadius: "8px",
    zIndex: "10111111",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#2F3035",
    marginLeft: "15px",
  },
});

export default CustomTooltip;
