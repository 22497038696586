import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import { getAllCards } from "../../../api/cardsApi";
import Skeleton from "../../../components/Skeleton/Skeleton";
import styles from "./styles.module.scss";
import { Box } from "@mantine/core";
import Table from "../../../components/Table/Table";
import ColumnsCards from "../../../constants/columns/ColumnsCards";
import HeaderTable from "./HeaderTable";
import { ModalsInteractive } from "../../Modals/TeamManagementModals/ModalsInteractive";
import useCardsHandles from "../../../hooks/handles/useCardsHandles";
import IssueCardModal from "../../Modals/IssueCardModal/IssueCardModal";
import SetLimitsCard from "../../Modals/SetLimitsCard/SetLimitsCard";
import { optionsTable } from "../../../constants/constants";

const CardTable = ({ filters }) => {
  const pageSize = 15;

  const [isOpenCardIssue, setIsOpenCardIssue] = useState(false);
  const [isSetLimitsOpen, setIsSetLimitsOpen] = useState(false);

  const [activeModalInveractive, setActiveModalInveractive] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalDataRow, setModalDataRow] = useState(null);

  const queryClient = useQueryClient();
  const [dataTable, setDataTable] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const {
    data: cardsData,
    isLoading,
    isFetching,
  } = useAuthQuery({
    queryKey: ["cardsTableData", pagination.pageIndex, pageSize],
    queryFn: () =>
      getAllCards({
        params: filters,
        page: pagination.pageIndex + 1,
        perPage: pageSize,
      }),
    keepPreviousData: true,
    staleTime: 2 * 60 * 1000,
    cacheTime: 2 * 60 * 1000,
    onSuccess: (data) => {
      if (data.meta.current_page < data.meta.last_page) {
        queryClient.prefetchQuery({
          queryKey: ["cardsTableData", pagination.pageIndex + 2, pageSize],
          queryFn: () =>
            getAllCards({
              page: pagination.pageIndex + 2,
              perPage: pageSize,
            }),
        });
      }
    },
  });

  useEffect(() => {
    if (cardsData?.data && !isLoading && !isFetching) {
      setDataTable(cardsData.data);
    }
  }, [cardsData, isLoading, isFetching]);

  const {
    handleSubmit,
    handleOpenModalRemoveUser,
    handleOpenModalsInteractive,
  } = useCardsHandles({
    setActiveModalInveractive,
    setSelectedRows,
    setModalDataRow,
    selectedRows,
  });

  const handleOpenCardIssue = useCallback(() => setIsOpenCardIssue(true), []);

  if (isLoading)
    return <Skeleton className={styles.skeletonTable} isSpinner={true} />;

  return (
    <>
      {isSetLimitsOpen && (
        <SetLimitsCard
          setActiveModal={setIsSetLimitsOpen}
          selectedRows={selectedRows}
          type="coupeOfCardsLimits"
        />
      )}
      <Box
        style={{
          borderRadius: "8px",
          overflow: "hidden",
          background: "#fff",
        }}
      >
        <HeaderTable
          handleOpenModalsInteractive={handleOpenModalsInteractive}
          selectedRows={selectedRows}
          handleOpenCardIssue={handleOpenCardIssue}
          setIsSetLimitsOpen={setIsSetLimitsOpen}
        />

        <Table
          columns={ColumnsCards({
            selectedRows,
            setSelectedRows,
            handleOpenModalRemoveUser,
            dataTable,
          })}
          data={dataTable}
          enablePagination={true}
          manualPagination={true}
          onPaginationChange={(updater) => {
            setPagination((prev) => {
              const newState =
                typeof updater === "function" ? updater(prev) : updater;
              return newState;
            });
          }}
          state={{
            pagination: {
              pageIndex: pagination.pageIndex,
              pageSize: pageSize,
            },
          }}
          manualFiltering={true}
          enableColumnActions={false}
          rowCount={cardsData?.meta?.total ?? 0}
          pageCount={
            cardsData?.meta?.last_page ??
            Math.ceil((cardsData?.meta?.total ?? 0) / pageSize)
          }
          enableColumnFilters={true}
          enableGlobalFilter={false}
          enableHiding={true}
          enableDensityToggle={false}
          paginationDisplayMode={"pages"}
          enableTopToolbar={false}
          mantinePaginationProps={{
            withEdges: true,
            showRowsPerPage: false,
          }}
          mantineTableProps={{
            ...optionsTable,
          }}
        />

        <ModalsInteractive
          activeModal={activeModalInveractive}
          modalData={modalDataRow || selectedRows}
          // modalData={selectedRows}
          onSubmit={handleSubmit}
          onCancel={() => {
            setActiveModalInveractive(null);
            setModalDataRow(null);
          }}
        />

        {isOpenCardIssue && (
          <IssueCardModal setIssueCardModal={setIsOpenCardIssue} />
        )}
      </Box>
    </>
  );
};

export default React.memo(CardTable);
