import React from "react";

const PauseIcon = ({ className, style, onClick }) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        className={className}
        onClick={onClick}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="3.2" fill="#D5E8FF" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.8004 9.6001C11.0272 9.6001 10.4004 10.2269 10.4004 11.0001V21.0001C10.4004 21.7733 11.0272 22.4001 11.8004 22.4001H13.8004C14.5736 22.4001 15.2004 21.7733 15.2004 21.0001V11.0001C15.2004 10.2269 14.5736 9.6001 13.8004 9.6001H11.8004ZM11.6004 11.0001C11.6004 10.8896 11.6899 10.8001 11.8004 10.8001H13.8004C13.9108 10.8001 14.0004 10.8896 14.0004 11.0001V21.0001C14.0004 21.1106 13.9108 21.2001 13.8004 21.2001H11.8004C11.6899 21.2001 11.6004 21.1106 11.6004 21.0001V11.0001Z"
          fill="#227ECF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.2008 9.6001C17.4276 9.6001 16.8008 10.2269 16.8008 11.0001V21.0001C16.8008 21.7733 17.4276 22.4001 18.2008 22.4001H20.2008C20.974 22.4001 21.6008 21.7733 21.6008 21.0001V11.0001C21.6008 10.2269 20.974 9.6001 20.2008 9.6001H18.2008ZM18.0008 11.0001C18.0008 10.8896 18.0903 10.8001 18.2008 10.8001H20.2008C20.3113 10.8001 20.4008 10.8896 20.4008 11.0001V21.0001C20.4008 21.1106 20.3113 21.2001 20.2008 21.2001H18.2008C18.0903 21.2001 18.0008 21.1106 18.0008 21.0001V11.0001Z"
          fill="#227ECF"
        />
      </svg>
    </>
  );
};

export default PauseIcon;
