import React from "react";
import styles from "./styles.module.scss";

const FooterMainLayout = () => {
  return (
    <div className={styles.containerFooter}>
      <div className={styles.contentFooter}>
        <p>
          Copyright © 2024 — 2025{" "}
          <span className={styles.textFirmName}>[FBM]PAY</span>. All rights
          reserved
        </p>
      </div>
    </div>
  );
};

export default FooterMainLayout;
