import { Link } from "react-router-dom";
import style from "./styles.module.scss";
import { HIGHLIGH_POSITION_LAST } from "../../constants/constants";
import React from "react";
import clsx from "clsx";

const ItemBlockInfo = ({
  title,
  titleColored,
  additContent,
  linkTitle,
  href,
  highlightPosition,
  disabledLinks,
}) => {
  const titleItem = highlightPosition ? (
    highlightPosition === HIGHLIGH_POSITION_LAST ? (
      <div className={style.titles}>
        <span className={clsx(style.titleNormal, "H3")}>{title}</span>{" "}
        <span className={clsx(style.titleColored, "H3")}>{titleColored}</span>
      </div>
    ) : (
      <div className={style.titles}>
        <span className={clsx(style.titleColored, "H3")}>{titleColored}</span>{" "}
        <span className={clsx(style.titleNormal, "H3")}>{title}</span>
      </div>
    )
  ) : (
    title
  );

  return (
    <div className={style.containerItemBlock}>
      <div className={style.containerContentItemBlock}>
        <span className="H3">{titleItem}</span>
        <div className={style.containerAdditional}>
          {additContent}{" "}
          <Link
            className={clsx(
              style.linkTitle,
              "buttons",
              disabledLinks && style.disableLink
            )}
            to={href}
          >
            {linkTitle}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ItemBlockInfo);
