import React, { useEffect, useState } from "react";
import styles from "./TopUpModal.module.scss";
import { StyledTextField } from "../../../components/Inputs/Input";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  confirmFundAccount,
  fundAccount,
  getAccounts,
  getExchangeRate,
} from "../../../api/getAccounts";
import SuccessIcon from "../../../icons/SuccessIcon";
import { AccountForm } from "./AccountsForm";
import ArrowRight from "../../../icons/ArrowRight";
import QestionIcon from "../../../icons/QestionIcon";
import ShowMoreIcon from "../../../icons/ShowMoreIcon";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import EqualsIcon from "../../../icons/equalsIcon";
import { showToast } from "../../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import CopyIcon from "../../../icons/CopyIcon";
import Teather from "../../../icons/Teather";
import Erc from "../../../icons/Erc";
import QuestionIcons from "../../../icons/QuestionIcons";
import TrcIcon from "../../../icons/networks/TrcIcon";
import ErcIcon from "../../../icons/networks/ErcIcon";
import AddIcon from "../../../icons/AddIcon";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { validateTxnHash } from "../../../utils/validateHashTransaction";

const perPage = 120;
const WalletTopUpModal = ({ setTopUpModal, onClose, user }) => {
  const { data, isLoading } = useAuthQuery({
    queryKey: ["topUpData", perPage],
    queryFn: () =>
      getAccounts({
        perPage: perPage,
      }),
  });

  const accounts = data?.data;

  const [step, setStep] = useState(1);
  const [formCount, setFormCount] = useState([1]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [accountsArray, setAccountsArray] = useState([
    { iban: "", amount: "", id: "" },
  ]);

  const [summValue, setSummValue] = useState();
  const [showMore, setShowMore] = useState(false);
  const [transferData, setTransferData] = useState("");
  const [txnHash, setTxnHash] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [walletValue, setWalletValue] = useState();

  // Хранит все выбранные IBAN для того чтобы в созданных акках не повторялись счета
  const [selectedIbans, setSelectedIbans] = useState([null]);
  const [timeLeft, setTimeLeft] = useState(600);

  const [networkError, setNetworkError] = useState(false);
  const [errorHashMessage, setErrorHashMessage] = useState("");

  const handleNetworkChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue) {
      setNetworkError(false);
      setSelectedNetwork(selectedValue);
    } else {
      setNetworkError(true);
    }
  };

  useEffect(() => {
    if (step === 3) {
      if (timeLeft === 0) {
        setStep(4);
        return;
      }

      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerId);

            setStep(4);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [step, timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`;
  };

  if (isLoading) return null;

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const addAccountForm = () => {
    if (accounts.length === 10) {
      showToast("error", "Maximum number of accounts - 10");
      return;
    }
    setAccountsArray((prev) => [...prev, { iban: "", amount: "", id: "" }]);

    setFormCount((prevCount) => [...prevCount, prevCount.length + 1]);
  };

  const sumValues = (accounts) => {
    const total = accounts.reduce((total, account) => {
      return total + Number(account.amount);
    }, 0);
    setSummValue(total);
  };

  const handleInputChange = (event) => {
    const value = event.target.value.trim();
    setTxnHash(value);

    const validationMessage = validateTxnHash(value, selectedNetwork);
    setErrorHashMessage(validationMessage);
  };

  const removeAccount = (index) => {
    setAccountsArray((prevArray) =>
      prevArray.filter((_, idx) => idx !== index)
    );
    setFormCount((prevCount) => prevCount.filter((_, idx) => idx !== index));

    setSelectedIbans((prevIbans) =>
      prevIbans.filter((iban) => iban !== accountsArray[index]?.iban)
    );
  };

  const handleNextStep = () => {
    if (step === 1) {
      const totalSum = accountsArray.reduce(
        (sum, acc) => sum + Number(acc.amount),
        0
      );
      const hasEmptyFields = accountsArray.some(
        (acc) => !acc.amount || !acc.id
      );
      if (!accountsArray.length || hasEmptyFields) {
        showToast(
          "error",
          "Please fill in all account fields before proceeding"
        );
        return;
      }

      if (totalSum < 500) {
        showToast("error", "Total amount must be at least $500 to proceed");
        return;
      }

      sumValues(accountsArray);
      setStep(2);
    } else if (step === 2) {
      if (!selectedNetwork) {
        setNetworkError(true);
        showToast("error", "Please select a network before proceeding");
        return;
      }
      setStep(3);

      const body = {
        accounts: accountsArray,
        cryptoCurrency: selectedNetwork,
      };

      const fetchFundDetails = async (body) => {
        try {
          const result = await fundAccount(body);
          setTransferData(result.data.data);
          setWalletValue(result.data.data.walletAddress);
        } catch (error) {
          console.error("Error fetching fund details:", error);
          showToast("error", error.response.data.data.message);
          console.log(error.response.data.data.message);
          if (
            error.response.data.data.message ===
            "Summary amount must me more then 500$"
          ) {
            setStep(1);
          }
        }
      };

      fetchFundDetails(body);
      console.log(body);

      const fetchExchangeRate = async () => {
        const result = await getExchangeRate();
        setExchangeRate(result.data.data.currencyExchangeRate);
      };
      fetchExchangeRate();
    } else if (step === 3) {
      if (!txnHash || errorHashMessage) {
        showToast("error", "Please enter the transaction hash");
        return;
      }
      const id = transferData.id;
      const body = { transactionHash: txnHash };

      const fetchFundConfirm = async (id, body) => {
        try {
          const result = await confirmFundAccount(id, body);
          if (result.status === 204) {
            showToast("success", NOTIFICATION_INFO.TOPUP_SUCCESS);
            setTopUpModal(false);
          }
        } catch (error) {
          showToast("error", error.response.data.data.message);

          setTopUpModal(false);
        } finally {
        }
      };

      fetchFundConfirm(id, body);
    }
  };

  const handlePreviousStep = () => {
    if (step === 2) {
      sumValues(accountsArray);
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletValue);
    showToast("success", "Copied to clipboard!");
  };

  const isErrorHash = Boolean(errorHashMessage);

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h1>
          Top up balance
          {summValue ? ` for $${summValue.toFixed(2)}` : ""}
        </h1>
        <div className={styles.modalItemsGap}>
          <div className={styles.modalContainer}>
            <div className={styles.sideSteps}>
              <div className={styles.stepRound}>
                {step === 1 ? "1" : <SuccessIcon />}
              </div>
              <div className={styles.stepLine}></div>
            </div>
            <div className={styles.modalAccountsContainer}>
              <div
                className={`${styles.firstStep} ${styles.scrolledItem}`}
                style={{ height: step == 1 ? "" : "50px" }}
              >
                <div className={styles.stepTitle}>
                  <h2>Step 1</h2>
                  <p className="secondaryText" style={{ color: "#AAAAAA" }}>
                    Accounts and amount in $
                  </p>
                </div>
                <div
                  className={`${styles.firstStepAccounts} ${
                    step === 1 ? styles.activeStep : styles.displayNone
                  }`}
                >
                  <p
                    className="primaryText"
                    style={{ padding: "12px 0 32px 0", color: "#464646" }}
                  >
                    Select the accounts and the amount you want to top them up
                    with.
                  </p>
                  <div className={styles.accountItem}>
                    {formCount.map((_, idx) => (
                      <AccountForm
                        key={idx}
                        accounts={accounts}
                        setAccountsArray={setAccountsArray}
                        index={idx}
                        removeAccount={removeAccount}
                        user={user}
                        selectedIbans={selectedIbans}
                        setSelectedIbans={setSelectedIbans}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div
                className={`${styles.buttonsContainer} ${
                  step === 1 ? styles.activeStep : styles.displayNone
                }`}
                style={{ position: "relative" }}
              >
                <button onClick={handleNextStep} className={styles.continueBtn}>
                  Continue
                </button>
                {accountsArray.length < 10 && (
                  <button
                    onClick={addAccountForm}
                    className={styles.addAccountBtn}
                  >
                    <span>Add account</span> <AddIcon />
                  </button>
                )}
                <span className={styles.firstStepOutOff}>
                  {formCount.length} out of 10
                </span>
              </div>
            </div>
          </div>

          <div className={styles.modalContainer}>
            <div className={styles.sideSteps}>
              <div
                className={`${styles.stepRound} ${
                  step < 2 ? styles.stepInactive : ""
                }`}
              >
                {step < 2 ? "2" : step > 2 ? <SuccessIcon /> : "2"}
              </div>
              <div className={styles.stepLine}></div>
            </div>
            <div className={styles.singleStep}>
              <div
                className={`${styles.stepTitle} ${
                  step < 2 ? styles.textInactive : ""
                }`}
              >
                <h2>Step 2</h2>
                <p
                  className="secondaryText"
                  style={{ color: "#AAAAAA", marginBottom: "12px" }}
                >
                  Wallet network
                </p>
              </div>
              <div
                className={`${styles.step} ${
                  step === 2 ? styles.activeStep : ""
                }`}
              >
                <FormControl
                  fullWidth
                  sx={{
                    maxHeight: "40px",
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "40px",
                      color: (theme) => (selectedNetwork ? "black" : "grey"),
                      fontWeight: "500",
                      fontSize: "14px",
                    },
                  }}
                  error={networkError}
                >
                  <InputLabel
                    id="network-select-label"
                    shrink
                    sx={{
                      backgroundColor: "white",
                      padding: "0px",
                      fontFamily: "Noto Sans",
                      color: "#AAAAAA",
                      fontWeight: "700",
                    }}
                  >
                    Wallet network
                  </InputLabel>
                  <Select
                    labelId="network-select-label"
                    id="network-select"
                    value={selectedNetwork}
                    onChange={handleNetworkChange}
                    displayEmpty
                    sx={{
                      fontFamily: "Noto Sans",
                      fontWeight: "400 !important",
                    }}
                  >
                    <MenuItem
                      value=""
                      disabled
                      sx={{
                        color: "grey",
                      }}
                    >
                      Select wallet’s network
                    </MenuItem>
                    <MenuItem value={"USDTTRC20"}>
                      <div className={styles.topUpNetworkList}>
                        <p>USDT TRC20</p>
                        <Teather />
                      </div>
                    </MenuItem>
                    <MenuItem value={"USDT"}>
                      <div className={styles.topUpNetworkList}>
                        <p>USDT ERC20</p>
                        <Erc />
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
                <div className={styles.buttonsStepsContainer}>
                  <button
                    onClick={handleNextStep}
                    className={styles.continueBtn}
                  >
                    Continue
                  </button>
                  <button onClick={handlePreviousStep}>Back</button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.modalContainer}>
            <div className={styles.sideSteps}>
              <div
                className={`${styles.stepRound} ${
                  step < 3 ? styles.stepInactive : ""
                }`}
              >
                {step < 3 ? "3" : step > 3 ? <SuccessIcon /> : "3"}
              </div>
            </div>
            <div className={styles.singleStep}>
              <div
                className={`${styles.stepTitle} ${
                  step < 3 ? styles.textInactive : ""
                }`}
              >
                <h2>Step 3</h2>
                <p className="secondaryText" style={{ color: "#AAAAAA" }}>
                  Payment and confirmation
                </p>
              </div>
              <div
                className={`${styles.step} ${
                  step === 3 ? styles.activeStep : ""
                }`}
              >
                <div className={styles.thirdStepContainer}>
                  <p className="primaryText" style={{ color: "#464646" }}>
                    Transfer USDT to the specified wallet.{" "}
                    <span style={{ fontWeight: "700" }}>
                      Wallet network is{" "}
                      {selectedNetwork === "USDTTRC20" ? (
                        <>
                          TRC20 <TrcIcon />
                        </>
                      ) : (
                        <>
                          ERC20 <ErcIcon />
                        </>
                      )}
                    </span>
                  </p>
                  {timeLeft === 0 ? <div>time left 000</div> : ""}
                  <div className={styles.thirdStepTransferInformation}>
                    <div className={styles.thirdStepTransferHeader}>
                      <div className={styles.thirdStepContainerInput}>
                        <h1>${summValue ? summValue.toFixed(2) : ""}</h1>{" "}
                        <ArrowRight />
                        <StyledTextField
                          id={"amounts"}
                          label="Wallet address for transfer"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={transferData?.walletAddress}
                          sx={{
                            maxHeight: "40px",
                            width: "72%",
                            cursor: "pointer",
                            "& input": {
                              cursor: "pointer",
                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "white",
                              padding: "0 3px",
                              color: "#AAAAAA",
                              fontWeight: "700",
                            },
                          }}
                          inputProps={{
                            readOnly: true,
                          }}
                          onClick={copyToClipboard}
                          InputProps={{
                            startAdornment: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "8px",
                                }}
                              >
                                {!transferData?.walletAddress && (
                                  <div>
                                    <Skeleton
                                      className={styles.widgetSkeleton}
                                    />
                                  </div>
                                )}
                              </div>
                            ),
                            endAdornment: (
                              <CopyIcon
                                fill="#617381"
                                className={styles.copyIcon}
                              />
                            ),
                          }}
                        />
                      </div>
                      <div className={styles.thirdStepFee}>
                        <span>
                          <span style={{ marginRight: "4px" }}>
                            {formatTime(timeLeft)}
                          </span>

                          <CustomTooltip
                            placement="bottom-start"
                            title="Send money within 10 minutes or the replenishment process will be terminated"
                            arrow
                          >
                            <span>
                              <QestionIcon />
                            </span>
                          </CustomTooltip>
                        </span>
                        <div className={styles.thirdStepFeeContainer}>
                          <div className={styles.containerWithPrice}>
                            <span
                              style={{
                                marginRight: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              €{" "}
                              {summValue
                                ? (exchangeRate * summValue).toFixed(2)
                                : ""}
                            </span>
                            <CustomTooltip
                              placement="bottom-start"
                              title="The amount in dollars equals that amount in euros. We convert dollars to euros at a 1-to-1 exchange rate"
                              arrow
                            >
                              <span>
                                <QestionIcon />
                              </span>
                            </CustomTooltip>
                          </div>
                          <span
                            style={{
                              border: "1px solid var(--Primary-Black, #232323)",
                              minWidth: "16px",
                            }}
                          ></span>
                          <div className={styles.thirdStepServiceFee}>
                            <span style={{ whiteSpace: "nowrap" }}>
                              €{" "}
                              {summValue
                                ? (summValue * exchangeRate * 0.045).toFixed(2)
                                : ""}
                            </span>
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "12px",
                                color: "#AAAAAA",
                                paddingLeft: "4px",
                              }}
                            >
                              Service <br /> fee 4,5%
                            </span>
                          </div>
                          <EqualsIcon />
                          <div>
                            <div className={styles.thirdStepServiceFee}>
                              <span style={{ whiteSpace: "nowrap" }}>
                                €{" "}
                                {summValue
                                  ? (summValue * exchangeRate * 0.955).toFixed(
                                      2
                                    )
                                  : ""}
                              </span>
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  color: "#AAAAAA",
                                  paddingLeft: "4px",
                                }}
                              >
                                Will be credited <br /> to the accounts
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="primaryText" style={{ fontWeight: "400" }}>
                      After making the transfer, paste the{" "}
                      <span style={{ fontWeight: "700" }}>txnHash</span> in the
                      field below.
                    </p>
                    <TextField
                      id="txnHash"
                      label="Transaction hash"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          fontSize: "16px",
                          fontWeight: 700,
                          color: "#AAAAAA",
                          backgroundColor: "white",
                          paddingRight: "7px",
                        },
                      }}
                      sx={{
                        fontSize: "20px",
                        color: "#464646",
                      }}
                      value={txnHash}
                      onChange={handleInputChange}
                      error={isErrorHash}
                      helperText={isErrorHash ? errorHashMessage : ""}
                      placeholder="Enter transaction hash"
                      style={{
                        maxHeight: "40px",
                        marginBottom: isErrorHash ? "10px" : "",
                      }}
                    />

                    <div className={styles.thirdStepAttention}>
                      <div className={styles.thirdStepAttentionTitle}>
                        <h3 style={{ color: "#FF4D4D" }}>Attention</h3>{" "}
                        <button
                          onClick={toggleShowMore}
                          className={
                            showMore ? styles.thirdStepAttentionTitleButton : ""
                          }
                        >
                          <ShowMoreIcon />
                        </button>
                      </div>

                      <p
                        className={`secondaryText ${styles.thirdStepAttentionText}`}
                      >
                        It’s strictly forbidden to top up balance from{" "}
                        <span>Garantex</span> , <span>Hydra</span> and{" "}
                        <span>Tornado Cash</span> crypto wallets.
                      </p>
                      {showMore && (
                        <p
                          className={`secondaryText ${styles.thirdStepAttentionText}`}
                        >
                          Payments received from such wallets will block your
                          account with all funds without the possibility of
                          return! You will lose your money.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.buttonsStepsContainer}>
                    <button
                      onClick={handleNextStep}
                      className={styles.continueBtn}
                    >
                      Complete
                    </button>
                    <button onClick={handlePreviousStep}>Back</button>
                  </div>
                </div>
              </div>
              {timeLeft === 0 ? (
                <p
                  className="secondaryText"
                  style={{
                    color: "#464646",
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#FF4D4D",
                      marginRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    0:00{" "}
                    <CustomTooltip
                      placement="bottom-start"
                      title="Waiting time has expired, please top up again."
                      arrow
                    >
                      <span className={styles.containerQuestion}>
                        <QuestionIcons
                          fill="#21A038"
                          style={{ height: "18px" }}
                        />
                      </span>
                    </CustomTooltip>
                  </span>{" "}
                  Waiting time has expired, please top up again.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <button
          className={styles.closeBtn}
          onClick={() => {
            setTopUpModal(false);
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default WalletTopUpModal;
