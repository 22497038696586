import React from "react";

const CopyIcon = ({ fill = "#21A038", className, onClick }) => {
  return (
    <>
      <svg
        width="22"
        className={className}
        height="22"
        viewBox="0 0 22 22"
        onClick={onClick}
        style={{ cursor: "pointer" }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5639 18.1777H8.89501C8.60188 18.1777 8.36426 17.9401 8.36426 17.6469V8.97801C8.36426 8.68489 8.60188 8.44727 8.89501 8.44727H17.5639C17.857 8.44727 18.0946 8.68489 18.0946 8.97801V17.6469C18.0946 17.9401 17.857 18.1777 17.5639 18.1777Z"
          fill={fill}
        />
        <path
          d="M13.6718 8.44732V4.55516C13.6718 4.26204 13.4342 4.02441 13.141 4.02441H4.47215C4.17903 4.02441 3.94141 4.26204 3.94141 4.55516V13.2241C3.94141 13.5172 4.17903 13.7548 4.47215 13.7548H8.36431"
          stroke={fill}
          stroke-width="1.76916"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default CopyIcon;
