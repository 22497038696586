import { colors, InputAdornment, styled, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    height: "40px",
    padding: "0px",

    "& fieldset": {
      borderColor: "#AAAAAA",
    },
    "&:hover fieldset": {
      borderColor: "#AAAAAA",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#AAAAAA",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      color: "#464646",
      "&::placeholder": {
        fontSize: "14px",
        color: "#AAAAAA",
      },
    },
    "& .MuiInputLabel-root": {
      fontWeight: "700",
      color: "#AAAAAA",
      fontSize: "30px",
    },
  },
});

export const Input = ({
  name,
  control,
  label,
  variant = "outlined",
  className,
  placeholder,
  width,
  rules = {},
  defaultValue = "",
  startAdornment,
  endAdornment,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          variant={variant}
          className={className}
          placeholder={placeholder}
          error={!!error}
          helperText={error ? error.message : ""}
          InputProps={{
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          sx={{
            maxHeight: "40px",
            width: width,
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              height: "40px",
            },
          }}
          {...rest}
        />
      )}
    />
  );
};
