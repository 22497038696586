import React from "react";

const FBMService = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75Z"
        fill={fill}
      />
      <path
        d="M6.505 14.84H5.285V10.33H6.505V10.95H6.06V14.22H6.505V14.84ZM7.95609 14H7.00609V10.43H9.11609V11.205H7.95609V11.885H9.02609V12.66H7.95609V14ZM10.9464 10.43C11.4631 10.43 11.8314 10.5067 12.0514 10.66C12.2714 10.8133 12.3814 11.0333 12.3814 11.32C12.3814 11.4767 12.3531 11.61 12.2964 11.72C12.2397 11.8267 12.1681 11.9117 12.0814 11.975C11.9981 12.0383 11.9114 12.0817 11.8214 12.105V12.13C11.9181 12.1533 12.0147 12.1933 12.1114 12.25C12.2081 12.3067 12.2881 12.3917 12.3514 12.505C12.4181 12.615 12.4514 12.765 12.4514 12.955C12.4514 13.1717 12.3964 13.3583 12.2864 13.515C12.1764 13.6717 12.0197 13.7917 11.8164 13.875C11.6131 13.9583 11.3714 14 11.0914 14H9.70141V10.43H10.9464ZM10.9564 11.8C11.1164 11.8 11.2281 11.7667 11.2914 11.7C11.3581 11.6333 11.3914 11.5533 11.3914 11.46C11.3914 11.3633 11.3547 11.29 11.2814 11.24C11.2081 11.1867 11.0931 11.16 10.9364 11.16H10.6664V11.8H10.9564ZM10.6664 12.505V13.255H11.0064C11.1731 13.255 11.2897 13.22 11.3564 13.15C11.4231 13.0767 11.4564 12.985 11.4564 12.875C11.4564 12.8117 11.4414 12.7517 11.4114 12.695C11.3847 12.6383 11.3364 12.5933 11.2664 12.56C11.1997 12.5233 11.1064 12.505 10.9864 12.505H10.6664ZM14.6255 14L13.8955 11.43H13.8755C13.8822 11.4967 13.8889 11.5867 13.8955 11.7C13.9022 11.8133 13.9089 11.9367 13.9155 12.07C13.9222 12.2 13.9255 12.3267 13.9255 12.45V14H13.0705V10.43H14.3555L15.1005 12.965H15.1205L15.8505 10.43H17.1355V14H16.2505V12.435C16.2505 12.3217 16.2522 12.2017 16.2555 12.075C16.2589 11.945 16.2639 11.8233 16.2705 11.71C16.2772 11.5933 16.2822 11.5017 16.2855 11.435H16.2655L15.5455 14H14.6255ZM17.6345 14.22H18.0795V10.95H17.6345V10.33H18.8545V14.84H17.6345V14.22Z"
        fill={fill}
      />
    </svg>
  );
};

export default FBMService;
