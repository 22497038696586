import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getAllCards = async ({ page, perPage, params }) => {
  const response = await axiosAuth.get(API_PATHS.ALL_CARDS, {
    params: {
      ...params,
      page: page,
      perPage: perPage,
    },
  });

  return response.data;
};

export const getCard = async (cardId) => {
  const response = await axiosAuth.get(`${API_PATHS.ALL_CARDS}/${cardId}`);

  return response.data;
};

export const getCardsOptions = async () => {
  const response = await axiosAuth.get(`${API_PATHS.CARDS_OPTIONS}`);

  return response.data.data;
};
export const changeCardStatus = (id, body) => {
  return axiosAuth.post(`${API_PATHS.ALL_CARDS}/${id}/status`, body);
};

export const currentCardCredentials = async (id) => {
  const res = await axiosAuth.get(`${API_PATHS.ALL_CARDS}/${id}/credentials`);

  return res.data;
};

export const changeCard = (id, body) => {
  return axiosAuth.post(`${API_PATHS.ALL_CARDS}/${id}`, body);
};

export const issueCard = (body) => {
  return axiosAuth.post(`${API_PATHS.ALL_CARDS}`, body);
};

export const getAllCardBins = async ({ perPage, params }) => {
  const res = await axiosAuth.get(API_PATHS.CARDS_BINS, {
    params: {
      per_page: perPage,
      ...params,
    },
  });

  return res.data;
};

export const getAllCardIbans = async ({ perPage, params }) => {
  const res = await axiosAuth.get(API_PATHS.CARDS_IBANS, {
    params: {
      per_page: perPage,
      ...params,
    },
  });

  return res.data;
};

export const getExportCards = async ({ params }) => {
  return await axiosAuth.get(API_PATHS.EXPORT_CARDS, {
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
    params: {
      ...params,
    },
  });
};
