import clsx from "clsx";
import styles from "./LimitAndConditions.module.scss";

const LimitAndConditions = () => {
  return (
    <div className={styles.profileSettigns}>
      <div className={styles.profileSettingsTop}>
        <div className={clsx(styles.flexRow)}>
          <h2>Cards per day</h2>
          <span>Unlimited</span>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>Total active cards</h2>
          <span>Unlimited</span>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>Limit on card</h2>
          <span>Unlimited</span>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>Limit per transaction, €</h2>
          <span>Unlimited</span>
        </div>
      </div>
      <div className={styles.limitnConditionItems}>
        <div className={styles.limitnConditionItem}>
          <h2>
            <span>Replenishment</span> fee %
          </h2>
          <div className={styles.limitnConditionItemContents}>
            <div className={styles.limitnConditionItemContent}>
              <p>4,5%</p>
              <p>from $500</p>
            </div>
          </div>
        </div>

        <div className={styles.limitnConditionItem}>
          <h2>
            <span> Cards</span> price
          </h2>
          <div className={styles.limitnConditionItemContents}>
            <div className={styles.limitnConditionItemContent}>
              <p>3.5€</p>
              <p>for 1 card</p>
            </div>
            <div className={styles.limitnConditionItemContent}>
              <p>2.5€</p>
              <p>from 2 to 4 cards</p>
            </div>
            <div className={styles.limitnConditionItemContent}>
              <p>1.5€</p>
              <p>from 5 cards</p>
            </div>
          </div>
        </div>
        <div className={styles.limitnConditionItem}>
          <h2>
            <span>Withdrawal</span> conditions
          </h2>
          <div className={styles.limitnConditionItemWithdrawContent}>
            <span>4%</span>
            <span>
              for <br />
              withdrawal
            </span>
          </div>
          <p className={styles.limitnConditionItemWithdraw}>
            Withdrawal takes place in 2-3 business days.
          </p>
        </div>
        <div className={styles.limitnConditionItem}>
          <h2>
            Allowable <span>DECLINE RATE</span>
          </h2>
          <div className={styles.limitnConditionItemWithdrawContent}>
            <span>10%</span>
            <span>
              If exceeded, we will give <br /> you time to fix it.
            </span>
          </div>
          <p className={styles.limitnConditionItemWithdraw}>
            If decline rate won’t change - we will have to freeze your account
            and <span>forcibly withdraw all funds back to your wallet.</span>
          </p>
        </div>
      </div>
      <div className={styles.limitnConditionAttention}>
        <h3>ATTENTION</h3>
        <p>
          Due to the current sanctions policy,{" "}
          <span className={styles.tekstInfo}>
            it is strictly forbidden to top-up [FBM] Pay accounts from crypto
            wallets belonging to such platforms, as:
          </span>
          {/* <br /> */}
          <span className={styles.limitnConditionAttentionSpan}>Garantex</span>
          <span className={styles.limitnConditionAttentionSpan}>Hydra</span>
          <span className={styles.limitnConditionAttentionSpan}>
            Tornado Cash
          </span>
        </p>
        <p>
          Payments received from such wallets will block your account with all
          funds without the possibility of return! You will lose your money.
        </p>
      </div>
    </div>
  );
};

export default LimitAndConditions;
