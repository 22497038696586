import { useForm } from "react-hook-form";
import { Button } from "../../components/Button/Button";
import { useEffect, useState } from "react";
import styles from "./RecoverPassword.module.scss";
import { LOGIN_REG_EXP } from "../../constants/constants";
import { requestVerificationCode } from "../../api/recoverPasswordAuthentication";
import TelegramIcon from "../../icons/TelegramIcon";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { PAGE_ROUTES } from "../constants";
import ArrowLeft from "../../icons/ArrowLeft";

const RecoverPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const { email } = watch();

  useEffect(() => {
    if (errors && errors.serverError) {
      clearErrors("serverError");
    }
  }, [email, errors]);

  const handleResetVerificationCode = async (data) => {
    try {
      setIsLoading(true);
      const body = {
        email: data.email,
      };

      const response = await requestVerificationCode(body);
      if (response && response.data) {
        setError("serverError", { message: response.data.status });
      }
    } catch (error) {
      setError("serverError", { message: error.response.data.data.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.recoverPage}>
      <div className={styles.recoverPageContainer}>
        <div className={styles.recoverPageTitle}>
          <h1>Password change</h1>
          <p className="primaryText">
            To change your password, please specify your e-mail address to which
            we will send the link.
          </p>
        </div>

        <form
          onSubmit={handleSubmit(handleResetVerificationCode)}
          className={styles.recoverPassword}
        >
          <div className={styles.recoverPasswordContainer}>
            <div className={styles.recoverPasswordInputs}>
              <div className={styles.recoverPasswordInput}>
                <label htmlFor="email" className="primaryText">
                  E-mail address
                </label>
                <input
                  id="email"
                  type="text"
                  placeholder="fbmpay@gmail.com"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: LOGIN_REG_EXP,
                      message: "Invalid email address",
                    },
                  })}
                />

                {errors.email && (
                  <p className={styles.recoverPasswordError}>
                    {errors.email.message}
                  </p>
                )}
              </div>
            </div>
            {errors.serverError && (
              <p className={styles.recoverPasswordSuccess}>
                {errors.serverError.message}
              </p>
            )}
          </div>
          <Button isLoading={isLoading} type="submit">
            Reset password
          </Button>
        </form>
        <p className={styles.titleBackLogin}>
          <ArrowLeft style={{ height: "15px" }} /> Return to{" "}
          <Link to={PAGE_ROUTES.LOGIN}> previous page</Link>
        </p>
        <div className={styles.loginContainerRegistration}>
          <div className={styles.loginContainerRegistrationText}>
            <p className="semiHedline">Any troubles?</p>
            <p className={clsx("primaryText", styles.primaryText)}>
              Contact our manager{" "}
              <a
                href="https://t.me/v_astanin"
                target="_blank"
                className={styles.loginRecoverLink}
                rel="noreferrer"
              >
                @fbm_ivan
              </a>{" "}
              for help
            </p>
          </div>
          <Button className={styles.loginContainerRegistrationButton}>
            <a
              href="https://t.me/v_astanin"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact <TelegramIcon />
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
