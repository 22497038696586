import React from "react";

const PlusIcon = ({
  fill = "#232323",
  width = "20",
  height = "20",
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 20 20"
      fill="none"
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.25C10.4142 0.25 10.75 0.58579 10.75 1V9.25H19C19.4142 9.25 19.75 9.5858 19.75 10C19.75 10.4142 19.4142 10.75 19 10.75H10.75V19C10.75 19.4142 10.4142 19.75 10 19.75C9.5858 19.75 9.25 19.4142 9.25 19V10.75H1C0.58579 10.75 0.25 10.4142 0.25 10C0.25 9.5858 0.58579 9.25 1 9.25H9.25V1C9.25 0.58579 9.5858 0.25 10 0.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusIcon;
