import LogoIcon from "../../icons/LogoIcon";
import Footer from "../footer/Footer";
import MenuBar from "../menu/MenuBar";
import MobileMenu from "../MobileMenu/MobileMenu";
import styles from "./Sidebar.module.scss";

const Sidebar = () => {
  return (
    <>
      <aside className={styles.sideBar}>
        <div className={styles.sideBarContainer}>
          <div className={styles.wrapperSidebar}>
            <div className={styles.logoContainer}>
              <LogoIcon />
            </div>
            <div className={styles.sideBarNavigation}>
              <MenuBar />
            </div>
          </div>

          <Footer />
        </div>
      </aside>

      <MobileMenu />
    </>
  );
};

export default Sidebar;
