import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import CopyIcon from "../../icons/CopyIcon";
import clsx from "clsx";
import { toast } from "react-toastify";

const CardItem = ({ cardType, cardCredentials }) => {
  const { cardNumber, cvv, expirationDate } = cardCredentials;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCardClass = () => {
    switch (cardType) {
      case "visa":
        return isMobile ? styles.visaMobile : styles.visa;
      case "mc":
        return isMobile ? styles.mcMobile : styles.mc;
      case "union":
        return isMobile ? styles.upMobile : styles.union;
      default:
        return "";
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleCopyAll = () => {
    const textToCopy = `Card Number: ${cardNumber}\nExpiration Date: ${expirationDate}\nCVV: ${cvv}`;
    copyToClipboard(textToCopy);
  };

  const formatCardNumberToSpans = () => {
    return cardNumber
      ? cardNumber
          .match(/.{1,4}/g)
          .map((group, index) => <span key={index}>{group}</span>)
      : "No card info";
  };

  return (
    <div className={clsx(styles.container, getCardClass())}>
      <div className={styles.dataExpNumber}>
        <div className={styles.cardNumber}>
          {formatCardNumberToSpans(cardNumber)}
        </div>
        <CopyIcon fill="#CBF6D9" onClick={() => copyToClipboard(cardNumber)} />
      </div>

      <div className={styles.containerAddInfo}>
        <div className={styles.dataExp}>
          <span className={styles.commonText}>EXP</span>
          <span className={styles.extraBold}>{expirationDate}</span>
          <CopyIcon
            fill="#CBF6D9"
            onClick={() => copyToClipboard(`EXP: ${expirationDate}`)}
          />
        </div>

        <div className={styles.dataExp}>
          <span>CVV</span>
          <span className={styles.extraBold}>{cvv}</span>
          <CopyIcon
            fill="#CBF6D9"
            onClick={() => copyToClipboard(`CVV: ${cvv}`)}
          />
        </div>
      </div>
      <div className={styles.dataExp}>
        <span>COPY ALL</span>
        <CopyIcon fill="#CBF6D9" onClick={handleCopyAll} />
      </div>
    </div>
  );
};

export default CardItem;
