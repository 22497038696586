import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";

export const useAuthQuery = (queryKey, queryFn, onSuccess, options = {}) => {
  const { token } = useContext(AuthContext);

  return useQuery(queryKey, queryFn, onSuccess, {
    ...options,
    enabled: !!token && (options.enabled ?? true),
  });
};
