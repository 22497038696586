import React from "react";

const TGChannelIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.64876 14.8669C8.68866 15.8093 9.36531 16.5236 10.1154 17.0178C11.6938 18.0579 13.2106 19.242 14.8398 20.199C15.3617 20.5057 16.0798 20.8179 16.9708 20.736C18.4585 20.5991 19.1199 19.235 19.363 17.9349C19.8736 15.2053 20.8918 9.21709 21.1315 6.6989C21.2716 5.22596 20.3608 3.8233 18.7678 3.85137C18.2078 3.86123 17.6362 4.02184 16.7927 4.32794C15.9233 4.64342 14.5965 5.18038 12.4577 6.06999C10.9445 6.69935 7.93828 7.99437 3.45023 9.94969C2.52739 10.3196 1.52713 10.914 1.39091 12.0006C1.18414 13.65 2.81503 14.2156 4.08432 14.6282C5.6029 15.1218 7.12984 15.5877 8.64876 14.8669ZM8.62318 13.1511C7.27544 14.0766 6.00886 13.6766 4.54803 13.2017C3.58095 12.8874 2.81233 12.7212 2.87926 12.1872C2.91412 11.9091 3.29706 11.6248 4.02807 11.3341C8.52988 9.37272 11.5318 8.07972 13.0337 7.45496C17.3223 5.67122 18.2134 5.36136 18.7942 5.35113C19.5085 5.33854 19.6972 5.93592 19.6382 6.55678C19.4058 8.99862 18.4002 14.9242 17.8886 17.6591C17.6722 18.8163 17.2459 19.2043 16.8333 19.2423C16.1231 19.3077 15.5483 18.8979 14.9085 18.4417C13.6114 17.517 12.2711 16.642 10.9408 15.7653C9.62856 14.9007 10.1779 14.3711 10.9104 13.665C12.1397 12.4799 13.4096 11.3373 14.6263 10.1391C15.1375 9.63567 15.5056 9.24717 15.5284 9.14989C15.5921 8.87824 15.3532 8.79679 15.1369 8.84584C15.0005 8.87682 12.8293 10.3119 8.62318 13.1511Z"
        fill="#232323"
      />
    </svg>
  );
};

export default TGChannelIcon;
