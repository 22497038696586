import { useState, useEffect, useContext } from "react";
import { Button, Group, Box } from "@mantine/core";
import Table from "../../components/Table/Table";
import { getAllTeamMembers } from "../../api/usersApi";
import { IconPlus } from "@tabler/icons-react";
import ModalAddMember from "../Modals/ModalAddMember/ModalAddMember";
import { ModalsInteractive } from "../Modals/TeamManagementModals/ModalsInteractive";
import { useQueryClient } from "@tanstack/react-query";
import { useTeamManagementHandlers } from "../../hooks/handles/useTeamManagementHandlers";
import ColumnsTeamManagement from "../../constants/columns/ColumnsTeamManagement";
import styles from "./styles.module.scss";
import Skeleton from "../../components/Skeleton/Skeleton";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import ReloadIcon from "../../icons/ReloadIcon";
import { optionsTable } from "../../constants/constants";
import { AuthContext, AuthProvider } from "../../context/AuthContext";

const TeamManagementTable = () => {
  const pageSize = 15;

  const queryClient = useQueryClient();

  const [data, setData] = useState([]);
  const [modals, setModals] = useState({
    modalAddCurrentMember: false,
    modalAddMember: false,
  });
  const [modalData, setModalData] = useState(null);
  const [activeModalInveractive, setActiveModalInveractive] = useState(null);
  const [isEditModal, setIsEditModal] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
  });
  const { userData } = useContext(AuthContext);
  const {
    data: tableData,
    isLoading,
    isFetching,
  } = useAuthQuery({
    queryKey: ["tableData", pagination.pageIndex, pageSize],
    queryFn: () =>
      getAllTeamMembers({
        page: pagination.pageIndex + 1,
        perPage: pageSize,
      }),
    keepPreviousData: true,
    staleTime: 2 * 60 * 1000,
    onSuccess: (data) => {
      if (data.meta.current_page < data.meta.last_page) {
        queryClient.prefetchQuery({
          queryKey: ["tableData", pagination.pageIndex + 2, pageSize],
          queryFn: () =>
            getAllTeamMembers({
              page: pagination.pageIndex + 2,
              perPage: pageSize,
            }),
        });
      }
    },
  });
  console.log("userData", userData);
  useEffect(() => {
    if (tableData?.data && !isLoading && !isFetching) {
      setData(tableData.data.data);
    }
  }, [tableData, isLoading, isFetching]);

  const { handleEditUser, handleOpenModal, handleAddUser, handleModalSubmit } =
    useTeamManagementHandlers({
      setData,
      setActiveModalInveractive,
      setModalData,
      setIsEditModal,
      setModals,
      queryClient,
    });

  useEffect(() => {
    const bodyRoot = document.getElementById("root");

    if (modals.modalAddMember) {
      bodyRoot.style.overflow = "hidden";
    } else {
      bodyRoot.style.overflow = "auto";
    }
  }, [modals.modalAddMember]);

  if (isLoading)
    return <Skeleton className={styles.skeletonTable} isSpinner={true} />;

  return (
    <Box
      style={{ borderRadius: "8px", overflow: "hidden", background: "#fff" }}
      styles={{
        maxWidth: "calc(100% - 40px)",
        minWidth: "700px",
        background: "red",
      }}
    >
      <div className={styles.containerReloadTable}>
        <Button
          onClick={handleAddUser}
          rightIcon={<IconPlus />}
          style={{
            background:
              "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))",
            color: "#fff",
            border: "none",
            borderRadius: "8px",
            marginRight: "16px",
          }}
        >
          Add member
        </Button>
        <ReloadIcon
          onClick={() => queryClient.invalidateQueries(["tableData"])}
          style={{
            animation: isFetching ? "spin 1s linear infinite" : "none",
            cursor: "pointer",
          }}
        />
      </div>
      <Table
        columns={ColumnsTeamManagement({
          handleEditUser,
          handleOpenModal,
          userData,
        })}
        data={data}
        enablePagination={true}
        manualPagination={true}
        onPaginationChange={(updater) => {
          setPagination((prev) => {
            const newState =
              typeof updater === "function" ? updater(prev) : updater;
            return newState;
          });
        }}
        state={{
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pageSize,
          },
        }}
        enableColumnActions={false}
        rowCount={tableData?.data?.meta?.total ?? 0}
        pageCount={
          tableData?.data?.meta?.last_page ??
          Math.ceil((tableData?.data?.meta?.total ?? 0) / pageSize)
        }
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableTopToolbar={false}
        enableDensityToggle={false}
        paginationDisplayMode={"pages"}
        mantinePaginationProps={{
          showRowsPerPage: false,
          showFirstLastButtons: true,
        }}
        mantineTableProps={{
          ...optionsTable,
        }}
      />

      <ModalAddMember
        setModals={setModals}
        modals={modals}
        isEdit={isEditModal}
        userData={modalData}
        setData={setData}
      />

      <ModalsInteractive
        activeModal={activeModalInveractive}
        modalData={modalData}
        onSubmit={handleModalSubmit}
        onCancel={() => {
          setActiveModalInveractive(null);
          setModalData(null);
        }}
      />
    </Box>
  );
};

export default TeamManagementTable;

// import { useState, useEffect } from "react";
// import { Button, Group, Box, Skeleton } from "@mantine/core";
// import Table from "../../components/Table/Table";
// import { getAllTeamMembers } from "../../api/usersApi";
// import { IconPlus } from "@tabler/icons-react";
// import ModalAddMember from "../Modals/ModalAddMember/ModalAddMember";
// import { ModalsInteractive } from "../Modals/TeamManagementModals/ModalsInteractive";
// import { useQuery, useQueryClient } from "@tanstack/react-query";
// import { useTeamManagementHandlers } from "../../hooks/handles/useTeamManagementHandlers";
// import ColumnsTeamManagement from "../../constants/columns/ColumnsTeamManagement";
// import styles from "./styles.module.scss";

// const optionsTable = {
//   striped: true,
//   highlightOnHover: true,
//   withBorder: false,
//   sx: {
//     "thead th": {
//       color: "#aaaaaa !important",
//       fontWeight: "600 !important",
//     },
//     "th:nth-of-type(6)": {
//       paddingLeft: "11px !important",
//     },
//     "th:nth-of-type(7)": {
//       paddingLeft: "35px !important",
//     },
//     td: {
//       background: "white !important",
//     },
//   },
// };

// const TeamManagementTable = () => {
//   const [data, setData] = useState([]);
//   const [modals, setModals] = useState({
//     modalAddCurrentMember: false,
//     modalAddMember: false,
//   });
//   const [currentPage, setCurrentPage] = useState(1);
//   const [perPage, setPerPage] = useState(15);

//   const [modalData, setModalData] = useState(null);
//   const [activeModalInveractive, setActiveModalInveractive] = useState(null);
//   const [isEditModal, setIsEditModal] = useState(false);
//   const queryClient = useQueryClient();

//   const {
//     data: tableData,
//     isLoading,
//     isFetching,
//   } = useQuery({
//     queryKey: ["tableData", currentPage, perPage],
//     queryFn: () => getAllTeamMembers({ page: currentPage, perPage }),
//     keepPreviousData: true,
//     staleTime: 5 * 1000,
//     onSuccess: (data) => {
//       if (data.meta.current_page < data.meta.last_page) {
//         queryClient.prefetchQuery({
//           queryKey: ["tableData", currentPage + 1, perPage],
//           queryFn: () => getAllTeamMembers({ page: currentPage + 1, perPage }),
//         });
//       }
//     },
//   });

//   console.log("tableData", tableData.data);

//   useEffect(() => {
//     console.log("Updated tableData:", tableData?.data);
//     if (tableData?.data && !isLoading && !isFetching) {
//       console.log("newData", tableData.data);
//       setData(tableData.data);
//     }
//   }, [tableData, isLoading, isFetching]);

//   console.log("data", data);
//   const { handleEditUser, handleOpenModal, handleAddUser, handleModalSubmit } =
//     useTeamManagementHandlers({
//       setData,
//       setActiveModalInveractive,
//       setModalData,
//       setIsEditModal,
//       setModals,
//     });

//   if (isLoading || isFetching)
//     return <Skeleton className={styles.skeletonTable} />;

//   return (
//     <Box
//       style={{ borderRadius: "8px", overflow: "hidden", background: "#fff" }}
//       styles={{
//         maxWidth: "calc(100% - 40px)",
//         minWidth: "700px",
//         background: "red",
//       }}
//     >
//       <Group position="apart" p="md">
//         <Button
//           onClick={handleAddUser}
//           rightIcon={<IconPlus />}
//           style={{
//             background: "linear-gradient(90deg, #34d058 0%, #34b4d0 100%)",
//             color: "#fff",
//           }}
//         >
//           Add member
//         </Button>
//       </Group>
//       <Table
//         columns={ColumnsTeamManagement({
//           handleEditUser,
//           handleOpenModal,
//         })}
//         data={data}
//         enablePagination={true}
//         initialState={{ pageSize: perPage }}
//         manualPagination={true}
//         onPageChange={(pageIndex) => {
//           setCurrentPage(pageIndex + 1);
//         }}
//         state={{
//           pageIndex: currentPage - 1,
//           pageSize: perPage,
//         }}
//         onPageSizeChange={(newPageSize) => {
//           setPerPage(newPageSize);
//           setCurrentPage(1);
//         }}
//         enableColumnActions={false}
//         enableColumnFilters={false}
//         enableGlobalFilter={false}
//         enableHiding={false}
//         enableTopToolbar={false}
//         enableDensityToggle={false}
//         mantineTableProps={optionsTable}
//       />
//       <ModalAddMember
//         setModals={setModals}
//         modals={modals}
//         isEdit={isEditModal}
//         userData={modalData}
//         setData={setData}
//       />

//       <ModalsInteractive
//         activeModal={activeModalInveractive}
//         modalData={modalData}
//         onSubmit={handleModalSubmit}
//         onCancel={() => {
//           setActiveModalInveractive(null);
//           setModalData(null);
//         }}
//       />
//     </Box>
//   );
// };

// export default TeamManagementTable;

// const optionsTable = {
//   striped: true,
//   highlightOnHover: true,
//   withBorder: false,
//   sx: {
//     "thead th": {
//       color: "#aaaaaa !important",
//       fontWeight: "600 !important",
//     },
//     "th:nth-of-type(6)": {
//       paddingLeft: "11px !important",
//     },
//     "th:nth-of-type(7)": {
//       paddingLeft: "35px !important",
//     },
//     td: {
//       background: "white !important",
//     },
//   },
// };

// const TeamManagementTable = () => {
//   const [data, setData] = useState([]);
//   const [modals, setModals] = useState({
//     modalAddCurrentMember: false,
//     modalAddMember: false,
//   });

//   const [modalData, setModalData] = useState(null);
//   const [activeModalInveractive, setActiveModalInveractive] = useState(null);
//   const [isEditModal, setIsEditModal] = useState(false);

//   const [pagination, setPagination] = useState({
//     pageIndex: 0, // 0-индексация
//     // pageSize: 15,
//   });
//   const pageSize = 15;

//   const queryClient = useQueryClient();

//   const {
//     data: tableData,
//     isLoading,
//     isFetching,
//   } = useQuery({
//     queryKey: ["tableData", pagination.pageIndex, pagination.pageSize],
//     queryFn: () =>
//       getAllTeamMembers({
//         page: pagination.pageIndex + 1,
//         perPage: pagination.pageSize,
//       }),
//     keepPreviousData: true,
//     staleTime: 5 * 1000,
//     onSuccess: (data) => {
//       if (data.meta.current_page < data.meta.last_page) {
//         queryClient.prefetchQuery({
//           queryKey: [
//             "tableData",
//             pagination.pageIndex + 2,
//             pagination.pageSize,
//           ],
//           queryFn: () =>
//             getAllTeamMembers({
//               page: pagination.pageIndex + 2,
//               perPage: pagination.pageSize,
//             }),
//         });
//       }
//     },
//   });
//   useEffect(() => {
//     console.log("Updated tableData:", tableData?.data);
//     if (tableData?.data && !isLoading && !isFetching) {
//       console.log("newData", tableData.data);
//       setData(tableData.data);
//     }
//   }, [tableData, isLoading, isFetching]);

//   const { handleEditUser, handleOpenModal, handleAddUser, handleModalSubmit } =
//     useTeamManagementHandlers({
//       setData,
//       setActiveModalInveractive,
//       setModalData,
//       setIsEditModal,
//       setModals,
//     });

//   if (isLoading || isFetching)
//     return <Skeleton className={styles.skeletonTable} />;

//   const paginationMemo = useMemo(
//     () => ({
//       pageIndex: pageIndex,
//       pageSize: pageSize,
//     }),
//     [pageIndex, pageSize]
//   );

//   console.log("currentPage", pagination.pageIndex + 1);
//   console.log("perPage", pagination.pageSize);
//   return (
//     <Box
//       style={{ borderRadius: "8px", overflow: "hidden", background: "#fff" }}
//       styles={{
//         maxWidth: "calc(100% - 40px)",
//         minWidth: "700px",
//         background: "red",
//       }}
//     >
//       <Group position="apart" p="md">
//         <Button
//           onClick={handleAddUser}
//           rightIcon={<IconPlus />}
//           style={{
//             background: "linear-gradient(90deg, #34d058 0%, #34b4d0 100%)",
//             color: "#fff",
//           }}
//         >
//           Add member
//         </Button>
//       </Group>
//       <Table
//         columns={ColumnsTeamManagement({
//           handleEditUser,
//           handleOpenModal,
//         })}
//         data={data}
//         enablePagination={true}
//         // initialState={{ pageSize: perPage }}
//         manualPagination={true}
//         onPaginationChange={(updater) => {
//           console.log("onPaginationChange:", updater);
//           setPagination((prev) => {
//             const newState =
//               typeof updater === "function" ? updater(prev) : updater;
//             console.log("New pagination state:", newState);
//             return newState;
//           });
//         }}
//         state={{
//           pagination: {
//             pageIndex: pagination.pageIndex,
//             pageSize: pagination.pageSize,
//           },
//         }}
//         enableColumnActions={false}
//         rowCount={tableData?.meta?.total ?? 0}
//         pageCount={
//           tableData?.meta?.last_page ??
//           Math.ceil((tableData?.meta?.total ?? 0) / pagination.pageSize)
//         }
//         enableColumnFilters={false}
//         enableGlobalFilter={false}
//         enableHiding={false}
//         enableTopToolbar={false}
//         enableDensityToggle={false}
//         showRowsPerPage={false}
//         paginationDisplayMode={"pages"}
//         mantinePaginationProps={{
//           showRowsPerPage: false,
//         }}
//         mantineTableProps={{
//           ...optionsTable,
//         }}
//       />
//       <ModalAddMember
//         setModals={setModals}
//         modals={modals}
//         isEdit={isEditModal}
//         userData={modalData}
//         setData={setData}
//       />

//       <ModalsInteractive
//         activeModal={activeModalInveractive}
//         modalData={modalData}
//         onSubmit={handleModalSubmit}
//         onCancel={() => {
//           setActiveModalInveractive(null);
//           setModalData(null);
//         }}
//       />
//     </Box>
//   );
// };

// export default TeamManagementTable;
