import React, { useState } from "react";
import { Table, Select, Button, Group, Pagination } from "@mantine/core";
import ReloadIcon from "../../../icons/ReloadIcon";
import styles from "./BalanceHistoryTable.module.scss";
import {
  accountOperations,
  getAllAvailableAccounts,
  getExportBalanceHistory,
} from "../../../api/getAccounts";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { DateFormatter } from "../../../components/DateFormatter/DateFormatter";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getBalanceHistoryData } from "../../../api/getExcel";
import ExcelButton from "../../../components/ExcelButton/ExcelButton";
import { getOperationType } from "../../../utils/getOperationType";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import DatePicker from "../../../components/DatePicker/DatePickerInput";
import { handleExcelDownload } from "../../../utils/excelDownload";

const perPageFiltersAccs = 100;

const BalanceHistoryTable = () => {
  const [pagination, setPagination] = useState({ pageIndex: 1 });
  const pageSize = 15;
  const queryClient = useQueryClient();
  const [isActiveFilters, setIsActiveFilters] = useState(false);
  const [dateValue, setDateValue] = useState([null, null]);

  const handleChangePage = (page) => {
    const newPageIndex = page;
    setPagination({ pageIndex: newPageIndex });
  };

  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["tableData", pagination.pageIndex, pageSize],
    queryFn: () =>
      accountOperations({
        params: filters,
        page: pagination.pageIndex,
        perPage: pageSize,
      }),
  });

  const {
    data: accountsAll,
    isLoading: isLoadingAllAccounts,
    isFetching: isFetchingAllAccounts,
  } = useQuery({
    queryKey: ["accountsAllBH", perPageFiltersAccs],
    queryFn: () => getAllAvailableAccounts(perPageFiltersAccs),
  });

  const [accountName, setAccountName] = useState();

  const [filters, setFilters] = useState({
    accountId: null,
    iban: null,
    dateFrom: null,
    dateTo: null,
    type: null,
    refillMethod: null,
    sourceId: null,
  });

  const handleUpdateAccountsData = () => {
    queryClient.invalidateQueries(["tableData"]);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleClearFilters = () => {
    setAccountName(null);
    setDateValue([null, null]);

    setFilters({
      accountId: null,
      dateFrom: null,
      dateTo: null,
      type: null,
      refillMethod: null,
      sourceId: null,
    });

    setTimeout(() => {
      handleUpdateAccountsData();
    }, 0);
  };
  const allAvailableAccountsToFilter = accountsAll?.data?.data;

  console.log("allAvailableAccountsToFilter", allAvailableAccountsToFilter);

  console.log("filters", filters);
  return (
    <div className={styles.balanceHistoryContainer}>
      <div className={styles.balanceHistoryHeadernFilters}>
        <div className={styles.balanceHistoryHeader}>
          <div className={styles.balanceHistoryHeaderTitle}>
            <h3>BALANCE HISTORY</h3>
            <FiltersButton
              isActive={isActiveFilters}
              onClick={() => setIsActiveFilters((prev) => !prev)}
            />
            <ReloadIcon
              onClick={handleUpdateAccountsData}
              style={{
                animation: isFetching ? "spin 1s linear infinite" : "none",
              }}
            />
          </div>
          <ExcelButton
            onSubmit={() =>
              handleExcelDownload({
                fn: () => getExportBalanceHistory({ params: filters }),
                fileName: "balance-history",
              })
            }
          />
        </div>
        <div className={styles.balanceHistoryHeaderMobile}>
          <div className={styles.balanceHistoryHeaderTitle}>
            <h3>BALANCE HISTORY</h3>
            <ReloadIcon
              onClick={handleUpdateAccountsData}
              style={{
                animation: isFetching ? "spin 1s linear infinite" : "none",
              }}
            />
          </div>
          <div className={styles.balanceHistoryHeaderButtonsMobile}>
            <FiltersButton
              isActive={isActiveFilters}
              onClick={() => setIsActiveFilters((prev) => !prev)}
            />
            <ExcelButton
              onSubmit={() =>
                handleExcelDownload({
                  fn: () => getExportBalanceHistory({ params: filters }),
                  fileName: "balance-history",
                })
              }
            />
          </div>
        </div>
        {isActiveFilters && (
          <div className={styles.balanceHistoryFilters}>
            <div>
              <Group
                spacing="sm"
                mb="md"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  minWidth: "100%",
                }}
              >
                <div
                  className={styles.datePickerInput}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <DatePicker
                    setFilters={setFilters}
                    setDateValue={setDateValue}
                    dateValue={dateValue}
                  />
                </div>

                <Select
                  label="Account"
                  value={filters.accountId || null}
                  onChange={(selectedId) => {
                    const selectedAccount = allAvailableAccountsToFilter?.find(
                      (item) => item.ID === selectedId // Используем ID в верхнем регистре
                    );
                    console.log("selectedId", selectedId);
                    if (selectedAccount) {
                      // Обновляем стейт: записываем ID и IBAN
                      setFilters((prev) => ({
                        ...prev,
                        accountId: selectedAccount.ID, // Обратите внимание на верхний регистр
                        iban: selectedAccount.iban,
                      }));
                    } else {
                      // Если ничего не выбрано, сбрасываем поля
                      setFilters((prev) => ({
                        ...prev,
                        accountId: null,
                        iban: null,
                      }));
                    }
                  }}
                  placeholder={isLoading ? "Loading..." : "All"}
                  data={
                    allAvailableAccountsToFilter?.map((item) => ({
                      value: item.ID, // Используем верхний регистр для поля ID
                      label: `${item.name}, ${item.iban}, ${
                        item.balance === 0
                          ? item.balance
                          : item.balance?.toFixed(2)
                      } EUR`,
                    })) || []
                  }
                  styles={{
                    label: {
                      marginBottom: 8,
                    },
                    input: {
                      "&:focus-within": {
                        borderColor: "rgb(33, 160, 56)",
                        borderWidth: "1px",
                      },
                    },
                    item: {
                      "&[data-hovered]": {
                        background:
                          "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                        color: "#fff",
                        borderRadius: "4px",
                      },
                      "&[data-selected]": {
                        background:
                          "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                        color: "#fff",
                      },
                    },
                  }}
                  className={styles.inputWidths}
                />

                <Select
                  label="Type"
                  value={filters?.type}
                  onChange={(value) => handleFilterChange("type", value)}
                  placeholder="Select type"
                  data={[
                    { value: "REPLENISHMENT_FEE", label: "Replenishment Fee" },
                    { value: "REPLENISHMENT", label: "Replenishment" },
                    {
                      value: "INTERNAL_PAYMENT",
                      label: "Transfer between accounts",
                    },
                    { value: "WITHDRAWAL", label: "Withdrawal" },
                    { value: "OTHER", label: "Other" },
                  ]}
                  className={styles.inputWidths}
                  styles={{
                    label: {
                      marginBottom: 8,
                    },
                    input: {
                      "&:focus-within": {
                        borderColor: "rgb(33, 160, 56)",
                        borderWidth: "1px",
                      },
                    },
                    item: {
                      "&[data-hovered]": {
                        background:
                          "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                        color: "#fff",
                        borderRadius: "4px",
                      },
                      "&[data-selected]": {
                        background:
                          "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                        color: "#fff",
                      },
                    },
                  }}
                />
                <Select
                  label="Refill Method"
                  value={filters?.refillMethod}
                  onChange={(value) =>
                    handleFilterChange("refillMethod", value)
                  }
                  placeholder="Select refill method"
                  data={[
                    { value: "WIRED", label: "Wired" },
                    { value: "CRYPTO", label: "Crypto" },
                  ]}
                  className={styles.inputWidths}
                  styles={{
                    label: {
                      marginBottom: 8,
                    },
                    input: {
                      "&:focus-within": {
                        borderColor: "rgb(33, 160, 56)",
                        borderWidth: "1px",
                      },
                    },
                    item: {
                      "&[data-hovered]": {
                        background:
                          "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                        color: "#fff",
                        borderRadius: "4px",
                      },
                      "&[data-selected]": {
                        background:
                          "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                        color: "#fff",
                      },
                    },
                  }}
                />
              </Group>
              <div className={styles.filterButtons}>
                <button
                  className={styles.applyFiltersButton}
                  onClick={handleUpdateAccountsData}
                >
                  Apply filters
                </button>
                <button
                  className={styles.clearFiltersButton}
                  onClick={handleClearFilters}
                >
                  Clear filters
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <Skeleton className={styles.skeletonTable} isSpinner={true} />
      ) : data?.data?.length ? (
        <div style={{ width: "100%", overflowX: "auto", marginTop: "32px" }}>
          <Table
            striped
            highlightOnHover
            sx={{
              minWidth: "800px",
              tableLayout: "auto",
            }}
            state={{
              pagination: {
                pageIndex: pagination.pageIndex,
                pageSize: pageSize,
              },
            }}
            pageCount={
              data?.meta?.last_page ??
              Math.ceil((data?.meta?.total ?? 0) / pageSize)
            }
          >
            <thead className={styles.balanceHistoryHead}>
              <tr>
                <th style={{ color: "#AAAAAA" }}>DOCUMENT</th>
                <th style={{ color: "#AAAAAA" }}>DATE</th>
                <th style={{ color: "#AAAAAA" }}>AMOUNT</th>
                <th style={{ color: "#AAAAAA" }}>BALANCE</th>
                <th style={{ color: "#AAAAAA" }}>ACCOUNT</th>
                <th style={{ color: "#AAAAAA" }}>TYPE</th>
                <th style={{ color: "#AAAAAA" }}>REFILL METHOD</th>
              </tr>
            </thead>
            <tbody className={styles.balanceHistoryBody}>
              {data.data.map((item, index) => (
                <tr key={index}>
                  <td>{item.ID}</td>
                  <td>
                    <DateFormatter date={item.transactionAt} />
                  </td>
                  <td style={{ color: item.amount > 0 ? "#21A038" : "black" }}>
                    € {item.amount.toFixed(2)}
                  </td>
                  <td>€ {item.balance.toFixed(2)}</td>
                  <td>
                    <span>#{item.account.iban.slice(-6)}</span>{" "}
                    {item.account.name}
                  </td>
                  <td>{getOperationType(item.operationType)}</td>
                  <td>
                    <span className={styles.refillMethod}>
                      {item.refillMethod}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p style={{ textAlign: "center", fontSize: "14px", color: "#AAAAAA" }}>
          {" "}
          No records to display
        </p>
      )}
      <div style={{ marginTop: "16px" }}>
        <Pagination
          value={pagination.pageIndex}
          onChange={handleChangePage}
          total={
            data?.meta?.last_page ??
            Math.ceil((data?.meta?.total ?? 0) / pageSize)
          }
          position="right"
          withEdges
        />
      </div>
    </div>
  );
};

export default BalanceHistoryTable;
