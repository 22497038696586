import { grantAccessToAcc } from "../../api/getAccounts";
import { blockUserMember, unblockUserMember } from "../../api/userApi";
import { removeUserAccount } from "../../api/usersApi";
import { NOTIFICATION_INFO } from "../../constants/constants";
import { showToast } from "../../modules/Notification/ActionNotification";
import { useClientInvalidateData } from "../useClientInvalidateData";

export const useTeamManagementHandlers = ({
  setData,
  setActiveModalInveractive,
  setModalData,
  setIsEditModal,
  setModals,
}) => {
  const { handleInvalidateData } = useClientInvalidateData({
    queryKey: "tableData",
  });

  const handleModalSubmit = async (modalType, data) => {
    try {
      if (modalType === "removeAccount") {
        await handleRemoveUserAccount(data.accountId, data.userId);
        showToast("removeAccount", NOTIFICATION_INFO.ACCOUNT_REMOVED);
      } else if (modalType === "blockUser") {
        await blockUser(data.userId);
        showToast("blocked", NOTIFICATION_INFO.ACCOUNT_BLOCKED_ACTION);
      } else if (modalType === "unblockUser") {
        await unblockUser(data.userId);
        showToast("unblocked", NOTIFICATION_INFO.ACCOUNT_UNBLOCKED);
      } else if (modalType === "grantAccount") {
        const body = {
          userIds: [data.userId],
        };
        await grantAccessToAcc(data.accountId, body);
        showToast("accGranted", NOTIFICATION_INFO.ACCOUNT_GRANTED);
      }

      handleInvalidateData();
      setActiveModalInveractive(null);
      setModalData(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = (modalType, data) => {
    setActiveModalInveractive(modalType);
    setModalData(data);
  };

  const unblockUser = async (idUser) => {
    try {
      await unblockUserMember(idUser);
      setData((prev) => {
        return prev.map((user) =>
          user.id === idUser ? { ...user, blocked: false } : user
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  const blockUser = async (idUser) => {
    try {
      await blockUserMember(idUser);
      setData((prev) => {
        return prev.map((user) =>
          user.id === idUser ? { ...user, blocked: true } : user
        );
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleRemoveUserAccount = async (accountId, userId) => {
    try {
      await removeUserAccount(accountId, userId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddUser = () => {
    setIsEditModal(false);
    setModals((prev) => ({
      ...prev,
      modalAddMember: true,
    }));
  };
  const handleEditUser = (user) => {
    console.log("user", user);
    setIsEditModal(true);
    setModalData(user);
    setModals((prev) => ({
      ...prev,
      modalAddMember: true,
    }));
  };

  return {
    handleEditUser,
    handleAddUser,
    handleRemoveUserAccount,
    handleModalSubmit,
    handleOpenModal,
  };
};
