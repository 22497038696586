import MasterCard from "../icons/cardsIcons/MasterCard.svg";
import UnionCard from "../icons/cardsIcons/UnionCard.svg";
import VisaCard from "../icons/cardsIcons/VisaCard.svg";
import MasterCardIcon from "../icons/paymentSystems/MasterCardIcon";
import UnionPay from "../icons/paymentSystems/UnionPay";
import VisaIcon from "../icons/paymentSystems/VisaIcon";

export const getBackgroundForSystem = (system) => {
  switch (system) {
    case "mc":
      return `url(${MasterCard})`;
    case "visa":
      return `url(${VisaCard})`;
    case "up":
      return `url(${UnionCard})`;
    default:
      return "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)";
  }
};

export const getSystemTitle = (system) => {
  switch (system) {
    case "mc":
      return `MASTERCARD`;
    case "visa":
      return `VISA`;
    case "up":
      return `UNIONPAY`;
    default:
      return "none";
  }
};

export const getIconForSystem = (system) => {
  switch (system) {
    case "mc":
      return <MasterCardIcon />;
    case "visa":
      return <VisaIcon />;
    case "up":
      return <UnionPay />;
    case "union":
      return <UnionPay />;
    default:
      return null;
  }
};
