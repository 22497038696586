import { useEffect } from "react";
import styles from "./styles.module.scss";

const Modal = ({ children }) => {
  return (
    <div className={styles.containerModal}>
      <div className={styles.modal}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
