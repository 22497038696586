import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Pagination from "../../components/pagination/Pagination";
import ItemBlockInfo from "../../components/itemBlockInfo/ItemBlockInfo";
import AllAccountsTable from "../../modules/Tables/AllAccounts/AllAccountsTable";
import { getWidgetValues } from "../../api/dashboardApi";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import Skeleton from "../../components/Skeleton/Skeleton";
import BalanceHistoryTable from "../../modules/Tables/BalanceHistoryTable/BalanceHistoryTable";
import { AuthContext } from "../../context/AuthContext";

const Accounts = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["dashboardValues"],
    queryFn: getWidgetValues,

    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
  });

  const { userData } = useContext(AuthContext);

  const items = [
    {
      title: "DEPOSIT SUM",
      titleColored: "TOTAL",
      data: data?.data.data.totalDepositSum.value,
    },
    {
      title: "AMOUNT CREDITED",
      titleColored: "TOTAL",
      data: data?.data.data.totalAmountCredited.value,
    },
    {
      title: "FEE AMOUNT",
      titleColored: "TOTAL",
      data: data?.data.data.totalTotalFee.value,
    },
    {
      title: "OF ALL DEPOSITS",
      titleColored: "NUMBER",
      data: String(data?.data.data.totalDepositCount.value),
    },
  ];

  const tabs = [
    { title: "All accounts" },
    ...(userData?.role !== "Member" ? [{ title: "Balance history" }] : []),
  ];

  return (
    <div className={styles.accountsPage} style={{ overflow: "hidden" }}>
      <div className={styles.accountPageHeader}>
        <h1>Accounts</h1>
        <Pagination tabs={tabs} active={activeTab} setActive={setActiveTab} />
      </div>
      {userData?.role === "Member" ? (
        ""
      ) : (
        <div className={styles.accountsBlockInfoContainer}>
          {items.map((item, index) => (
            <ItemBlockInfo
              key={index}
              title={item.title}
              titleColored={item.titleColored}
              disabledLinks={true}
              additContent={
                <div className="H1">
                  {isLoading ? (
                    <Skeleton className={styles.widgetSkeleton} />
                  ) : (
                    `${index === items.length - 1 ? "" : "€"} ${
                      typeof item?.data === "number"
                        ? item?.data.toFixed(2)
                        : item?.data
                    }`
                  )}
                </div>
              }
              linkTitle="Details"
              href="/"
              highlightPosition="first"
            />
          ))}
        </div>
      )}

      <div>
        {activeTab === 0 && (
          <div className={styles.tableContainer}>
            <AllAccountsTable />
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <BalanceHistoryTable />
          </div>
        )}
      </div>
    </div>
  );
};

export default Accounts;
