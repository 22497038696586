export const handleExcelDownload = async ({ fn, fileName }) => {
  try {
    const response = await fn();

    console.log("Response headers:", response.headers);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  }
};
