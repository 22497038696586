import React from "react";

const NewsIcon = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 2C4.567 2 3 3.567 3 5.5V18.5C3 20.433 4.567 22 6.5 22H19.5C21.433 22 23 20.433 23 18.5V8.75C23 8.33579 22.6642 8 22.25 8C21.8358 8 21.5 8.33579 21.5 8.75V18.5C21.5 19.6046 20.6046 20.5 19.5 20.5H6.5C5.39543 20.5 4.5 19.6046 4.5 18.5V5.5C4.5 4.39543 5.39543 3.5 6.5 3.5H16.5C17.6046 3.5 18.5 4.39543 18.5 5.5V18.1875C18.5 18.6017 18.8358 18.9375 19.25 18.9375C19.6642 18.9375 20 18.6017 20 18.1875V5.5C20 3.567 18.433 2 16.5 2H6.5Z"
        fill={fill}
      />
      <path
        d="M12.75 6C12.3358 6 12 6.33579 12 6.75C12 7.16421 12.3358 7.5 12.75 7.5H16.25C16.6642 7.5 17 7.16421 17 6.75C17 6.33579 16.6642 6 16.25 6H12.75Z"
        fill={fill}
      />
      <path
        d="M12 9.75C12 9.33579 12.3358 9 12.75 9H16.25C16.6642 9 17 9.33579 17 9.75C17 10.1642 16.6642 10.5 16.25 10.5H12.75C12.3358 10.5 12 10.1642 12 9.75Z"
        fill={fill}
      />
      <path
        d="M6.75 12C6.33579 12 6 12.3358 6 12.75C6 13.1642 6.33579 13.5 6.75 13.5H16.25C16.6642 13.5 17 13.1642 17 12.75C17 12.3358 16.6642 12 16.25 12H6.75Z"
        fill={fill}
      />
      <path
        d="M6 15.75C6 15.3358 6.33579 15 6.75 15H16.25C16.6642 15 17 15.3358 17 15.75C17 16.1642 16.6642 16.5 16.25 16.5H6.75C6.33579 16.5 6 16.1642 6 15.75Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25 6C6.55964 6 6 6.55964 6 7.25V9.75C6 10.4404 6.55964 11 7.25 11H9.75C10.4404 11 11 10.4404 11 9.75V7.25C11 6.55964 10.4404 6 9.75 6H7.25ZM7.5 9.5V7.5H9.5V9.5H7.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewsIcon;
