import React from "react";

const BlockedIcon = ({ className, onClick, style }) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        className={className}
        style={style}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="3.2" fill="#FFE2E2" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.0002 8.19971C11.6924 8.19971 8.2002 11.6919 8.2002 15.9997C8.2002 20.3075 11.6924 23.7997 16.0002 23.7997C20.308 23.7997 23.8002 20.3075 23.8002 15.9997C23.8002 11.6919 20.308 8.19971 16.0002 8.19971ZM11.7767 10.9278C12.9212 9.97368 14.3937 9.39971 16.0002 9.39971C19.6452 9.39971 22.6002 12.3546 22.6002 15.9997C22.6002 17.6062 22.0262 19.0787 21.0721 20.2231L11.7767 10.9278ZM10.9282 11.7763C9.97416 12.9208 9.4002 14.3932 9.4002 15.9997C9.4002 19.6447 12.3551 22.5997 16.0002 22.5997C17.6067 22.5997 19.0792 22.0257 20.2236 21.0717L10.9282 11.7763Z"
          fill="#FF4D4D"
        />
      </svg>
    </>
  );
};

export default BlockedIcon;
