import React from "react";
import styles from "./styles.module.scss";
import { widthStatusTitle } from "../../../constants/constants";

const StatusBadge = ({ status }) => {
  const statusStyles = {
    "In progress": {
      backgroundColor: "#FFE390",
      color: "#F79E1B",
      text: "In progress",
    },
    Reversed: {
      backgroundColor: "#FFE2E2",
      color: "#FF4D4D",
      text: "Reversed",
    },
    "Partial reversed": {
      backgroundColor: "#FFE2E2",
      color: "#FF4D4D",
      text: "Part reversed",
    },

    Unhold: {
      backgroundColor: "#D5E8FF",
      color: "#227ECF",
      text: "Unhold",
    },
    Executed: {
      backgroundColor: "#D9FFE0",
      color: "#21A038",
      text: "Completed",
    },
    Refund: {
      backgroundColor: "#FFD8F9",
      color: "#C970BC",
      text: "Refund",
    },
    "Partial Refund": {
      backgroundColor: "#FFD8F9",
      color: "#C970BC",
      text: "Part refund",
    },
  };

  const currentStatus = statusStyles[status] || {
    backgroundColor: "lightgray",
    color: "black",
    text: "Unknown status",
  };

  return (
    <span
      style={{
        backgroundColor: currentStatus.backgroundColor,
        color: currentStatus.color,
        width: widthStatusTitle,
      }}
      className={`${styles.statusBadge} secondaryText`}
    >
      {currentStatus.text}
    </span>
  );
};

export default StatusBadge;
