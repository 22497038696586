import React from "react";

const VisaIcon = () => {
  return (
    <svg
      width="62"
      height="32"
      viewBox="0 0 62 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1122 6.03076C35.743 6.03076 31.8382 8.29576 31.8382 12.4796C31.8382 17.2781 38.7632 17.6095 38.7632 20.0205C38.7632 21.0357 37.6002 21.9443 35.613 21.9443C32.7936 21.9443 30.6863 20.6747 30.6863 20.6747L29.7845 24.8973C29.7845 24.8973 32.2121 25.9698 35.435 25.9698C40.212 25.9698 43.9714 23.5939 43.9714 19.338C43.9714 14.2672 37.0175 13.9456 37.0175 11.7084C37.0175 10.9131 37.9722 10.0421 39.9533 10.0421C42.1887 10.0421 44.012 10.9655 44.012 10.9655L44.8948 6.88761C44.8948 6.88761 42.91 6.03076 40.1116 6.03076H40.1122ZM0.136224 6.33876L0.0302734 6.95413C0.0302734 6.95413 1.86839 7.29046 3.52418 7.96189C5.65612 8.73127 5.80765 9.17971 6.16678 10.5706L10.0789 25.6519H15.3235L23.4028 6.33876H18.1706L12.979 19.4698L10.8606 8.33888C10.6666 7.06501 9.68224 6.33814 8.47798 6.33814H0.13684L0.136224 6.33876ZM25.507 6.33876L21.4027 25.6519H26.3922L30.4818 6.33876H25.507ZM53.3351 6.33876C52.132 6.33876 51.4945 6.98308 51.0269 8.1085L43.717 25.6519H48.9492L49.9613 22.7278H56.3356L56.9509 25.6519H61.5678L57.5404 6.33876H53.3351ZM54.0157 11.5568L55.5668 18.804H51.4119L54.0163 11.5568H54.0157Z"
        fill="#10398A"
      />
    </svg>
  );
};

export default VisaIcon;
