import React from "react";
import styles from "../AddUserModal/AddUserModal.module.scss";
import { Button } from "../../../components/Button/Button";
import { removeAccessToAcc } from "../../../api/getAccounts";
import { showToast } from "../../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";

const RemoveUserModal = ({ setRemoveMemberModal, removeUserData, reload }) => {
  const handleRemoveUser = async (removeUserData) => {
    try {
      const { id, userId } = removeUserData;
      await removeAccessToAcc(id, userId);
      // const removeUserResponse = await removeAccessToAcc(id, userId);
      setRemoveMemberModal(false);
      showToast("success", NOTIFICATION_INFO.USER_REMOVE);
      reload();
    } catch (err) {
      console.log(err);
    }
  };
  console.log(removeUserData);
  return (
    <div className={styles.addUserModal}>
      <div className={styles.addUserModalContainer}>
        <h1>Remove user</h1>
        <p className={styles.addUserModalContainerText}>
          User {removeUserData?.name} {removeUserData?.email} will lose access{" "}
          <span>
            to this account "{removeUserData?.accountName}" and all its cards
            also.
          </span>
          <p>Are you sure you want to remove this user?</p>
        </p>
        <div className={styles.addUserModalContainerButtons}>
          <Button
            className={styles.addUserModalContainerButton}
            onClick={() => handleRemoveUser(removeUserData)}
          >
            Remove
          </Button>
          <Button
            className={styles.addUserModalContainerButton}
            onClick={() => setRemoveMemberModal(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RemoveUserModal;
