import React from "react";

const Visa = () => {
  return (
    <svg
      width="40"
      height="14"
      viewBox="0 0 40 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4293 0.824951C22.6581 0.824951 20.1814 2.26156 20.1814 4.91519C20.1814 7.95875 24.5737 8.16895 24.5737 9.69815C24.5737 10.342 23.8361 10.9183 22.5756 10.9183C20.7874 10.9183 19.4508 10.1131 19.4508 10.1131L18.8788 12.7913C18.8788 12.7913 20.4186 13.4715 22.4627 13.4715C25.4926 13.4715 27.8771 11.9646 27.8771 9.26525C27.8771 6.04901 23.4664 5.84506 23.4664 4.42604C23.4664 3.92164 24.072 3.36919 25.3285 3.36919C26.7464 3.36919 27.9028 3.95485 27.9028 3.95485L28.4627 1.36842C28.4627 1.36842 27.2039 0.824951 25.4289 0.824951H25.4293ZM0.0740364 1.0203L0.00683594 1.41061C0.00683594 1.41061 1.17269 1.62393 2.22289 2.0498C3.5751 2.53778 3.67122 2.82221 3.89899 3.70441L6.38033 13.2699H9.70676L14.8312 1.0203H11.5126L8.21975 9.34886L6.87613 2.28891C6.75306 1.48094 6.12872 1.01991 5.3649 1.01991H0.0744271L0.0740364 1.0203ZM16.1658 1.0203L13.5626 13.2699H16.7273L19.3211 1.0203H16.1658ZM33.8161 1.0203C33.0531 1.0203 32.6487 1.42897 32.3521 2.14278L27.7157 13.2699H31.0343L31.6762 11.4153H35.7192L36.1095 13.2699H39.0378L36.4834 1.0203H33.8161ZM34.2478 4.32993L35.2316 8.92652H32.5963L34.2482 4.32993H34.2478Z"
        fill="#10398A"
      />
    </svg>
  );
};

export default Visa;
