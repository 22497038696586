import React from "react";

const AddIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.00015 0C4.66028 0 4.38477 0.275517 4.38477 0.615385V4.38461H0.615385C0.275517 4.38461 0 4.66013 0 5C0 5.33986 0.275517 5.61538 0.615385 5.61538H4.38477V9.38461C4.38477 9.72448 4.66028 10 5.00015 10C5.34002 10 5.61553 9.72448 5.61553 9.38461V5.61538H9.38461C9.72448 5.61538 10 5.33987 10 5C10 4.66013 9.72448 4.38461 9.38461 4.38461H5.61553V0.615385C5.61553 0.275517 5.34002 0 5.00015 0Z"
        fill="#232323"
      />
    </svg>
  );
};

export default AddIcon;
