import React, { useState } from "react";
import ReloadIcon from "../../icons/ReloadIcon";
import styles from "./styles.module.scss";
import CardTable from "../Tables/CardTable/CardTable";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import ExcelButton from "../../components/ExcelButton/ExcelButton";
import { getAllCardsData } from "../../api/getExcel";
import FiltersButton from "../../components/FiltersButton/FiltersButton";
import TableFiltersAllCards from "../TableFilters/AllCards";
import FilterActive from "../../icons/FilterActive";
import { getExportCards } from "../../api/cardsApi";
import { axiosAuth } from "../../api/axios";
import { handleExcelDownload } from "../../utils/excelDownload";
import { Button } from "../../components/Button/Button";
import FilterUnactive from "../../icons/FilterUnactive";

const AllCards = () => {
  const queryClient = useQueryClient();
  const [isFiltersShow, setIsFiltersShow] = useState(false);
  const [filters, setFilters] = useState({
    dateFrom: null,
    dateTo: null,
    status: null,
    iban: null,
    bin: null,
    ibanId: null,
  });

  const handleUpdateDashboardData = () => {
    queryClient.invalidateQueries(["cardsTableData"]);
  };
  const isFetching = useIsFetching(["cardsTableData"]);

  return (
    <div className={styles.containerCards}>
      <div className={styles.containerTitle}>
        <div className={styles.containerExcel}>
          <div className={styles.containerTitleCard}>
            <h2 className="H3">ALL CARDS</h2>
            <FiltersButton
              isActive={isFiltersShow}
              onClick={() => setIsFiltersShow((prev) => !prev)}
            />
            <ReloadIcon
              className={styles.reloadIcon}
              onClick={handleUpdateDashboardData}
              style={{
                animation: isFetching ? "spin 1s linear infinite" : "none",
              }}
            />
          </div>
          <ExcelButton
            onSubmit={() =>
              handleExcelDownload({
                fn: () => getExportCards({ params: filters }),
                fileName: "cards",
              })
            }
          />
        </div>

        <div className={styles.containerExcelMobile}>
          <div className={styles.containerTitleCardMobile}>
            <h2 className="H3">ALL CARDS</h2>
            <ReloadIcon
              className={styles.reloadIconMobile}
              onClick={handleUpdateDashboardData}
              style={{
                animation: isFetching ? "spin 1s linear infinite" : "none",
              }}
            />
          </div>
          <div className={styles.containerFiltersMobile}>
            <FiltersButton
              isActive={isFiltersShow}
              onClick={() => setIsFiltersShow((prev) => !prev)}
            />

            <ExcelButton
              onSubmit={() =>
                handleExcelDownload({
                  fn: () => getExportCards({ params: filters }),
                  fileName: "cards",
                })
              }
            />
          </div>
        </div>
        {isFiltersShow && (
          <TableFiltersAllCards filters={filters} setFilters={setFilters} />
        )}
      </div>

      <CardTable filters={filters} />
    </div>
  );
};

export default AllCards;
