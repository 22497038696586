import MasterCard from "../icons/MasterCard";
import UnionPay from "../icons/UnionPay";
import Visa from "../icons/Visa";

export const getCorrectCardType = (paymentSystem) => {
  if (paymentSystem === "mc") {
    return <MasterCard />;
  } else if (paymentSystem === "visa") {
    return <Visa />;
  } else {
    return <UnionPay />;
  }
};
