export const getFilteredAndSortedCards = (
  cardsData,
  binFilter,
  systemFilter,
  currencyFilter
) => {
  return [...cardsData].sort((a, b) => {
    let aScore = 0;
    let bScore = 0;

    if (binFilter) {
      if (a.bin.includes(binFilter)) aScore++;
      if (b.bin.includes(binFilter)) bScore++;
    }

    if (systemFilter !== "all") {
      if (
        (systemFilter === "mastercard" && a.system === "mc") ||
        systemFilter === a.system
      )
        aScore++;
      if (
        (systemFilter === "mastercard" && b.system === "mc") ||
        systemFilter === b.system
      )
        bScore++;
    }

    if (currencyFilter !== "all") {
      if (a.currensy === currencyFilter) aScore++;
      if (b.currensy === currencyFilter) bScore++;
    }

    return bScore - aScore;
  });
};
