import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { Box } from "@mui/material";
import Skeleton from "../../../components/Skeleton/Skeleton";
import styles from "./styles.module.scss";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getPayments } from "../../../api/paymentsApi";
import ColumnsCardHistory from "../../../constants/columns/ColumnsCardHistory";
import { optionsTable, pageSize } from "../../../constants/constants";

const TableCardPayments = ({ cardId, filters }) => {
  const queryClient = useQueryClient();
  const [dataTable, setDataTable] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
  });

  const {
    data: cardsData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["cardHistory", pagination.pageIndex, pageSize, cardId],
    queryFn: () =>
      getPayments({
        params: filters,
        page: pagination.pageIndex + 1,
        perPage: pageSize,
        cardId: cardId,
      }),
    keepPreviousData: true,
    staleTime: 2 * 60 * 1000,
    cacheTime: 2 * 60 * 1000,
    onSuccess: (data) => {
      if (data.meta.current_page < data.meta.last_page) {
        queryClient.prefetchQuery({
          queryKey: [
            "cardsTableData",
            pagination.pageIndex + 2,
            pageSize,
            cardId,
          ],
          queryFn: () =>
            getPayments({
              params: filters,
              page: pagination.pageIndex + 2,
              perPage: pageSize,
              cardId: cardId,
            }),
        });
      }
    },
    enabled: !!cardId,
  });

  useEffect(() => {
    if (cardsData?.data && !isLoading && !isFetching) {
      setDataTable(cardsData?.data);
    }
  }, [cardsData, isLoading, isFetching]);

  if (isLoading || isFetching)
    return <Skeleton className={styles.skeletonTable} isSpinner={true} />;

  return (
    <Box
      style={{
        borderRadius: "8px",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <Table
        columns={ColumnsCardHistory()}
        data={dataTable}
        enablePagination={true}
        manualPagination={true}
        onPaginationChange={(updater) => {
          setPagination((prev) => {
            const newState =
              typeof updater === "function" ? updater(prev) : updater;
            return newState;
          });
        }}
        state={{
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pageSize,
          },
        }}
        enableColumnActions={false}
        rowCount={cardsData?.meta?.total ?? 0}
        pageCount={
          cardsData?.meta?.last_page ??
          Math.ceil((cardsData?.meta?.total ?? 0) / pageSize)
        }
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableTopToolbar={false}
        enableDensityToggle={false}
        paginationDisplayMode={"pages"}
        mantinePaginationProps={{
          showRowsPerPage: false,
        }}
        mantineTableProps={{
          ...optionsTable,
        }}
      />
    </Box>
  );
};

export default TableCardPayments;
