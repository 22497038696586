import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInput-underline:after": {
            borderBottomColor: "#21A038",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#21A038",
            },
          "& .MuiOutlinedInput-root": {
            height: "40px",
            "& .MuiInputBase-input": {
              height: "40px",
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&.Mui-checked": {
            color: "#21A038",
          },
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#21A038",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#21A038",
          },
          "& .MuiOutlinedInput-root": {
            height: "40px",
            "& .MuiInputBase-input": {
              height: "40px",
            },
          },
        },
        icon: {
          color: "#21A038",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#21A038",
          },
        },
      },
    },
  },
});
