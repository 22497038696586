import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { ALL_MERCHANTS } from "../../constants/constants";
import ArrowLink from "../../icons/ArrowLink";
import IssueCardModal from "../../modules/Modals/IssueCardModal/IssueCardModal";
import CardItem from "./CardItem";
import {
  getBackgroundForSystem,
  getIconForSystem,
} from "../../utils/getBackgroundForSystem";

const CardIssuanceItem = ({ data }) => {
  const [issueCardModal, setIssueCardModal] = useState(false);
  const [cardData, setCardData] = useState();

  return (
    <>
      {data.map((card, idx) => (
        <div key={idx} className={styles.cardItem}>
          <div className={styles.cardItemLeft}>
            <div className={styles.cardItemLeftHeader}>
              <div className={styles.cardItemTitle}>
                <h1>BIN: {card.bin} </h1> {getIconForSystem(card.system)}
              </div>

              <p>
                Advertising (FB, Google, TikTok, etc.) and services{" "}
                <a href={ALL_MERCHANTS} target="_blank" rel="noreferrer">
                  (all merchants) <ArrowLink style={{ marginLeft: "5px" }} />
                </a>
              </p>
              <div className={styles.cardItemSpanMobile}>
                <div>
                  <span>On & Off 3DS </span>
                  <span>UK</span>
                </div>

                <span>Without any hidden commission</span>
              </div>
              <button
                className={styles.issueCardButton}
                onClick={() => {
                  setIssueCardModal(true);
                  setCardData({
                    bin: card.bin,
                    system: card.system,
                    currensy: "EUR",
                  });
                }}
              >
                Issue card
              </button>
            </div>
            <div className={styles.cardItemSpan}>
              <span>On & Off 3DS </span>
              <span>UK</span>
              <span>Without any hidden commission</span>
            </div>
          </div>
          <div className={styles.cardItemRight}>
            <CardItem card={card} />
            <div
              style={{
                backgroundImage: getBackgroundForSystem(card.system),
                backgroundPosition: "calc(50% - 5px) center",
                maxWidth: "352px",
                width: "384x",
                borderRadius: "16px",
                zIndex: "2",
                minHeight: "200px",
                maxHeight: "210px",
                minWidth: "320px",
                position: "absolute",
                right: "-12px",
                top: "10px",
              }}
            ></div>
            <div
              style={{
                backgroundImage: getBackgroundForSystem(card.system),

                backgroundPosition: "calc(50% - 20px) center",
                maxWidth: "352px",
                width: "384x",
                zIndex: "1",
                minHeight: "180px",
                minWidth: "288px",
                position: "absolute",
                right: "-27px",
                top: "20px",
                borderRadius: "16px",
              }}
            ></div>
          </div>
        </div>
      ))}

      {issueCardModal && (
        <IssueCardModal setIssueCardModal={setIssueCardModal} data={cardData} />
      )}
    </>
  );
};

export default CardIssuanceItem;
