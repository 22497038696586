import React, { useState, useEffect } from "react";
import {
  getNotifications,
  markAsReadNotifications,
} from "../../api/notifications";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";
import { Button } from "../../components/Button/Button";

export const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getNotifs = async () => {
      try {
        const fetchedNotifications = await getNotifications();
        const sortedNotifications = fetchedNotifications.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        const latestNotifications = sortedNotifications.slice(0, 5);

        setNotifications(latestNotifications);
      } catch (error) {
        console.error("Ошибка при получении уведомлений:", error);
      }
    };

    getNotifs();
  }, []);

  const handleGoToNews = async (url, id) => {
    const linkId = extractNewsId(url);

    try {
      await markAsReadNotifications(id);
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
      navigate(`/news-article/${linkId}`);
    } catch (error) {
      console.error("Ошибка при переходе в новость:", error);
    }
  };

  const markAsRead = async (id) => {
    try {
      await markAsReadNotifications(id);
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const extractNewsId = (url) => {
    const match = url.match(/news\/(\d+)$/);
    return match ? match[1] : null;
  };

  return (
    <div className={styles.containerAllNotifs}>
      {notifications.map((notification) => {
        console.log("notification.data.title", notification.data.title);

        return (
          <div key={notification.id} className={styles.containerNotification}>
            <div
              className={styles.notificationItem}
              onClick={() =>
                handleGoToNews(notification.data.link, notification.id)
              }
            >
              <button
                className={styles.closeButton}
                onClick={(e) => {
                  e.stopPropagation();
                  markAsRead(notification.id);
                }}
              >
                ✕
              </button>
              <div className={styles.contentBlock}>
                <h4 className={styles.title}>{notification.data.title}</h4>

                {notification?.data?.preview ? (
                  <div
                    className={styles.preview}
                    dangerouslySetInnerHTML={{
                      __html:
                        notification.data.preview.length > 100
                          ? `${notification.data.preview.slice(0, 100)}...`
                          : notification.data.preview,
                    }}
                  ></div>
                ) : (
                  <p className={styles.noContent}>No content</p>
                )}
              </div>

              <div className={styles.containerDate}>
                <DateFormatter
                  date={notification.created_at}
                  withTime={false}
                />
                <Button className={styles.btnMock}>Important news</Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
{
  /* <p className={styles.content}>
                  {notification?.data?.preview
                    ? notification.data.preview.length > 100
                      ? `${notification.data.preview.slice(0, 100)}...`
                      : notification.data.preview
                    :  "No content"}
                </p> */
}
