import { useQueryClient } from "@tanstack/react-query";
import { getWidgetValues } from "../../api/dashboardApi";
import NewsContainer from "../../components/news/NewsContainer";
import ReloadIcon from "../../icons/ReloadIcon";
import DashBoardItems from "../../modules/DashBoardItems/DashBoardItems";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useCallback } from "react";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import ContainerNews from "../../components/news/container/ContainerNews";

const Dashboard = () => {
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["dashboardValues"],
    queryFn: getWidgetValues,

    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
  });

  const handleUpdateDashboardData = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["dashboardValues"],
    });
    queryClient.invalidateQueries({ queryKey: ["news"] });
    queryClient.invalidateQueries({
      queryKey: ["dataDashBoardTable"],
    });
  }, [queryClient]);

  return (
    <div className={styles.dashboardMain}>
      <div className={styles.dashboardTitleContainer}>
        <h2 className={clsx("H1")}>Dashboard</h2>
        <ReloadIcon
          className={styles.reloadIcon}
          onClick={handleUpdateDashboardData}
          style={{
            animation: isFetching ? "spin 1s linear infinite" : "none",
          }}
        />
      </div>

      <div className={styles.centerMainContent}>
        <DashBoardItems
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
        />

        <NewsContainer
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          width={1920}
        />
      </div>
    </div>
  );
};

export default Dashboard;
