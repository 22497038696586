import { Popover, TextInput } from "@mantine/core";
import { Button } from "../../../components/Button/Button";
import { IconPencil } from "@tabler/icons-react";
import { changeAccount } from "../../../api/getAccounts";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Rename from "../../../icons/Rename";
import { showToast } from "../../Notification/ActionNotification";

const EditableName = React.memo(({ account, setTableData }) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [newName, setNewName] = useState(account.name);

  useEffect(() => {
    setNewName(account.name);
  }, [account.name]);

  const handleEditClick = () => setPopoverOpen(true);

  const handleChangeName = async (accountID) => {
    const data = {
      name: newName,
    };
    if (newName.trim().length < 3) {
      showToast("error", "Name must be at least 3 characters long!");
      return;
    }

    try {
      await changeAccount(accountID, data);

      setTableData((prevData) => {
        return prevData.map((acc) =>
          acc.ID === accountID ? { ...acc, name: newName } : acc
        );
      });
      showToast("success", "Account name has been successfully updated!");
      setPopoverOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popover
      opened={isPopoverOpen}
      onClose={() => setPopoverOpen(false)}
      position="bottom-start"
      withArrow
    >
      <Popover.Target>
        <div
          onClick={handleEditClick}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Rename />
        </div>
      </Popover.Target>
      <Popover.Dropdown className={styles.popOver}>
        <TextInput
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder="Enter new name"
          mb="sm"
          maxLength={40}
          required
        />
        <Button
          className={styles.saveButton}
          onClick={() => handleChangeName(account.ID)}
        >
          Save
        </Button>
      </Popover.Dropdown>
    </Popover>
  );
});

export default EditableName;
