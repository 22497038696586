import React, { useState } from "react";
import { DatePickerInput } from "@mantine/dates";
import Calendar from "../../icons/Calendar";

const DatePicker = ({ setFilters, setDateValue, dateValue }) => {
  const handleDateChange = (newValue) => {
    setDateValue(newValue);

    if (newValue[0] && newValue[1]) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateFrom: newValue[0].toISOString().split("T")[0],
        dateTo: newValue[1].toISOString().split("T")[0],
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateFrom: "",
        dateTo: "",
      }));
    }
  };

  return (
    <DatePickerInput
      type="range"
      label="Date"
      placeholder="01.01.2024"
      value={dateValue}
      onChange={handleDateChange}
      valueFormat="YYYY-MM-DD"
      styles={{
        input: {
          fontSize: "14px",
          fontWeight: "400",
          color: "black",
          whiteSpace: "nowrap",
          width: "100%",
          "&:focus-within": {
            borderColor: "rgb(33, 160, 56)",
            borderWidth: "1px",
          },
        },
        placeholder: {
          color: "#aaaaaa",
        },
        label: {
          marginBottom: 8,
        },
      }}
      rightSection={<Calendar />}
    />
  );
};

export default DatePicker;
