import React from "react";

const PaymentsMobileIcon = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 8.25012C7.83578 8.25012 7.5 8.5859 7.5 9.00012C7.5 9.41427 7.83578 9.75012 8.25 9.75012H15.75C16.1642 9.75012 16.5 9.41427 16.5 9.00012C16.5 8.5859 16.1642 8.25012 15.75 8.25012H8.25Z"
        fill={fill}
      />
      <path
        d="M7.5 12.0001C7.5 11.5859 7.83578 11.2501 8.25 11.2501H15.75C16.1642 11.2501 16.5 11.5859 16.5 12.0001C16.5 12.4143 16.1642 12.7501 15.75 12.7501H8.25C7.83578 12.7501 7.5 12.4143 7.5 12.0001Z"
        fill={fill}
      />
      <path
        d="M12.375 14.2501C11.9608 14.2501 11.625 14.5859 11.625 15.0001C11.625 15.4143 11.9608 15.7501 12.375 15.7501H15.75C16.1642 15.7501 16.5 15.4143 16.5 15.0001C16.5 14.5859 16.1642 14.2501 15.75 14.2501H12.375Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7657 21.7088C12.2852 21.9597 11.7121 21.9586 11.2325 21.7059L8.70382 20.3729C8.6592 20.3493 8.6058 20.3499 8.56162 20.3741L6.19457 21.6747C5.09494 22.2789 3.75 21.4833 3.75 20.2287V3.76617C3.75 2.51372 5.09059 1.7179 6.1901 2.31764L8.56238 3.61161C8.60633 3.63557 8.65928 3.63605 8.70368 3.61289L11.2362 2.29033C11.7148 2.04043 12.2852 2.04043 12.7638 2.29033L15.2963 3.61289C15.3407 3.63605 15.3937 3.63557 15.4376 3.61161L17.8099 2.31764C18.9094 1.7179 20.25 2.51372 20.25 3.76617V20.234C20.25 21.4864 18.9094 22.2822 17.8099 21.6825L15.4376 20.3886C15.3937 20.3646 15.3407 20.3641 15.2963 20.3873L12.7657 21.7088ZM11.932 20.3789C11.9756 20.4019 12.0277 20.402 12.0714 20.3792L14.602 19.0577C15.0898 18.8028 15.6727 18.8082 16.1559 19.0717L18.5282 20.3657C18.6281 20.4202 18.75 20.3478 18.75 20.234V3.76617C18.75 3.6523 18.6281 3.57996 18.5282 3.63448L16.1559 4.92846C15.6727 5.19202 15.0898 5.19729 14.602 4.9425L12.0695 3.61995C12.0259 3.59723 11.9741 3.59723 11.9305 3.61995L9.39802 4.9425C8.91015 5.19729 8.32733 5.19202 7.8441 4.92846L5.47183 3.63448C5.37188 3.57996 5.25 3.6523 5.25 3.76617V20.2287C5.25 20.3427 5.37226 20.415 5.47223 20.3601L7.8393 19.0595C8.32523 18.7925 8.91277 18.7874 9.40327 19.0459L11.932 20.3789Z"
        fill={fill}
      />
    </svg>
  );
};

export default PaymentsMobileIcon;
