import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getCodes = async ({ page, perPage, type, params }) => {
  const response = await axiosAuth.get(`${API_PATHS.METAPAYS_CODES}/${type}`, {
    params: {
      ...params,
      page: page,
      per_page: perPage,
    },
  });
  return response.data;
};
