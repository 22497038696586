export const validateTxnHash = (value, selectedNetwork) => {
  const hexRegex = /^[0-9a-fA-F]+$/;

  if (selectedNetwork === "USDTTRC20") {
    if (value.length !== 64 || !hexRegex.test(value)) {
      return "Transaction hash must be exactly 64 hex characters (0-9, a-f, A-F)";
    }
  } else if (selectedNetwork === "USDT") {
    if ((value.length !== 64 && value.length !== 66) || !hexRegex.test(value)) {
      return "Transaction hash must be 64 or 66 hex characters (0-9, a-f, A-F)";
    }
  }
  return "";
};
