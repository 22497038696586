import React from "react";

const ArrowBottomMobie = ({ showInfo }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: "inline-block",
        transition: "transform 0.3s ease",
        transform: showInfo ? "rotate(180deg)" : "rotate(0deg)",
      }}
    >
      <rect
        x="24.5"
        y="0.5"
        width="24"
        height="24"
        rx="12"
        transform="rotate(90 24.5 0.5)"
        fill="white"
      />
      <path
        d="M18.009 13.5971C18.2685 13.3377 18.6891 13.3377 18.9485 13.5971C19.2079 13.8565 19.2079 14.2771 18.9485 14.5365L14.1777 19.3073C13.2512 20.2339 11.749 20.2339 10.8225 19.3073L6.05169 14.5365C5.79227 14.2771 5.79227 13.8565 6.05169 13.5971C6.3111 13.3377 6.7317 13.3377 6.99112 13.5971L11.7619 18.3679C11.7858 18.3917 11.8104 18.4141 11.8358 18.4351L11.8358 5.6682C11.8358 5.30132 12.1332 5.00391 12.5001 5.00391C12.8669 5.00391 13.1644 5.30132 13.1644 5.6682L13.1644 18.4351C13.1898 18.4141 13.2144 18.3917 13.2383 18.3679L18.009 13.5971Z"
        fill="#21A038"
      />
    </svg>
  );
};

export default ArrowBottomMobie;
